import React from 'react';
import styles from './TopActivities.module.css';

function TopActivities() {
  const data = [
    { name: 'Anom', rank: 1, adapters: 255 },
    { name: 'Megha', rank: 2, adapters: 127 },
    { name: 'Subham', rank: 3, adapters: 125 },
    { name: 'Subham', rank: 4, adapters: 125 },
    { name: 'Subham', rank: 5, adapters: 125 },
    { name: 'Subham', rank: 6, adapters: 125 },
    { name: 'Subham', rank: 7, adapters: 125 },
    { name: 'Subham', rank: 8, adapters: 125 },
    { name: 'Subham', rank: 9, adapters: 125 },
    { name: 'Subham', rank: 10, adapters: 125 },
  ];
  return (
    <div className={styles.conatiner}>
      <table className={styles.table}>
        <tbody>

          <tr>
            <th className={styles.tableh}>Rank</th>
            <th className={styles.tableh}>Name</th>
            <th className={styles.tableh}># Adaptors</th>
          </tr>
          {data.map((val) => (
            <tr key={val.rank}>
              <td className={styles.tabled1}>{val.rank}</td>
              <td className={styles.tabled2}>{val.name}</td>
              <td className={styles.tabled3}>{val.adapters}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TopActivities;
