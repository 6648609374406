import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TextField,
} from '@mui/material';
import styles from './StepOne.module.css';

function StepOne({ data }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['form-container']}>
        <div className={styles.fields}>

          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="questionEN"
              label={t('questionEN')}
              name="questionEN"
              value={data.english_description}
              type="text"
              multiline
              variant="outlined"
              disabled
            />

          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="questionAR"
              label={t('questionAR')}
              name="questionAR"
              value={data.arabic_description}
              type="text"
              multiline
              variant="outlined"
              disabled
            />
          </div>

        </div>
      </div>

    </div>
  );
}

export default StepOne;
StepOne.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    english_description: PropTypes.string.isRequired,
    arabic_description: PropTypes.string.isRequired,
    is_active: PropTypes.number.isRequired,
  }).isRequired,
};
