/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import useAuth from "../../../Auth/useAuth";
import styles from "./LoginForm.module.css";
import useLoginForm from "./useLoginForm";
import signin from "../../../Services/adminServices";

function LoginForm({ setSuspend }) {
  const { t } = useTranslation();
  const { login, setAuth } = useAuth();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const {
    // setValues,
    handleChange,
    values,
    handleSubmit,
    error,
    setError,
  } = useLoginForm(setLoader);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const data = decodeURI(params.get("data"));

    if (data) {
      // Replace 'your_secret_key' with the same encryption key used on the main domain.
      const secretKey = "BBiiBBiiBBii";
      // Decrypt the data using AES decryption.
      const bytes = CryptoJS.AES.decrypt(data, secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      if (decryptedData) {
        // Now you have the decrypted data.
        // You can further process it, for example, by splitting it into parts.
        const parts = decryptedData.split("|");

        if (parts.length === 3) {
          const username = parts[0];
          const password = parts[1];
          const expirationTime = parseInt(parts[2], 10);

          if (expirationTime >= new Date().getTime()) {
            setSuspend(true);
            signin({ email: username.replace('"', ""), password })
              .then((response) => {
                if (response.status === 200) {
                  if (
                    response.data.user.user_type === "admin" ||
                    response.data.user.user_type === "ادارة"
                  ) {
                    setAuth({ token: response.data.authorization.token });
                    login({ token: response.data.authorization.token });
                    navigate("/");
                  } else {
                    setError({
                      ...error,
                      password: "Error! Password",
                    });
                  }
                } else if (response.status === 422) {
                  setError({
                    ...error,
                    password: response.data.message,
                  });
                }
              })
              .finally(() => {
                setSuspend(false);
              });
          } else {
            // console.log("expire");
          }
        }
      }
    }
  }, []);

  const [visibility, setVisibility] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setVisibility({ ...visibility, showPassword: !visibility.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit} className={styles["form-conatiner"]}>
      <p className={styles.title}>{t("adminTitle")}</p>
      <h5 className={styles.title2}>{t("adminLogin")}</h5>
      <p className={styles.body1}>{t("adminLoginP1")}</p>
      <div className={styles.fields}>
        <TextField
          className={styles.fields}
          id="login-email"
          label={t("email")}
          name="email"
          value={values.email || null}
          onChange={handleChange}
          type="email"
          variant="outlined"
          color={error.email ? "secondary" : "primary"}
        />
        {/* {values.email
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              email: '',
            });
          }}
        />
        )} */}
      </div>
      <div className={styles.fields}>
        <FormControl className={styles.fields} variant="outlined">
          <InputLabel
            color={error.password && "secondary"}
            htmlFor="outlined-adornment-password"
          >
            {t("password")}
          </InputLabel>
          <OutlinedInput
            id="login-password"
            type={visibility.showPassword ? "text" : "password"}
            value={values.password || null}
            name="password"
            onChange={handleChange}
            color={error.password && "secondary"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {visibility.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label={t("password")}
          />
        </FormControl>
      </div>
      {(error.email || error.password) && (
        <p className={styles.error}>{t("loginError")}</p>
      )}
      <div className={styles["forgot-password-continer"]}>
        <div>
          <Button
            disabled={loader}
            type="submit"
            variant="contained"
            className={styles["login-button"]}
          >
            {loader ? t("wait") : t("login")}
          </Button>
        </div>
        <a href="/forgot-password" className={styles["forgot-password"]}>
          {t("forgotPassword")}
        </a>
      </div>
      <p className={styles.terms}>
        {t("termsNote")}{" "}
        <a href="/#" className={styles.conditions}>
          {t("terms")}
        </a>
      </p>
    </form>
  );
}

export default LoginForm;
