import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../Auth/useAuth";
import validateLogin from "./validateLogin";
import signin from "../../../Services/adminServices";

const useLoginForm = (setLoader) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({});
  const { login, setAuth } = useAuth();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateLogin(values));
    if (Object.keys(validateLogin(values)).length === 0) {
      // there is no errors
      // navigate('/');
      setLoader(true);
      signin(values).then((response) => {
        if (response.status === 200) {
          if (
            response.data.user.user_type === "admin" ||
            response.data.user.user_type === "ادارة"
          ) {
            setAuth({ token: response.data.authorization.token });
            login({ token: response.data.authorization.token });
            navigate("/");
          } else {
            setError({
              ...error,
              password: "Error! Password",
            });
          }
        } else if (response.status === 422) {
          setError({
            ...error,
            password: response.data.message,
          });
        }
        setLoader(false);
      });
    }
  };
  return {
    handleChange,
    values,
    setValues,
    handleSubmit,
    error,
    setError,
  };
};
export default useLoginForm;
