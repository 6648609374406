import { useState } from "react";
import { changePassword } from "../../Services/adminServices";

const useChangePasswordForm = (
  user,
  validateSetPassword,
  setPasswordLoader
) => {
  const { id } = user;
  const [passwordValues, setPasswordValues] = useState({
    id,
    password: "",
    password1: "",
    password2: "",
  });
  const [passwordError, setPasswordError] = useState({});
  const [errors1, setErrors1] = useState({});
  const [errors2, setErrors2] = useState({});
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordValues({
      ...passwordValues,
      [name]: value,
    });
  };
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    setPasswordError(validateSetPassword(passwordValues).errors);
    setErrors1(validateSetPassword(passwordValues).errors1);
    setErrors2(validateSetPassword(passwordValues).errors2);
    if (
      Object.keys(validateSetPassword(passwordValues).errors).length === 0 &&
      Object.keys(validateSetPassword(passwordValues).errors1).length === 0 &&
      Object.keys(validateSetPassword(passwordValues).errors2).length === 0
    ) {
      // there is no errors
      setPasswordLoader(true);
      changePassword({
        user_id: user.user_id,
        old_password: passwordValues.password,
        password: passwordValues.password1,
        password_confirmation: passwordValues.password2,
      }).then((response) => {
        if (response.status === 200) {
          localStorage.removeItem("token");
          window.location.reload(false);
        } else if (response.status === 403) {
          localStorage.removeItem("token");
          window.location.reload(false);
        } else if (response.status === 422) {
          setPasswordError({
            ...passwordError,
            password: response.data.message,
          });
        }
        setPasswordLoader(false);
      });
    }
  };
  return {
    handlePasswordChange,
    passwordValues,
    handlePasswordSubmit,
    passwordError,
    errors1,
    errors2,
  };
};
export default useChangePasswordForm;
