import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TextField,
} from '@mui/material';
import styles from './StepOne.module.css';

function StepOne({ packageData }) {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles['form-container']}>
        <div className={styles.fields}>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="noofStudents"
              label={t('numOfStudents')}
              name="number"
              value={packageData.students_amount}
              type="number"
              variant="outlined"
              disabled
            />
          </div>

          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="duration"
              label={t('durationInDays')}
              name="duration"
              value={packageData.duration}
              type="number"
              variant="outlined"
              disabled
            />
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="price"
              label={t('price')}
              name="price"
              value={packageData.price}
              type="number"
              multiline
              variant="outlined"
              disabled
            />
          </div>

        </div>
      </div>

    </div>
  );
}

export default StepOne;

StepOne.propTypes = {
  packageData: PropTypes.arrayOf(PropTypes.shape({
    students_amount: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  })).isRequired,
};
