import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Spinner from "../../Loader";
import { deleteNotification } from "../../Services/adminServices";

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  [`&.${tableCellClasses.head}`]: {
    color: "#4F4F4F",
    backgroundColor: "#ffeecc",
    height: "17px",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.08em",
    textTransform: "uppercase",
  },
}));

const root = {
  width: "97%",
};

const icons = {
  display: "flex",
  justifyContent: "space-between",
  width: "100px",
};

export default function NotificationsTable({
  setNotificationsToViewed,
  setViewNotificationsModal,
  notifications,
  setUpdateTable,
  updateTable,
}) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const columns = [
    { id: "id", label: t("id"), minWidth: 16 },
    {
      id: "description",
      label: t("textEN"),
      minWidth: 180,
    },
    {
      id: "arabic_description",
      label: t("textAR"),
      minWidth: 180,
    },
    {
      id: "user_type",
      label: t("userType"),
      minWidth: 180,
    },
    {
      id: "created_at",
      label: t("dateCreated"),
      minWidth: 180,
    },
  ];
  const handleView = (id) => {
    setNotificationsToViewed(id);
    setViewNotificationsModal(true);
  };
  const handleDelete = (id) => {
    setLoader(true);
    deleteNotification(id).then((response) => {
      if (response.status === 200 || response.status === 201) {
        setUpdateTable(updateTable);
      } else {
        // console.log(response.data);
      }
      setLoader(false);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={root}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: column.backgroundColor,
                    border: column.border,
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">{t("operation")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </StyledTableCell>
                    );
                  })}
                  {columns
                    .filter((column) => column.id === "description")
                    .map((column) => (
                      <StyledTableCell key={column.id}>
                        {loader ? (
                          <Spinner />
                        ) : (
                          <div style={icons}>
                            <VisibilityIcon
                              onClick={() => {
                                handleView(row.id);
                              }}
                            />
                            <DeleteIcon
                              onClick={() => {
                                handleDelete(row.id);
                              }}
                            />
                          </div>
                        )}
                      </StyledTableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={notifications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
NotificationsTable.propTypes = {
  setNotificationsToViewed: PropTypes.func.isRequired,
  setViewNotificationsModal: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      arabic_description: PropTypes.string.isRequired,
      user_type: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      is_active: PropTypes.number.isRequired,
    })
  ).isRequired,
  setUpdateTable: PropTypes.func.isRequired,
  updateTable: PropTypes.number.isRequired,
};
