/* eslint-disable camelcase */
import axios from "axios";
import cookies from "js-cookie";
import configData from "../config/production.json";
import axiosInstance from "./axiosInstance";

const { SERVER_URL } = configData;

export default async function signin(props) {
  const { email, password } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/login`,
      {
        email,
        password,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function forgotPassword(props) {
  const { email } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/forgot-password`,
      {
        email,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function resetPassword(props) {
  const { token, email, password, password_confirmation } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/reset-password`,
      {
        token,
        email,
        password,
        password_confirmation,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function GetAdminUsers() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.get(
      `${SERVER_URL}/api/users`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function addAdmin(props) {
  const {
    fname,
    mname,
    lname,
    email,
    gender,
    mobile,
    role,
    password1,
    password2,
  } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/register`,
      {
        fname,
        mname,
        lname,
        email,
        gender,
        mobile,
        role_id: role,
        password: password1,
        password_confirmation: password2,
        user_type: "admin",
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function editAdmin(props) {
  const {
    id,
    fname,
    mname,
    lname,
    email,
    gender,
    mobile,
    role,
    password1,
    password2,
  } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.patch(
      `${SERVER_URL}/api/users/${id}`,
      {
        fname,
        mname,
        lname,
        email,
        gender,
        mobile,
        role_id: role,
        password: password1,
        password_confirmation: password2,
        user_type: "admin",
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function ActivateUser(id, activation) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/users-activate`,
      {
        user_id: id,
        is_active: activation,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function deleteUser(id) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.delete(`${SERVER_URL}/api/users/${id}`, {
      headers: {
        accept: "application/json",
        "accept-language": currentLanguageCode,
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    });
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function deleteSkill(id) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.delete(`${SERVER_URL}/api/skills/${id}`, {
      headers: {
        accept: "application/json",
        "accept-language": currentLanguageCode,
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    });
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function deleteSP(id) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.delete(`${SERVER_URL}/api/service-provider/${id}`, {
      headers: {
        accept: "application/json",
        "accept-language": currentLanguageCode,
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    });
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function GetRoles() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.get(
      `${SERVER_URL}/api/roles`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function GetCaregivers() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/users-types`,
      {
        user_type: "caregiver",
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function createCategory(props) {
  const { name, arabic_name, sort_order } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/categories`,
      {
        name,
        arabic_name,
        sort_order,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function getCategories() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.get(
      `${SERVER_URL}/api/categories`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function editCategory(props) {
  const { id, name, arabic_name, sort_order, service_provider_service_ids } =
    props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.patch(
      `${SERVER_URL}/api/categories/${id}`,
      {
        name,
        arabic_name,
        sort_order,
        service_provider_service_ids,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function getSpecialities() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.get(
      `${SERVER_URL}/api/skills`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function createSkill(props) {
  const { name, arabic_name, sort_order, category_id } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/skills`,
      {
        name,
        arabic_name,
        sort_order,
        category_id,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function editSkill(props) {
  const { id, name, arabic_name, sort_order, category_id } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.patch(
      `${SERVER_URL}/api/skills/${id}`,
      {
        name,
        arabic_name,
        sort_order,
        category_id,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function getStudents() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");

    response = await axios.get(
      `${SERVER_URL}/api/students`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function getActivities() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");

    response = await axios.get(
      `${SERVER_URL}/api/activities`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function createActivity(props) {
  const {
    name,
    arabic_name,
    description,
    arabic_description,
    // category_id,
    skill_id,
  } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/activities`,
      {
        name,
        arabic_name,
        description,
        arabic_description,
        // category_id,
        skill_id,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function editActivity(props) {
  const {
    id,
    name,
    arabic_name,
    description,
    arabic_description,
    // category_id,
    skill_id,
  } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.patch(
      `${SERVER_URL}/api/activities/${id}`,
      {
        name,
        arabic_name,
        description,
        arabic_description,
        // category_id,
        skill_id,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function getOtherActivities() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");

    response = await axios.get(
      `${SERVER_URL}/api/other-activities`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function getEvaluations() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");

    response = await axios.get(
      `${SERVER_URL}/api/evaluations`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function createEvaluation(props) {
  const { is_active, description, arabic_description } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/evaluations`,
      {
        is_active,
        description,
        arabic_description,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function editEvaluation(props) {
  const { id, is_active, description, arabic_description } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.patch(
      `${SERVER_URL}/api/evaluations/${id}`,
      {
        is_active,
        description,
        arabic_description,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function ActivateEvaluation(id, activation) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/evaluations-activate`,
      {
        evaluation_id: id,
        is_active: activation,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function ActivateCategories(id, activation) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/categories-activate`,
      {
        category_id: id,
        is_active: activation,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function getPackages() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");

    response = await axios.get(
      `${SERVER_URL}/api/packages`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function ActivatePackages(id, activation) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/packages-activate`,
      {
        package_id: id,
        is_active: activation,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function getNotifications() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");

    response = await axios.get(
      `${SERVER_URL}/api/app-notifications`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function getCountries() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.get(
      `${SERVER_URL}/api/countries`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}
export async function getGovernorates(country_id) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.get(
      `${SERVER_URL}/api/governorates/${country_id}`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}
export async function getCities(governorate_id) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.get(
      `${SERVER_URL}/api/cities/${governorate_id}`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}
export async function getSpTypes() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");

    response = await axios.get(
      `${SERVER_URL}/api/service-provider-type`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function createNotification(props) {
  const { description, arabic_description, user_type, country_id } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/app-notifications`,
      {
        description,
        arabic_description,
        user_type,
        country_id,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function ActivateActivities(id, activation) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/activities-activate`,
      {
        activity_id: id,
        is_active: activation,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function createPackage(props) {
  const {
    name,
    arabic_name,
    description,
    students_amount,
    duration,
    price,
    arabic_description,
    country_id,
    waiting_list,
    student_files,
    working_caregiver,
    sort_order,
  } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/packages`,
      {
        name,
        arabic_name,
        description,
        students_amount,
        is_active: 1,
        duration,
        price,
        arabic_description,
        country_id,
        waiting_list,
        student_files,
        working_caregiver,
        sort_order,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function editPackage(props) {
  const {
    id,
    name,
    arabic_name,
    description,
    students_amount,
    duration,
    price,
    arabic_description,
    country_id,
    waiting_list,
    student_files,
    working_caregiver,
    sort_order,
  } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.patch(
      `${SERVER_URL}/api/packages/${id}`,
      {
        name,
        arabic_name,
        description,
        students_amount,
        is_active: 1,
        duration,
        price,
        arabic_description,
        country_id,
        waiting_list,
        student_files,
        working_caregiver,
        sort_order,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function getUser() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");

    response = await axios.get(
      `${SERVER_URL}/api/user`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function changePassword(props) {
  const { user_id, old_password, password, password_confirmation } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/users-changepassword`,
      {
        user_id,
        old_password,
        password,
        password_confirmation,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function getServices() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");

    response = await axios.get(
      `${SERVER_URL}/api/service-provider/create`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function createServiceProvider(props) {
  const {
    name,
    email,
    mobile,
    logo,
    location,
    governorate_id,
    area,
    files,
    country_id,
    comercial_register,
    licencing_authority,
    licensing_number,
    licencing_expired,
    id_number,
    gender,
    nationality,
    services,
    service_provider_type_id,
    package_id,
    password,
    password_confirmation,
  } = props;
  const formData = new FormData();
  formData.append("name", name);
  formData.append("email", email);
  formData.append("phone", mobile);
  formData.append("logo", logo);
  formData.append("location", location);
  formData.append("governorate_id", governorate_id);
  formData.append("area", area);
  for (let i = 0; i < files.length; i += 1) {
    formData.append("files[]", files[i], files[i].name);
  }
  formData.append("country_id", country_id);
  formData.append("commercial_register", comercial_register);
  formData.append("licensing_authority", licencing_authority);
  formData.append("licensing_number", licensing_number);
  formData.append("licensing_expired", licencing_expired);
  formData.append("id_number", id_number);
  formData.append("gender", gender);
  formData.append("nationality", nationality);
  formData.append("services", JSON.stringify(services));
  formData.append("service_provider_type_id", service_provider_type_id);
  formData.append("package_id", package_id);
  formData.append("password", password);
  formData.append("password_confirmation", password_confirmation);
  let response = "";

  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/service-provider`,
      formData,
      {
        headers: {
          "accept-language": currentLanguageCode,
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function validateServiceProvider(props) {
  const { email, password, password_confirmation, service_provider_id } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/service-provider-check-data`,
      {
        email,
        password,
        password_confirmation,
        service_provider_id,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function updateUser(props) {
  const {
    country,
    country_id,
    created_at,
    email,
    first_name,
    gender,
    governorate,
    governorate_id,
    is_active,
    last_name,
    middle_name,
    mobile,
    photo,
    role,
    role_id,
    updated_at,
    user_id,
    user_type,
  } = props;
  const formData = new FormData();
  formData.append("_method", "patch");
  formData.append("fname", first_name);
  formData.append("lname", last_name);
  formData.append("email", email);
  formData.append("mobile", mobile);
  formData.append("country", country);
  formData.append("photo", photo);
  formData.append("created_at", created_at);
  formData.append("governorate_id", governorate_id);
  formData.append("governorate", governorate);
  formData.append("is_active", is_active);
  formData.append("country_id", country_id);
  formData.append("gender", gender);
  formData.append("mname", middle_name);
  formData.append("role", role);
  formData.append("role_id", role_id);
  formData.append("updated_at", updated_at);
  formData.append("user_type", user_type);
  let response = "";

  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/users/${user_id}`,
      formData,
      {
        headers: {
          "accept-language": currentLanguageCode,
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function getRoles() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");

    response = await axios.get(
      `${SERVER_URL}/api/roles`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function createRole(props) {
  const { name, permissions } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/roles`,
      {
        name,
        permissions,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function editRole(props) {
  const { id, name, permissions } = props;
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.patch(
      `${SERVER_URL}/api/roles/${id}`,
      {
        name,
        permissions,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function deleteNotification(id) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.delete(`${SERVER_URL}/api/app-notifications/${id}`, {
      headers: {
        accept: "application/json",
        "accept-language": currentLanguageCode,
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    });
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}
export async function deleteCategories(id) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.delete(`${SERVER_URL}/api/categories/${id}`, {
      headers: {
        accept: "application/json",
        "accept-language": currentLanguageCode,
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    });
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function getServiceProviders() {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");

    response = await axios.get(
      `${SERVER_URL}/api/service-provider`,
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
      {}
    );
    // Success
    return response;
  } catch (error) {
    return error.response;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log('Error', error.message);
    // }
    // console.log(error);
  }
}

export async function ActivateServiceProvider(id, activation) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/service-provider-activate`,
      {
        service_provider_id: id,
        is_active: activation,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function VerifyUser(id) {
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/user-verify`,
      {
        user_id: id,
      },
      {
        headers: {
          accept: "application/json",
          "accept-language": currentLanguageCode,
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    if (error.request) {
      //   console.log(error.request);
    } else {
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function editServiceProvider(props) {
  const {
    id,
    name,
    email,
    mobile,
    logo,
    location,
    governorate_id,
    area,
    files,
    country_id,
    comercial_register,
    licencing_authority,
    licensing_number,
    licencing_expired,
    id_number,
    gender,
    nationality,
    services,
    service_provider_type_id,
    package_id,
    password,
    password_confirmation,
  } = props;
  const formData = new FormData();
  formData.append("_method", "patch");
  formData.append("service_provider_id", id);
  formData.append("name", name);
  formData.append("email", email);
  formData.append("phone", mobile);
  if (logo !== null) {
    formData.append("logo", logo);
  }
  formData.append("location", location);
  formData.append("governorate_id", governorate_id);
  formData.append("area", area);
  for (let i = 0; i < files.length; i += 1) {
    if (typeof files[i] !== "string") {
      formData.append("files[]", files[i], files[i].name);
    }
  }

  formData.append("country_id", country_id);
  formData.append("commercial_register", comercial_register);
  formData.append("licensing_authority", licencing_authority);
  formData.append("licensing_number", licensing_number);
  formData.append("licensing_expired", licencing_expired);
  formData.append("id_number", id_number);
  formData.append("gender", gender);
  formData.append("nationality", nationality);
  formData.append("services", JSON.stringify(services));
  formData.append("service_provider_type_id", service_provider_type_id);
  formData.append("package_id", package_id);
  formData.append("password", password);
  formData.append("password_confirmation", password_confirmation);
  let response = "";
  try {
    const currentLanguageCode = cookies.get("i18next");
    response = await axios.post(
      `${SERVER_URL}/api/service-provider/${id}`,
      formData,
      {
        headers: {
          "accept-language": currentLanguageCode,
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    // Success
    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      return error.response;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //   console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //   console.log('Error', error.message);
    }
    // console.log(error);
    return response;
  }
}

export async function getCoupons() {
  const response = await axiosInstance.get(`/api/coupons`);
  return response.data;
}

export async function addCoupon(props) {
  const response = await axiosInstance.post(`/api/coupons`, props);
  return response.data;
}

export async function activateCoupons(id, props) {
  const response = await axiosInstance.patch(`/api/coupons/${id}`, {
    activate: props,
  });
  return response.data;
}
