import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styles from './UserCard.module.css';
import configData from '../../config/production.json';

const { SERVER_URL } = configData;
function UserCard({ user }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload(false);
    setAnchorEl(null);
  };
  const handleProfile = () => {
    navigate('/profile');
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.container}
      >
        <div className={styles.image}>
          <img src={`${SERVER_URL}/storage/${user.photo}`} alt="avatar" className={styles.avatar} />
        </div>
        <div className={styles.user}>
          <p className={styles.name}>{(user.first_name === undefined) ? '' : `${user.first_name} ${user.last_name}`.length > 11 ? `${`${user.first_name} ${user.last_name}`.substring(0, 14 - 3)}...` : `${user.first_name} ${user.last_name}`}</p>
          <p className={styles.role}>{(user.role === undefined) ? '' : user.role}</p>
        </div>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => { setAnchorEl(null); }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.menu}>
          <MenuItem onClick={handleProfile}>Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </div>
      </Menu>
    </>
  );
}

export default UserCard;
UserCard.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
    photo: PropTypes.string,
  }).isRequired,
};
