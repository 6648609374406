import React, { useState } from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import ButtonGroup from "@mui/material/ButtonGroup";
import LanguageIcon from "@mui/icons-material/Language";
import LoginForm from "./Components/LoginForm";
import styles from "./Login.module.css";
import i18next from "../../i18next";
import ActivationModal from "./Components/ActivationModal";
import SuspenseLoading from "../../SuspenseLoading";

const color = {
  color: "black",
};
function AdminLogin({ needActivation, setNeedActivation }) {
  const [suspend, setSuspend] = useState(false);
  const setActivationModal = (e) => {
    setNeedActivation(e);
  };

  if (suspend) {
    return <SuspenseLoading />;
  }
  return (
    <>
      <ActivationModal
        confirm={needActivation}
        setConfirm={setActivationModal}
      />
      <div className={styles["login-container"]}>
        <img
          src="./logo/Logo-Outer.svg"
          width={123}
          height={162}
          alt="login logo"
        />
        <LoginForm setSuspend={setSuspend} />
      </div>
      <div className="languages-container">
        <LanguageIcon sx={color} className="language-icon" />
        <ButtonGroup
          sx={color}
          variant="text"
          aria-label="text primary button group"
        >
          <Button
            sx={color}
            onClick={() => i18next.changeLanguage("en")}
            className="languages-buttons"
          >
            English
          </Button>
          <Button
            sx={color}
            onClick={() => i18next.changeLanguage("ar")}
            className="languages-buttons"
          >
            Arabic
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
}

export default AdminLogin;
AdminLogin.propTypes = {
  needActivation: PropTypes.bool.isRequired,
  setNeedActivation: PropTypes.func.isRequired,
};
