import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import styles from "./StepOne.module.css";
import { editCategory, getServices } from "../../../Services/adminServices";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
    },
  },
  MenuListProps: {
    sx: {
      "&& .Mui-selected": {
        backgroundColor: "navajowhite",
        "&:hover": {
          backgroundColor: "navajowhite",
        },
      },
    },
  },
};

function StepOne({
  setStepOne,
  setStepThree,
  categoriesToEdit,
  handleUpdateTable,
  data,
}) {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [serviceProviderServices, setServiceProviderServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState(
    data?.services?.map((s) => s.id) || []
  );

  const [values, setValues] = useState({
    id: categoriesToEdit,
    name: data.english_name,
    arabic_name: data.arabic_name,
    sort_order: data.sort_order,
  });
  const [error, setError] = useState({});

  useEffect(() => {
    // Fetch ServiceProviderServices
    getServices().then((response) => {
      if (response.status === 200) {
        setServiceProviderServices(response.data.services);
      }
    });
  }, []);

  function validateStepOne(valuesIn) {
    const errors = {};
    if (!valuesIn.sort_order) {
      errors.frontend = "order required";
    } else if (valuesIn.sort_order <= 0) {
      errors.frontend = "order not valid";
    }
    if (!valuesIn.arabic_name.trim()) {
      errors.frontend = "name required";
    } else if (/^[A-Za-z]+/.test(valuesIn.arabic_name.trim())) {
      errors.frontend = t("validName");
    }
    if (!valuesIn.name.trim()) {
      errors.frontend = "name required";
    } else if (!/^[A-Za-z]+/.test(valuesIn.name.trim())) {
      errors.frontend = t("validName");
    }

    return errors;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleServiceChange = (e) => {
    setSelectedServices(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there are no errors
      setLoader(true);
      editCategory({
        ...values,
        service_provider_service_ids: selectedServices,
      }).then((response) => {
        if (response.status === 200) {
          handleUpdateTable();
          setStepOne(false);
          setStepThree(true);
        } else if (response.status === 422) {
          setError({
            ...error,
            frontend: response.data.message,
          });
        }
        setLoader(false);
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles["form-container"]}>
        <div className={styles.fields}>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="name"
              label={t("nameEN")}
              name="name"
              required
              value={values.name}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.nameEN && "secondary"}
            />
            {values.name && (
              <HighlightOffIcon
                className={styles.cancel}
                onClick={() => {
                  setValues({
                    ...values,
                    name: "",
                  });
                }}
              />
            )}
          </div>

          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="arabic_name"
              label={t("nameAR")}
              name="arabic_name"
              required
              value={values.arabic_name}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.nameAR && "secondary"}
            />
            {values.arabic_name && (
              <HighlightOffIcon
                className={styles.cancel}
                onClick={() => {
                  setValues({
                    ...values,
                    arabic_name: "",
                  });
                }}
              />
            )}
          </div>

          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sort_order"
              label={t("order")}
              name="sort_order"
              value={values.sort_order}
              required
              onChange={handleChange}
              InputProps={{ inputProps: { min: 1 } }}
              type="number"
              variant="outlined"
              color={error.order && "secondary"}
            />
          </div>

          <div className={styles["field-container"]}>
            <FormControl className={styles["text-field"]} variant="outlined">
              <InputLabel>{t("Service")}</InputLabel>
              <Select
                label={t("Service")}
                multiple
                value={selectedServices}
                onChange={handleServiceChange}
                renderValue={(selected) =>
                  selected
                    .map(
                      (id) =>
                        serviceProviderServices.find(
                          (service) =>
                            service.service_provider_service_id ===
                            parseInt(id, 10)
                        )?.name || id
                    )
                    .join(", ")
                }
                MenuProps={MenuProps}
              >
                {serviceProviderServices.map((service) => (
                  <MenuItem
                    key={service.service_provider_service_id}
                    value={service.service_provider_service_id}
                  >
                    {i18n.language === "en"
                      ? service.name
                      : service.arabic_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      {error.frontend && <p className={styles.error}>{error.frontend}</p>}
      <div className={styles["button-container"]}>
        <Button
          disabled={loader}
          type="submit"
          variant="contained"
          className={styles["next-button"]}
        >
          {loader ? t("wait") : t("confirm")}
        </Button>
      </div>
    </form>
  );
}

StepOne.propTypes = {
  setStepOne: propTypes.func.isRequired,
  handleUpdateTable: propTypes.func.isRequired,
  setStepThree: propTypes.func.isRequired,
  categoriesToEdit: propTypes.number.isRequired,
  data: propTypes.shape({
    english_name: propTypes.string.isRequired,
    arabic_name: propTypes.string.isRequired,
    sort_order: propTypes.number.isRequired,
    service_provider_service_ids: propTypes.arrayOf(propTypes.number),
  }).isRequired,
};

export default StepOne;
