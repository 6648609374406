/* eslint-disable no-nested-ternary */
import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

function LoggedOut() {
  const token = localStorage.token ? localStorage.token : null;
  const location = useLocation();
  return (
    (token === null) ? <Outlet />
      : <Navigate to="/" state={{ from: location }} replace />

  );
}

export default LoggedOut;
