import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ImageIcon from '@mui/icons-material/Image';
import styles from './Profile.module.css';

import { getUser } from '../../Services/adminServices';
import useChangeProfile from '../Profile/useProfilePage';
import useChangePasswordForm from '../Profile/useChangePassword';
import configData from '../../config/production.json';

const { SERVER_URL } = configData;

function Profile() {
  const { t } = useTranslation();
  function validateProfile(values) {
    const errors = {};
    if (!values.first_name.trim()) {
      errors.first_name = 'required';
      errors.frontend = 'required';
    } else if (!/^[A-Za-z]+/.test(values.first_name.trim())) {
      errors.first_name = t('validName');
      errors.frontend = t('validName');
    }
    if (!values.last_name.trim()) {
      errors.last_name = 'required';
      errors.frontend = 'required';
    } else if (!/^[A-Za-z]+/.test(values.last_name.trim())) {
      errors.last_name = t('validName');
      errors.frontend = t('validName');
    }
    if (!values.email) {
      errors.email = t('emailRequired');
      errors.frontend = t('emailRequired');
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = t('emailNotValid');
      errors.frontend = t('emailNotValid');
    }
    if (!values.mobile) {
      errors.mobile = 'number required';
      errors.frontend = 'number required';
    }
    if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/.test(values.mobile)) {
      if (!/(\d{4})[- ]?(\d{3})[- ]?(\d{4})$/.test(values.mobile)) {
        errors.mobile = t('valideNumber');
        errors.frontend = t('valideNumber');
      }
    }
    if (values.mobile.length < 11) {
      errors.mobile = t('valideNumber');
      errors.frontend = t('valideNumber');
    }
    return errors;
  }
  function validateSetPassword(values) {
    const errors = {};
    const errors1 = {};
    const errors2 = {};
    if (!values.password) {
      errors.password = t('passwordRequired');
    }
    if (!values.password1) {
      errors1.password1 = t('passwordRequired');
    } else if (values.password1.length < 8) {
      errors1.password1 = t('passShort');
    } else if (!/(?=.*?[A-Z])/.test(values.password1)) {
      errors1.password1 = t('passwordRule');
    } else if (!/(?=.*?[a-z])/.test(values.password1)) {
      errors1.password1 = t('passwordRule');
    } else if (!/(?=.*?[0-9])/.test(values.password1)) {
      errors1.password1 = t('passwordRule');
    } else if (!/(?=.*?[#?!@$%^&*-/_])/.test(values.password1)) {
      errors1.password1 = t('passwordRule');
    }
    if (values.password1 !== values.password2) {
      errors2.password2 = t('missMatchPassword');
    }
    return { errors, errors1, errors2 };
  }
  const [user, setUser] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [passwordLoader, setPasswordLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const {
    handleChange, values, handleSubmit, error, setValues,
  } = useChangeProfile(setLoader, validateProfile);
  useEffect(() => {
    (async () => {
      const response = await getUser();
      if (response.status === 200) {
        setUser(response.data.user);
        setValues(response.data.user);
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
  }, []);
  const {
    handlePasswordChange, passwordValues, handlePasswordSubmit, passwordError, errors1, errors2,
  } = useChangePasswordForm(user, validateSetPassword, setPasswordLoader);
  const handleSelectImage = (event) => {
    setSelectedImage(event.target.files[0]);
    setValues({
      ...values,
      photo: event.target.files[0],
    });
  };
  return (

    <div className={styles.container}>
      <div>
        <p className={styles.title}>{t('personal-info')}</p>
        <div className={styles.border} />
      </div>
      <form onSubmit={handleSubmit} className={styles['form-container']}>
        <div className={styles['fields-container']}>
          <div className={styles.fields}>
            <div className={styles['mini-form']}>
              <TextField
                className={styles['text-field']}
                id="change-first-name"
                label={t('fName')}
                required
                name="first_name"
                placeholder={user.first_name}
                value={values.first_name}
                onChange={handleChange}
                type="text"
                variant="outlined"
                color={error.first_name && 'secondary'}
              />
              {values.first_name
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              first_name: '',
            });
          }}
        />
        )}
              {(error.first_name) && <p className={styles.error}>{error.first_name}</p>}
            </div>
            <div className={styles['mini-form']}>
              <TextField
                className={styles['text-field']}
                id="change-last-name"
                label={t('lName')}
                name="last_name"
                required
                placeholder={user.last_name}
                value={values.last_name}
                onChange={handleChange}
                type="text"
                variant="outlined"
                color={error.last_name && 'secondary'}
              />
              {values.last_name
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              last_name: '',
            });
          }}
        />
        )}
              {(error.last_name) && <p className={styles.error}>{error.last_name}</p>}
            </div>
            <div className={styles['mini-form']}>
              <TextField
                className={styles['text-field']}
                id="login-email"
                label={t('email')}
                name="email"
                placeholder={user.email}
                value={values.email}
                onChange={handleChange}
                required
                type="email"
                variant="outlined"
                color={error.email && 'secondary'}
              />
              {values.email
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              email: '',
            });
          }}
        />
        )}
              {(error.email) && <p className={styles.error}>{error.email}</p>}
            </div>
            <div className={styles['mini-form']}>
              <TextField
                className={styles['text-field']}
                id="change-number"
                label={t('mobile')}
                required
                placeholder={user.mobile}
                name="mobile"
                value={values.mobile}
                onChange={handleChange}
                type="text"
                variant="outlined"
                color={error.mobile && 'secondary'}
              />
              {values.mobile
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              mobile: '',
            });
          }}
        />
        )}
              {(error.mobile) && <p className={styles.error}>{error.mobile}</p>}
            </div>
          </div>
          <div className={styles.image}>
            <div className={styles['image-container']}>
              <img src={selectedImage ? URL.createObjectURL(selectedImage) : `${SERVER_URL}/storage/${values.photo}`} width={250} height={250} alt="avatar" className={styles.avatar} />
            </div>
            <div className={styles['image-icon']}>
              <ImageIcon className={styles.icon} />
              <input
                type="file"
                className={styles.icon}
                style={{ opacity: 0 }}
                onChange={handleSelectImage}
              />
            </div>

          </div>
        </div>
        <div className={styles['change-button-container']}>
          <Button disabled={loader} type="submit" variant="contained" className={styles['change-button']}>
            {passwordLoader ? t('wait') : t('change')}
          </Button>
        </div>
      </form>
      <div>
        <p className={styles.title}>{t('change-password')}</p>
        <div className={styles.border} />
      </div>
      <div>
        <form onSubmit={handlePasswordSubmit} className={styles['change-password-form']}>
          <div className={styles['change-password-fields']}>
            <div>
              <TextField
                className={styles['text-field']}
                id="old-password"
                label={t('password')}
                name="password"
                required
                value={passwordValues.password}
                onChange={handlePasswordChange}
                type="password"
                variant="outlined"
                color={passwordError.password && 'secondary'}
              />
              {passwordError.password && <p className={styles.error}>{passwordError.password}</p>}
            </div>
            <div className={styles['new-password']}>
              <div>
                <TextField
                  className={styles['text-field']}
                  id="change-password"
                  label={t('newPassword')}
                  name="password1"
                  value={passwordValues.password1}
                  required
                  onChange={handlePasswordChange}
                  type="password"
                  variant="outlined"
                  color={errors1.password1 && 'secondary'}
                />
                {errors1.password1 && <p className={styles.error}>{errors1.password1}</p>}
              </div>
              {/* </div>
            <div> */}
              <div>
                <TextField
                  className={styles['text-field']}
                  id="confirm-password"
                  label={t('confirmPassword')}
                  name="password2"
                  value={passwordValues.password2}
                  onChange={handlePasswordChange}
                  required
                  type="password"
                  variant="outlined"
                  color={errors2.password2 && 'secondary'}
                />
                {errors2.password2 && <p className={styles.error}>{errors2.password2}</p>}
              </div>
            </div>

          </div>
          <div className={styles['change-password-button-container']}>
            <Button disabled={passwordLoader} type="submit" variant="contained" className={styles['change-button']}>
              {passwordLoader ? t('wait') : t('change')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profile;
