import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import propTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import styles from './ActivationModal.module.css';

const modal = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

};
const paperAR = {
  position: 'relative',
  border: 'none',
  maxWidth: '350px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  direction: 'rtl',
};
const paperEN = {
  position: 'relative',
  border: 'none',
  maxWidth: '350px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  direction: 'ltr',
};

export default function ActivationModal({ confirm, setConfirm }) {
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(confirm);
  }, [confirm]);

  const handleClose = () => {
    setConfirm(false);
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        sx={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={currentLanguageCode === 'en' ? paperEN : paperAR}>
            <h2 id="confirm-modal-title" className={styles.title}>{t('deactivated')}</h2>
            <p id="confirm-modal-description" className={styles.paragraph}>{t('contactForActivation')}</p>
            <div className={styles['button-container']}>
              <button type="button" onClick={handleClose} className={styles['confirm-button']}>
                {t('confirm')}
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
ActivationModal.propTypes = {
  confirm: propTypes.bool.isRequired,
  setConfirm: propTypes.func.isRequired,
};
