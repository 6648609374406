/* eslint-disable eqeqeq */
import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PackageCard from './PackageCard';
import styles from './StepTwo.module.css';

import { createServiceProvider, getPackages } from '../../../Services/adminServices';

function StepTwo({
  setStepTwo, setStepThree, SpValues, setSpValues, handleUpdateTable,
}) {
  const { t } = useTranslation();
  const [choosePack, setChoosePack] = useState(0);
  const [loader, setLoader] = useState(false);
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState('');
  useEffect(() => {
    (async () => {
      const response = await getPackages();
      if (response.status === 200) {
        setPackages(response.data.packages);
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
  }, []);
  // const packages = [
  //   {
  //     id: 1,
  //     admisionPortal: true,
  //     studentsFiles: false,
  //     followUp: false,
  //     students: 'unlimited',
  //     price: '999 L.E.',
  //   },
  //   {
  // ]
  const handleSubmit = (e) => {
    e.preventDefault();
    setSpValues(
      {
        ...SpValues,
        package_id: choosePack,
      },
    );
    setLoader(true);
    createServiceProvider({
      ...SpValues,
      package_id: choosePack,
    }).then((response) => {
      if (response.status === 200) {
        handleUpdateTable();
        setStepTwo(false);
        setStepThree(true);
      } else if (response.status === 422) {
        setError(response.data.message);
      }
      setLoader(false);
    });
  };
  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      {packages.filter((pack) => (pack.country_id == SpValues.country_id)).map((pack) => (
        <PackageCard
          pack={pack}
          choosePack={choosePack}
          setChoosePack={setChoosePack}
          key={pack.id}
        />
      ))}
      {error && <p className={styles.error}>{error}</p>}
      <Button type="submit" className={styles.confirm} disabled={choosePack.length === 0 || loader}>
        {loader ? t('wait') : t('confirm')}
      </Button>
    </form>
  );
}

export default StepTwo;
StepTwo.propTypes = {
  setStepTwo: PropTypes.func.isRequired,
  setStepThree: PropTypes.func.isRequired,
  setSpValues: PropTypes.func.isRequired,
  handleUpdateTable: PropTypes.func.isRequired,
  SpValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    logoName: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    governorate: PropTypes.string.isRequired,
    country_id: PropTypes.number.isRequired,
    area: PropTypes.string.isRequired,
    documents: PropTypes.string.isRequired,
    password1: PropTypes.string.isRequired,
    password2: PropTypes.string.isRequired,
    services: PropTypes.arrayOf(PropTypes.shape({
      service_provider_service_id: PropTypes.number.isRequired,
      service_provider_service_payment_id: PropTypes.number.isRequired,
      service_provider_service_type_id: PropTypes.number.isRequired,
    })),
  }).isRequired,
};
