/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Cookies from 'js-cookie';
import styles from './Evaluation.module.css';
import EvaluationTable from '../../Evaluation/EvaluationTable';
import EvaluationModal from '../../Evaluation/EvaluationModal';
import EditEvaluationModal from '../../Evaluation/EditEvaluation/EditEvaluation';
import ViewEvaluationModal from '../../Evaluation/ViewEvaluation/ViewEvaluation';

import { getEvaluations } from '../../../Services/adminServices';
import Excelexport from '../../ActivityBank/Excelexport';

function Evaluatiom() {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [newEvaluationModal, setNewEvaluationModal] = useState(false);
  const [editEvaluationModal, setEditEvaluationModal] = useState(false);
  const [viewEvaluationModal, setViewEvaluationModal] = useState(false);
  const [evaluationToEdit, setEvaluationToEdit] = useState(-1);
  const [evaluationToViewed, setEvaluationToViewed] = useState(-1);
  const [evaluations, setEvaluations] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const currentLanguageCode = Cookies.get('i18next');

  useEffect(() => {
    (async () => {
      const response = await getEvaluations();
      if (response.status === 200) {
        setEvaluations(response.data.evaluations);
        setFiltered(response.data.evaluations);
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
  }, [updateTable, currentLanguageCode]);
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setFiltered(
      evaluations.filter((act) => (act.id == value
      || act.description.toLowerCase().includes(value)
      || act.arabic_description.toLowerCase().includes(value))),
    );
    if (value <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(evaluations);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // search
    setFiltered(
      evaluations.filter((act) => (act.id == searchValue
      || act.description.toLowerCase().includes(searchValue)
      || act.arabic_description.toLowerCase().includes(searchValue))),
    );
    if (searchValue <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(evaluations);
    }
  };
  return (
    <>
      {newEvaluationModal && (
      <EvaluationModal
        newEvaluationModal={newEvaluationModal}
        setNewEvaluationModal={setNewEvaluationModal}
        setUpdateTable={setUpdateTable}
        updateTable={updateTable}
      />
      )}
      {editEvaluationModal && (
        <EditEvaluationModal
          setEditEvaluationModal={setEditEvaluationModal}
          editEvaluationModal={editEvaluationModal}
          evaluationToEdit={evaluationToEdit}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          evaluations={evaluations}
        />
      )}
      {viewEvaluationModal && (
        <ViewEvaluationModal
          setViewEvaluationModal={setViewEvaluationModal}
          viewEvaluationModal={viewEvaluationModal}
          evaluationToViewed={evaluationToViewed}
          evaluations={evaluations}
        />
      )}

      <div className={styles['sp-container']}>
        <div className={styles.container}>
          <form onSubmit={handleSearch} className={styles.header}>
            <SearchIcon style={{ marginRight: '20px' }} />
            <input
              className={styles['search-bar']}
              placeholder={t('searchForSomething')}
              onChange={handleChange}
            />
            <input type="submit" hidden />
          </form>
          <div className={styles.export}>
            <div style={{ width: '50px' }}>
              <Excelexport excelData={evaluations} fileName="Evaluation" />
            </div>
            <Button onClick={() => { setNewEvaluationModal(true); }} className={styles['add-button']} variant="contained">
              <AddIcon />
              <span className={styles['button-text']}>{t('createQuestion')}</span>
            </Button>
          </div>
        </div>
        <EvaluationTable
          setEditEvaluationModal={setEditEvaluationModal}
          setEvaluationToEdit={setEvaluationToEdit}
          setEvaluationToViewed={setEvaluationToViewed}
          setViewEvaluationModal={setViewEvaluationModal}
          evaluations={filtered}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      </div>
    </>
  );
}

export default Evaluatiom;
