import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CloseIcon from "@mui/icons-material/Close";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import Spinner from "../../Loader";
import { activateCoupons } from "../../Services/adminServices";

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  [`&.${tableCellClasses.head}`]: {
    color: "#4F4F4F",
    backgroundColor: "#ffeecc",
    height: "17px",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.08em",
    textTransform: "uppercase",
  },
}));

const root = {
  width: "97%",
};

const icons = {
  display: "flex",
  justifyContent: "space-between",
};

export default function CouponsTable({
  coupons,
  setUpdateTable,
  updateTable,
  setCouponToEdit,
  setNewCouponsModal,
}) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const columns = [
    {
      id: "id",
      label: t("id"),
      minWidth: 16,
    },
    {
      id: "code",
      label: t("CouponCode"),
    },
    {
      id: "type",
      label: t("CouponType"),
    },
    {
      id: "amount",
      label: t("CouponAmount"),
    },
    {
      id: "expires_at",
      label: t("CouponExpire"),
    },
  ];
  const handleEdit = (row) => {
    setCouponToEdit(row);
    setNewCouponsModal(true);
  };
  const handleLoad = () => {};
  const handleApprove = (id, activation) => {
    setLoader(true);
    activateCoupons(id, !activation)
      .then((response) => {
        setUpdateTable(!updateTable);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={root}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: column.backgroundColor,
                    border: column.border,
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">{t("operation")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : column.id === "type"
                          ? t(row.type)
                          : value}
                      </StyledTableCell>
                    );
                  })}
                  {columns
                    .filter((column) => column.id === "id")
                    .map((column) => (
                      <StyledTableCell key={column.id}>
                        {loader ? (
                          <Spinner />
                        ) : (
                          <div style={icons}>
                            {row.active ? (
                              <CheckIcon
                                className="pointer"
                                style={{ color: "green" }}
                                onClick={
                                  loader
                                    ? () => {
                                        handleLoad();
                                      }
                                    : () => {
                                        handleApprove(row.id, row.active);
                                      }
                                }
                              />
                            ) : (
                              <CloseIcon
                                className="pointer"
                                style={{ color: "red" }}
                                onClick={
                                  loader
                                    ? () => {
                                        handleLoad();
                                      }
                                    : () => {
                                        handleApprove(row.id, row.active);
                                      }
                                }
                              />
                            )}
                            <EditIcon
                              className="pointer"
                              onClick={() => {
                                handleEdit(row);
                              }}
                            />
                          </div>
                        )}
                      </StyledTableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={coupons.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
