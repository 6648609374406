import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import styles from './SPModal.module.css';
import StepOne from './SPSteps/StepOne';
import StepTwo from './SPSteps/StepTwo';
import StepThree from './SPSteps/StepThree';

const modal = {
  display: 'flex',
  alignItems: 'right',
  justifyContent: 'right',
};
const paper = {
  position: 'relative',
  border: 'none',
  maxWidth: '1000px',
  minWidth: '295px',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function SPModal({
  setNewServiceModal, newServiceModal,
  updateTable, setUpdateTable,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [SpValues, setSpValues] = useState({});

  useEffect(() => {
    setOpen(newServiceModal);
  }, [newServiceModal]);

  const handleClose = () => {
    setNewServiceModal(false);
    setOpen(false);
  };
  const handleUpdateTable = () => {
    setUpdateTable(!updateTable);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        sx={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={paper}>
            <p id="newService-modal-description" className={styles.subtitle}>{stepOne ? t('spStepOne') : stepTwo ? t('spStepTwo') : ''}</p>
            <h2 id="newService-modal-title" className={styles.title}>{(stepOne || stepTwo) ? t('addNewSP') : ''}</h2>
            {(stepOne || stepTwo) && <CloseIcon onClick={handleClose} className={styles.close} />}
            {stepOne
              ? (
                <StepOne
                  setStepOne={setStepOne}
                  setStepTwo={setStepTwo}
                  setSpValues={setSpValues}
                  SpValues={SpValues}
                />
              ) : stepTwo
                ? (
                  <StepTwo
                    setStepTwo={setStepTwo}
                    setStepThree={setStepThree}
                    setSpValues={setSpValues}
                    handleUpdateTable={handleUpdateTable}
                    SpValues={SpValues}
                  />
                )
                : stepThree && <StepThree handleClose={handleClose} />}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
SPModal.propTypes = {
  setNewServiceModal: propTypes.func.isRequired,
  setUpdateTable: propTypes.func.isRequired,
  updateTable: propTypes.bool.isRequired,
  newServiceModal: propTypes.bool.isRequired,
};
