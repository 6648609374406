import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import {
  Button, FormControl, InputLabel, Select, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from './StepOne.module.css';
import { createActivity, getSpecialities } from '../../Services/adminServices';

function StepOne({ setStepOne, setStepThree, handleUpdateTable }) {
  const { t } = useTranslation();
  function validateStepOne(values) {
    const errors = {};

    if (!values.arabic_name.trim()) {
      errors.nameAR = 'name required';
      errors.frontend = 'name required';
    } else if (/^[A-Za-z]+/.test(values.arabic_name.trim())) {
      errors.nameAR = t('validName');
      errors.frontend = t('validName');
    }
    if (!values.name.trim()) {
      errors.nameEN = 'name required';
      errors.frontend = 'name required';
    } else if (!/^[A-Za-z]+/.test(values.name.trim())) {
      errors.nameEN = t('validName');
      errors.frontend = t('validName');
    }
    if (!values.arabic_description.trim()) {
      errors.descriptionAR = 'description required';
      errors.frontend = 'description required';
    } else if (/^[A-Za-z]+/.test(values.arabic_description.trim())) {
      errors.descriptionAR = t('validText');
      errors.frontend = t('validText');
    }
    if (!values.description.trim()) {
      errors.description = 'description required';
      errors.frontend = 'description required';
    } else if (!/^[A-Za-z]+/.test(values.description.trim())) {
      errors.descriptionEN = t('validText');
      errors.frontend = t('validText');
    }
    if (!values.skill_id) {
      errors.speciality = 'Role Required';
      errors.frontend = 'Role Required';
    }

    return errors;
  }
  const [values, setValues] = useState({
    name: '',
    arabic_name: '',
    description: '',
    arabic_description: '',
    skill_id: '',
  });
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({});
  const [skills, setSkills] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await getSpecialities();
      if (response.status === 200) {
        setSkills(response.data.skills);
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there is no errors
      setLoader(true);
      createActivity(values).then((response) => {
        if (response.status === 200) {
          handleUpdateTable();
          setStepOne(false);
          setStepThree(true);
        } else if (response.status === 422) {
          setError({
            ...error,
            frontend: response.data.message,
          });
        }
        setLoader(false);
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles['form-container']}>
        <div className={styles.fields}>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="name"
              label={t('nameEN')}
              name="name"
              required
              value={values.name}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.nameEN && 'secondary'}
            />
            {values.name
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              name: '',
            });
          }}
        />
        )}
          </div>

          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="arabic_name"
              label={t('nameAR')}
              name="arabic_name"
              required
              value={values.arabic_name}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.nameAR && 'secondary'}
            />
            {values.arabic_name
        && (
          <HighlightOffIcon
            className={styles.cancel}
            onClick={() => {
              setValues({
                ...values,
                arabic_name: '',
              });
            }}
          />
        )}
          </div>
          <FormControl variant="outlined" className={styles['field-container']}>
            <InputLabel style={{ top: 20, left: 25 }} id="specialities-label" color={error.speciality && 'secondary'}>{t('speciality')}</InputLabel>
            <Select
              className={styles['text-field']}
              labelId="specialities-label"
              native
              required
              label={t('speciality')}
              value={values.skill_id}
              onChange={handleChange}
              color={error.speciality && 'secondary'}
              inputProps={{
                name: 'skill_id',
                id: 'skill_id',
              }}
            >
              <option aria-label="None" value="" />
              {skills.map((speciality) => (
                <option
                  key={speciality.id}
                  value={speciality.id}
                >
                  {speciality.name}

                </option>

              ))}
            </Select>
          </FormControl>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="description"
              label={t('descriptionEN')}
              name="description"
              required
              value={values.description}
              onChange={handleChange}
              type="text"
              multiline
              variant="outlined"
              color={error.descriptionEN && 'secondary'}
            />
            {values.description && (
            <HighlightOffIcon
              className={styles.cancel}
              onClick={() => {
                setValues({
                  ...values,
                  description: '',
                });
              }}
            />
            )}
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="arabic_description"
              label={t('descriptionAR')}
              name="arabic_description"
              required
              value={values.arabic_description}
              onChange={handleChange}
              type="text"
              multiline
              variant="outlined"
              color={error.descriptionAR && 'secondary'}
            />
            {values.arabic_description && (
            <HighlightOffIcon
              className={styles.cancel}
              onClick={() => {
                setValues({
                  ...values,
                  arabic_description: '',
                });
              }}
            />
            )}
          </div>

        </div>
      </div>
      {error.frontend && <p className={styles.error}>{error.frontend}</p>}
      <div className={styles['button-container']}>
        <Button disabled={loader} type="submit" variant="contained" className={styles['next-button']}>
          {loader ? t('wait') : t('confirm')}
        </Button>
      </div>
    </form>
  );
}

export default StepOne;

StepOne.propTypes = {
  setStepOne: propTypes.func.isRequired,
  setStepThree: propTypes.func.isRequired,
  handleUpdateTable: propTypes.func.isRequired,
};
