import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import propTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import cookies from 'js-cookie';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import i18next from '../../i18next';

import styles from './Navbar.module.css';

function Navbar({ setSidebar, sidebar }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguageCode = cookies.get('i18next');
  const location = useLocation();
  const [title, setTitle] = useState('');
  const [language, setLanguage] = useState(currentLanguageCode);

  useEffect(() => {
    if (location.pathname === '/dashboard' || location.pathname === '/') {
      setTitle(t('dashboard'));
    } else if (location.pathname === '/users/admin') {
      setTitle(t('admin'));
    } else if (location.pathname === '/users/service-provider') {
      setTitle(t('serviceProvider'));
    } else if (location.pathname === '/users/caregivers') {
      setTitle(t('caregivers'));
    } else if (location.pathname === '/users/students') {
      setTitle(t('students'));
    } else if (location.pathname === '/system-setup/categories') {
      setTitle(t('categories'));
    } else if (location.pathname === '/system-setup/specialities') {
      setTitle(t('specialities'));
    } else if (location.pathname === '/system-setup/activities-bank') {
      setTitle(t('activitiesBank'));
    } else if (location.pathname === '/system-setup/other-activities') {
      setTitle(t('otherActivities'));
    } else if (location.pathname === '/system-setup/evaluation') {
      setTitle(t('evaluation'));
    } else if (location.pathname === '/system-setup/packages') {
      setTitle(t('packages'));
    } else if (location.pathname === '/system-setup/roles') {
      setTitle(t('roles'));
    } else if (location.pathname === '/notifications') {
      setTitle(t('notifications'));
    } else if (location.pathname === '/profile') {
      setTitle(t('profile'));
    }
  });
  const showSidebar = () => setSidebar(!sidebar);
  const handleChange = (e) => {
    const { value } = e.target;
    setLanguage(value);
    i18next.changeLanguage(value);
  };
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <MenuIcon onClick={showSidebar} />
        <h6 className={styles.title}>{title}</h6>
      </div>
      <div className={styles.right}>
        <NotificationsNoneIcon onClick={() => { navigate('/notifications'); }} />
        <div className={styles['languages-container']}>
          <LanguageIcon />
          <select
            id="languageSelector"
            name="language"
            value={language}
            onChange={handleChange}
            required
            className={styles.languages}
          >
            <option>en</option>
            <option>ar</option>
          </select>
        </div>
      </div>

    </div>
  );
}

export default Navbar;
Navbar.propTypes = {
  setSidebar: propTypes.func.isRequired,
  sidebar: propTypes.bool.isRequired,
};
