import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import styles from './ViewStudents.module.css';
import StepOne from './StepOne';

const modal = {
  display: 'flex',
  alignItems: 'right',
  justifyContent: 'right',
};
const paper = {
  position: 'relative',
  border: 'none',
  maxWidth: '350px',
  minWidth: '295px',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ViewStudentsModal({
  setViewStudentsModal,
  viewStudentsModal, studentsToViewed, students,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    setOpen(viewStudentsModal);
    setUserData(students.find((user) => studentsToViewed === user.id));
  }, [viewStudentsModal]);

  const handleClose = () => {
    setViewStudentsModal(false);
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        sx={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={paper}>
            <p id="newService-modal-description" className={styles.subtitle}>{`${t('student')} ${studentsToViewed}`}</p>
            <h2 id="newService-modal-title" className={styles.title}>{t('viewStudent')}</h2>
            <CloseIcon onClick={handleClose} className={styles.close} />
            <StepOne userData={userData} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
ViewStudentsModal.propTypes = {
  setViewStudentsModal: PropTypes.func.isRequired,
  viewStudentsModal: PropTypes.bool.isRequired,
  studentsToViewed: PropTypes.number.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    birthdate: PropTypes.string.isRequired,
    relationship: PropTypes.string.isRequired,
    caregiver: PropTypes.string.isRequired,
    service_provider: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
    is_active: PropTypes.number.isRequired,
  })).isRequired,
};
