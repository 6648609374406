import React from 'react';

function MapLocation() {
  return (
    <div>
      Map
    </div>
  );
}

export default MapLocation;
