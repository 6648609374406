import React from 'react';
import {
  BarChart, Bar, XAxis, Tooltip, YAxis, ResponsiveContainer,
} from 'recharts';

const data = [
  { name: 'Center1', foo: 10 },
  { name: 'Center2', foo: 12 },
  { name: 'Center3', foo: 15 },
  { name: 'Center4', foo: 20 },
];

function Caregivers() {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart width={300} height={240} data={data} layout="vertical">
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" />
        <Bar background label dataKey="foo" fill="#4285F4" />
        <Tooltip />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Caregivers;
