/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import CreateIcon from '@mui/icons-material/Create';
import {
  Box, Button, Select, Table,
  TableBody, TableHead, TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import { getServices } from '../../../Services/adminServices';

// Creating styles
const StyledTable = styled(Table)(({ theme }) => ({
  border: 'none',
  [theme.breakpoints.up(750)]: {
    minWidth: 600,
  },
  [theme.breakpoints.up(1000)]: {
    minWidth: 900,
  },
}));
const select = {
  height: 30,
};
const color = {
  color: 'black',
};
const StyledTableCell = styled(TableCell)(() => ({
  // border: 'none',
  [`&.${tableCellClasses.body}`]: {
    border: 'none',

  },
  [`&.${tableCellClasses.head}`]: {
    height: '17px',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
  },
}));

function TableDemo({
  values, setValues, serv, filter,
}) {
  // Creating style object
  const { t } = useTranslation();
  const currentLanguageCode = Cookies.get('i18next');
  // const classes = useStyles();
  const [services, setServices] = useState([]);
  const [type, setType] = useState([]);
  const [payment, setPayment] = useState([]);
  // Defining a state named rows
  // which we can update by calling on setRows function
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (serv.length > 0) {
      setRows(serv.map((obj) => ({
        service_provider_service_id: obj.service_provider_service_id,
        service_provider_service_type_id: obj.service_provider_service_type_id,
        service_provider_service_payment_id: obj.service_provider_service_payment_id,
      })));
    } else {
      setRows([]);
    }
    (async () => {
      const response = await getServices();
      if (response.status === 200) {
        setServices(response.data.services);
        setType(response.data.services_types);
        setPayment(response.data.services_payments);
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
  }, [filter]);

  // Initial states
  const [isEdit, setEdit] = React.useState(false);
  const [disable, setDisable] = React.useState(true);
  const [showConfirm, setShowConfirm] = React.useState(false);

  // Function For adding new row object
  const handleAdd = () => {
    setRows([
      ...rows,
      {
        service_provider_service_id: '',
        service_provider_service_type_id: '',
        service_provider_service_payment_id: '',
      },
    ]);
    setEdit(true);
  };

  // Function to handle edit
  const handleEdit = () => {
    // If edit mode is true setEdit will
    // set it to false and vice versa
    setEdit(!isEdit);
  };

  // Function to handle save
  const handleSave = () => {
    setEdit(!isEdit);
    setRows(rows);
    setValues({
      ...values,
      services: rows,
    });

    setDisable(true);
  };

  // The handleInputChange handler can be set up to handle
  // many different inputs in the form, listen for changes
  // to input elements and record their values in state
  const handleInputChange = (e, index) => {
    setDisable(false);
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    let duplicate = false;
    for (let i = 0; i < rows.length; i += 1) {
      if (rows[i].service_provider_service_id == list[index].service_provider_service_id
         && rows[i].service_provider_service_type_id == list[index].service_provider_service_type_id
          && rows[i].service_provider_service_payment_id
          == list[index].service_provider_service_payment_id
          && index !== i) {
        duplicate = true;
      }
    }
    if (duplicate === true) {
      list.pop();
    }
    setRows(list);
  };

  // Showing delete confirmation to users
  const handleConfirm = () => {
    setShowConfirm(true);
  };

  // Handle the case of delete confirmation where
  // user click yes delete a specific row of id:i
  const handleRemoveClick = (i) => {
    const list = [...rows];
    list.splice(i, 1);
    setRows(list);
    setValues({
      ...values,
      services: list,
    });
    setShowConfirm(false);
  };

  // Handle the case of delete confirmation
  // where user click no
  const handleNo = () => {
    setShowConfirm(false);
  };

  return (
    <TableBody>
      <Box margin={1}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 50 }}>
          {isEdit ? (
            <div>
              <Button disabled={!filter} sx={color} onClick={handleAdd}>
                <AddBoxIcon onClick={handleAdd} />
                {t('add')}
              </Button>
              {rows.length !== 0 && (
                disable ? (
                  <Button sx={color} disabled align="right" onClick={handleSave}>
                    <DoneIcon />
                    {t('save')}
                  </Button>
                ) : (
                  <Button sx={color} align="right" onClick={handleSave}>
                    <DoneIcon />
                    {t('save')}
                  </Button>
                )
              )}
            </div>
          ) : (
            <div>
              <Button disabled={!filter} sx={color} onClick={handleAdd}>
                <AddBoxIcon onClick={handleAdd} />
                {t('add')}
              </Button>
              <Button sx={color} align="right" onClick={handleEdit}>
                <CreateIcon />
                {t('edit')}
              </Button>
            </div>
          )}
        </div>
        <TableRow align="center"> </TableRow>

        <StyledTable
          size="medium"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>{t('Service')}</StyledTableCell>
              <StyledTableCell>{t('Type')}</StyledTableCell>
              <StyledTableCell>{t('Payment')}</StyledTableCell>
              <StyledTableCell align="center"> </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
            //   <div>
              <TableRow>
                {isEdit ? (
                  <>
                    <StyledTableCell padding="none">
                      <Select
                        sx={select}
                        labelId="country-label"
                        native
                        variant="outlined"
                        value={row.service_provider_service_id}
                        onChange={(e) => handleInputChange(e, i)}
                        inputProps={{
                          name: 'service_provider_service_id',
                          id: `service${i}`,
                        }}
                      >
                        <option aria-label="None" value="" />
                        {services.filter((service) => (service.service_provider_type_id == filter))
                          .map((service) => (
                            <option
                              key={service.id}
                              value={service.service_provider_service_id}
                            >
                              {currentLanguageCode === 'en' ? service.name : service.arabic_name}

                            </option>

                          ))}

                      </Select>
                    </StyledTableCell>
                    <StyledTableCell padding="none">
                      <Select
                        sx={select}
                        labelId="type-label"
                        native
                        variant="outlined"
                        value={row.service_provider_service_type_id}
                        onChange={(e) => handleInputChange(e, i)}
                        inputProps={{
                          name: 'service_provider_service_type_id',
                          id: `type${i}`,
                        }}
                      >
                        <option aria-label="None" value="" />
                        {type.map((pay) => (
                          <option
                            key={pay.id}
                            value={pay.id}
                          >
                            {currentLanguageCode === 'en' ? pay.name : pay.arabic_name}

                          </option>

                        ))}

                      </Select>
                    </StyledTableCell>
                    <StyledTableCell padding="none">
                      <Select
                        sx={select}
                        labelId="country-label"
                        native
                        variant="outlined"
                        value={row.service_provider_service_payment_id}
                        onChange={(e) => handleInputChange(e, i)}
                        inputProps={{
                          name: 'service_provider_service_payment_id',
                          id: `payment${i}`,
                        }}
                      >
                        <option aria-label="None" value="" />
                        {payment.map((pay) => (
                          <option
                            key={pay.id}
                            value={pay.id}
                          >
                            {currentLanguageCode === 'en' ? pay.name : pay.arabic_name}

                          </option>

                        ))}

                      </Select>

                    </StyledTableCell>
                  </>
                ) : (
                  <>
                    <TableCell component="th" scope="row">
                      {services.filter((ser) => row.service_provider_service_id
                      == ser.service_provider_service_id)
                        .map((s) => (s.name))}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {type.filter((ser) => row.service_provider_service_type_id == ser.id)
                        .map((s) => (currentLanguageCode === 'en' ? s.name : s.arabic_name))}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {payment.filter((ser) => row.service_provider_service_payment_id == ser.id)
                        .map((s) => (currentLanguageCode === 'en' ? s.name : s.arabic_name))}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                    />
                  </>
                )}
                {isEdit ? (
                  <Button sx={color} className="mr10" onClick={handleConfirm}>
                    <ClearIcon />
                  </Button>
                ) : (
                  <Button sx={color} className="mr10" onClick={handleConfirm}>
                    <DeleteOutlineIcon />
                  </Button>
                )}
                {showConfirm && (
                  <div>
                    <Dialog
                      open={showConfirm}
                      onClose={handleNo}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {t('Confirm Delete')}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {t('Are you sure to delete')}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => handleRemoveClick(i)}
                          color="primary"
                          autoFocus
                        >
                          {t('yes')}
                        </Button>
                        <Button
                          onClick={handleNo}
                          color="primary"
                          autoFocus
                        >
                          {t('no')}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                )}
              </TableRow>
            //   </div>
            ))}
          </TableBody>
        </StyledTable>
      </Box>
    </TableBody>
  );
}

export default TableDemo;
TableDemo.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    logoName: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    governorate: PropTypes.string,
    area: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    password_confirmation: PropTypes.string.isRequired,
  }).isRequired,
  filter: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  serv: PropTypes.arrayOf(PropTypes.shape({
    service_provider_service_id: PropTypes.number.isRequired,
    service_provider_service_type_id: PropTypes.number.isRequired,
    service_provider_service_payment_id: PropTypes.number.isRequired,
  })).isRequired,
};
