/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Cookies from "js-cookie";
import styles from "./Categories.module.css";
import SpecialityTable from "../../Speciality/SpecialityTable";
import SpecialityModal from "../../Speciality/SpecialityModal";
import EditSpecialityModal from "../../Speciality/EditSpeciality/EditSpeciality";

import { getSpecialities } from "../../../Services/adminServices";
import Excelexport from "../../ActivityBank/Excelexport";

function Specialities() {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [newSpecialityModal, setNewSpecialityModal] = useState(false);
  const [editSpecialityModal, setEditSpecialityModal] = useState(false);
  const [specialityToEdit, setSpecialityToEdit] = useState(-1);
  const [specialities, setSpecialities] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const currentLanguageCode = Cookies.get("i18next");
  useEffect(() => {
    (async () => {
      const response = await getSpecialities();
      if (response.status === 200) {
        setSpecialities(response.data.skills);
        setFiltered(response.data.skills);
      } else {
        localStorage.removeItem("token");
        window.location.reload(false);
      }
    })();
  }, [updateTable, currentLanguageCode]);
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setFiltered(
      specialities.filter(
        (user) =>
          user.english_name.toLowerCase().includes(value) ||
          user.arabic_name.toLowerCase().includes(value) ||
          user.id == value ||
          user.sort_order == value
      )
    );
    if (value <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(specialities);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // search
    setFiltered(
      specialities.filter(
        (user) =>
          user.name.toLowerCase().includes(searchValue) ||
          user.arabic_name.toLowerCase().includes(searchValue) ||
          user.id == searchValue ||
          user.sort_order == searchValue
      )
    );
    if (searchValue <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(specialities);
    }
  };
  return (
    <>
      {newSpecialityModal && (
        <SpecialityModal
          newSpecialityModal={newSpecialityModal}
          setNewSpecialityModal={setNewSpecialityModal}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      )}
      {editSpecialityModal && (
        <EditSpecialityModal
          setEditSpecialityModal={setEditSpecialityModal}
          editSpecialityModal={editSpecialityModal}
          specialityToEdit={specialityToEdit}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          specialities={specialities}
        />
      )}

      <div className={styles["sp-container"]}>
        <div className={styles.container}>
          <form onSubmit={handleSearch} className={styles.header}>
            <SearchIcon style={{ marginRight: "20px" }} />
            <input
              className={styles["search-bar"]}
              placeholder={t("searchForSomething")}
              onChange={handleChange}
            />
            <input type="submit" hidden />
          </form>
          <div className={styles.export}>
            <div style={{ width: "50px" }}>
              <Excelexport excelData={specialities} fileName="Skills" />
            </div>
            <Button
              onClick={() => {
                setNewSpecialityModal(true);
              }}
              className={styles["add-button"]}
              variant="contained"
            >
              <AddIcon />
              <span className={styles["button-text"]}>
                {t("createSpeciality")}
              </span>
            </Button>
          </div>
        </div>
        <SpecialityTable
          setEditSpecialityModal={setEditSpecialityModal}
          setSpecialityToEdit={setSpecialityToEdit}
          specialities={filtered}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      </div>
    </>
  );
}

export default Specialities;
