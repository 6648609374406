import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Unauthorized.module.css';

function Unauthorized() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img src="/logo/Vision Logo 3.png" width={200} height={200} alt="logo" />
      <h1>
        {t('unauthorized')}
      </h1>
    </div>
  );
}

export default Unauthorized;
