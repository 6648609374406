import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './404.module.css';

function NotFound() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img src="/logo/Vision Logo 3.png" width={200} height={200} alt="logo" />
      <h1>
        4
        <span>0</span>
        4
      </h1>
      <p>
        {t('404')}
      </p>
    </div>
  );
}

export default NotFound;
