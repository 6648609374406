import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, InputLabel, TextField, FormControl, Select,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
import styles from './StepOne.module.css';
import { editAdmin, GetRoles } from '../../../Services/adminServices';

function StepOne({
  setStepOne, setStepThree, handleUpdateTable, adminToEdit, userData,
}) {
  const { t } = useTranslation();
  const genders = [{ name: t('male'), id: 'male' }, { name: t('female'), id: 'female' }];

  const [loader, setLoader] = useState(false);
  const [roles, setRoles] = useState([]);
  const [values, setValues] = useState({
    id: adminToEdit,
    fname: userData.first_name,
    mname: userData.middle_name,
    lname: userData.last_name,
    email: userData.email,
    gender: userData.gender,
    mobile: userData.mobile,
    role: userData.role_id,
    password1: '',
    password2: '',
  });
  function validateStepOne(value) {
    const errors = {};
    if (!value.fname.trim()) {
      errors.frontend = 'name required';
    }
    if (!value.mname.trim()) {
      errors.frontend = 'name required';
    }
    if (!value.lname.trim()) {
      errors.frontend = 'name required';
    }

    if (!value.email) {
      errors.frontend = t('emailRequired');
      errors.email = t('emailRequired');
    } else if (!/\S+@\S+\.\S+/.test(value.email)) {
      errors.frontend = t('emailNotValid');
      errors.email = t('emailNotValid');
    }
    if (!values.gender) {
      errors.gender = 'gender required';
    }
    if (!value.role) {
      errors.frontend = 'Role Required';
    }
    if (!value.mobile) {
      errors.frontend = 'number required';
    }
    if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/.test(value.mobile)) {
      if (!/(\d{4})[- ]?(\d{3})[- ]?(\d{4})$/.test(value.mobile)) {
        errors.frontend = t('valideNumber');
      }
    }
    if (value.mobile.length < 11) {
      errors.frontend = t('valideNumber');
    }
    if (values.password1 || values.password2) {
      if (values.password1.length < 8) {
        errors.frontend = t('passShort');
      } else if (!/(?=.*?[A-Z])/.test(value.password1)) {
        errors.frontend = t('passwordRule');
      } else if (!/(?=.*?[a-z])/.test(value.password1)) {
        errors.frontend = t('passwordRule');
      } else if (!/(?=.*?[0-9])/.test(value.password1)) {
        errors.frontend = t('passwordRule');
      } else if (!/(?=.*?[#?!@$%^&*-/_])/.test(value.password1)) {
        errors.frontend = t('passwordRule');
      }
      if (value.password1 !== value.password2) {
        errors.frontend = t('missMatchPassword');
      }
    }

    return errors;
  }

  const [error, setError] = useState({});
  useEffect(() => {
    (async () => {
      const response = await GetRoles();
      if (response.status === 200) {
        setRoles(response.data.roles);
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there is no errors
      setLoader(true);
      editAdmin(values).then((response) => {
        if (response.status === 200) {
          handleUpdateTable();
          setStepOne(false);
          setStepThree(true);
        } else if (response.status === 422) {
          setError({
            ...error,
            frontend: response.data.message,
          });
        } else {
          setError({
            ...error,
            password: 'user not found',
          });
        }
        setLoader(false);
      });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles['form-container']}>
        <div className={styles.fields}>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="admin-fname"
              label={t('fName')}
              required
              name="fname"
              value={values.fname}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.fname && 'secondary'}
            />
            {values.fname
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              fname: '',
            });
          }}
        />
        )}
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="admin-mname"
              label={t('mName')}
              name="mname"
              required
              value={values.mname}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.mname && 'secondary'}
            />
            {values.mname
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              mname: '',
            });
          }}
        />
        )}
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="admin-lname"
              label={t('lName')}
              name="lname"
              value={values.lname}
              onChange={handleChange}
              type="text"
              required
              variant="outlined"
              color={error.lname && 'secondary'}
            />
            {values.lname
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              lname: '',
            });
          }}
        />
        )}
          </div>

          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="sp-email"
              label={t('email')}
              name="email"
              value={values.email}
              onChange={handleChange}
              required
              type="text"
              variant="outlined"
              color={error.email && 'secondary'}
            />
            {values.email
        && (
          <HighlightOffIcon
            className={styles.cancel}
            onClick={() => {
              setValues({
                ...values,
                email: '',
              });
            }}
          />
        )}
          </div>
          {/* {(error.email) && <p className={styles.error}>{error.email}</p>} */}
          <FormControl variant="outlined" className={styles['field-container']}>
            <InputLabel required style={{ top: 20, left: 25 }} id="gender-label" color={error.specialistName && 'secondary'}>{t('gender')}</InputLabel>
            <Select
              className={styles['text-field']}
              labelId="gender-label"
              native
              label={t('gender')}
              value={values.gender}
              required
              onChange={handleChange}
              color={error.gender && 'secondary'}
              inputProps={{
                name: 'gender',
                id: 'gender',
              }}
            >
              <option aria-label="None" value="" />
              {genders.map((gender) => (
                <option
                  key={gender.id}
                  value={gender.id}
                >
                  {gender.name}

                </option>

              ))}
            </Select>
          </FormControl>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="sp-mobile"
              label={t('mobile')}
              name="mobile"
              required
              value={values.mobile}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.mobile && 'secondary'}
            />
            {values.mobile
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              mobile: '',
            });
          }}
        />
        )}
          </div>

          <FormControl variant="outlined" className={styles['field-container']}>
            <InputLabel style={{ top: 20, left: 25 }} id="role-label" color={error.role && 'secondary'}>{t('role')}</InputLabel>
            <Select
              className={styles['text-field']}
              labelId="role-label"
              native
              label={t('role')}
              required
              value={values.role}
              onChange={handleChange}
              color={error.role && 'secondary'}
              inputProps={{
                name: 'role',
                id: 'role',
              }}
            >
              <option aria-label="None" value="" />
              {roles.map((role) => (
                <option key={role.id} value={role.id}>{role.name}</option>

              ))}
            </Select>
          </FormControl>

          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="sp-password1"
              label={t('password')}
              name="password1"
              value={values.password1}
              onChange={handleChange}
              type="password"
              variant="outlined"
              color={error.password1 && 'secondary'}
            />

          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="sp-password2"
              label={t('confirmPassword')}
              name="password2"
              value={values.password2}
              onChange={handleChange}
              type="password"
              variant="outlined"
              color={error.password2 && 'secondary'}
            />
          </div>
        </div>
      </div>
      {(error.frontend) && <p className={styles.error}>{error.frontend}</p>}
      <div className={styles['button-container']}>
        <Button disabled={loader} type="submit" variant="contained" className={styles['next-button']}>
          {loader ? t('wait') : t('confirm')}
        </Button>
      </div>
    </form>
  );
}

export default StepOne;

StepOne.propTypes = {
  setStepOne: PropTypes.func.isRequired,
  setStepThree: PropTypes.func.isRequired,
  handleUpdateTable: PropTypes.func.isRequired,
  adminToEdit: PropTypes.number.isRequired,
  userData: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
  })).isRequired,
};
