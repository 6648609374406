/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import styles from './StepOne.module.css';

function StepOne({ roles }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['form-container']}>
        <div className={styles.fields}>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="name"
              label={t('nameEN')}
              value={roles.name}
              type="text"
              variant="outlined"
              disabled
            />
          </div>

          {/* <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="nameAR"
              label={t('nameAR')}
              value={roles.arabic_name}
              type="text"
              variant="outlined"
              disabled
            />
          </div> */}
          <div className={styles['field-container']}>
            <div className={styles['text-field']}>
              <Table
            // className={classes.table}
                size="larg"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={styles['table-title']}>Permissions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles.permissions.map((per) => (
                    <TableRow>
                      <TableCell className={styles['table-body']} component="th" scope="row">
                        {per.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default StepOne;
StepOne.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    arabic_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      arabic_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })),
  })).isRequired,
};
