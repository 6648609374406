import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  TextField,
} from '@mui/material';
import styles from './StepOne.module.css';

function StepOne({ data }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['form-container']}>
        <div className={styles.fields}>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="textAR"
              label={t('textAR')}
              name="textAR"
              value={data.arabic_description}
              disabled
              type="text"
              variant="outlined"
            />
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="textEN"
              label={t('textEN')}
              name="textEN"
              value={data.description}
              disabled
              type="text"
              variant="outlined"
            />
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="user-label"
              label={t('userType')}
              name="userType"
              value={data.user_type}
              disabled
              type="text"
              variant="outlined"
            />
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="created-label"
              label={t('dateCreated')}
              name="created_at"
              value={data.created_at}
              disabled
              type="text"
              variant="outlined"
            />
          </div>

        </div>
      </div>

    </div>
  );
}

export default StepOne;
StepOne.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    arabic_description: PropTypes.string.isRequired,
    user_type: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    is_active: PropTypes.number.isRequired,
  })).isRequired,
};
