/* eslint-disable react/prop-types */
import React from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button } from '@mui/material';

const button = {
  width: 'fit-content',
  height: 'fit-content',
  marginRight: '5px',
};

function Excelexport({ excelData, fileName }) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtention = '.xlsx';
  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtention);
  };
  return (
    <Button
      sx={button}
      variant="text"
      onClick={() => exportToExcel(fileName)}
    >
      <FileDownloadIcon />
    </Button>
  );
}

export default Excelexport;
