import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TextField,
} from '@mui/material';
import styles from './StepOne.module.css';

function StepOne({ userData }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['form-container']}>
        <div className={styles.fields}>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="name"
              label={t('name')}
              name="name"
              value={userData.name}
              disabled
              type="text"
              variant="outlined"
            />

          </div>

          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="gender"
              label={t('gender')}
              name="gender"
              disabled
              value={userData.gender}
              type="text"
              variant="outlined"
            />

          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="birthDate"
              label={t('birthdate')}
              name="birthDate"
              value={userData.birthdate}
              type="text"
              multiline
              variant="outlined"
              disabled
            />

          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="age"
              label={t('age')}
              name="age"
              value={userData.age}
              type="text"
              multiline
              variant="outlined"
              disabled
            />
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="caregiver"
              label={t('caregiver')}
              name="caregiver"
              value={userData.caregiver}
              type="text"
              multiline
              variant="outlined"
              disabled
            />
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="relation"
              label={t('relation')}
              name="relation"
              value={userData.relationship}
              type="text"
              multiline
              variant="outlined"
              disabled
            />
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="spName"
              label={t('serviceProvider')}
              name="spName"
              value={userData.caregiver}
              type="text"
              multiline
              variant="outlined"
              disabled
            />
          </div>

        </div>
      </div>

    </div>
  );
}

export default StepOne;
StepOne.propTypes = {
  userData: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    birthdate: PropTypes.string.isRequired,
    relationship: PropTypes.string.isRequired,
    caregiver: PropTypes.string.isRequired,
    service_provider: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
    is_active: PropTypes.number.isRequired,
  })).isRequired,
};
