/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styles from './Notifications.module.css';
import NotificationsTable from '../Notifications/NotificationsTable';
import NotificationsModal from '../Notifications/NotificationsModal';
import ViewNotificationsModal from '../Notifications/ViewNotifications/ViewNotifications';

import { getNotifications } from '../../Services/adminServices';

function Notifications() {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [newNotificationsModal, setNewNotificationsModal] = useState(false);
  const [viewNotificationsModal, setViewNotificationsModal] = useState(false);
  const [notificationsToViewed, setNotificationsToViewed] = useState(-1);
  const [notifications, setNotifications] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  useEffect(() => {
    (async () => {
      const response = await getNotifications();
      if (response.status === 200) {
        setNotifications(response.data.notifications);
        setFiltered(response.data.notifications);
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
  }, [updateTable]);
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setFiltered(
      notifications.filter((not) => (not.id == value
      || not.description.toLowerCase().includes(value)
      || not.user_type.toLowerCase().includes(value)
      || not.created_at.toLowerCase().includes(value)
      || not.arabic_description.toLowerCase().includes(value))),
    );
    if (value <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(notifications);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // search
    setFiltered(
      notifications.filter((not) => (not.id == searchValue
      || not.description.toLowerCase().includes(searchValue)
      || not.user_type.toLowerCase().includes(searchValue)
      || not.created_at.toLowerCase().includes(searchValue)
      || not.arabic_description.toLowerCase().includes(searchValue))),
    );
    if (searchValue <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(notifications);
    }
  };
  return (
    <>
      {newNotificationsModal && (
      <NotificationsModal
        newNotificationsModal={newNotificationsModal}
        setNewNotificationsModal={setNewNotificationsModal}
        setUpdateTable={setUpdateTable}
        updateTable={updateTable}
      />
      )}
      {viewNotificationsModal && (
        <ViewNotificationsModal
          setViewNotificationsModal={setViewNotificationsModal}
          viewNotificationsModal={viewNotificationsModal}
          notificationsToViewed={notificationsToViewed}
          notifications={notifications}
        />
      )}

      <div className={styles['sp-container']}>
        <div className={styles.container}>
          <form onSubmit={handleSearch} className={styles.header}>
            <SearchIcon style={{ marginRight: '20px' }} />
            <input
              className={styles['search-bar']}
              placeholder={t('searchForSomething')}
              onChange={handleChange}
            />
            <input type="submit" hidden />
          </form>
          <Button onClick={() => { setNewNotificationsModal(true); }} className={styles['add-button']} variant="contained">
            <AddIcon />
            <span className={styles['button-text']}>{t('createNotification')}</span>
          </Button>
        </div>
        <NotificationsTable
          setNotificationsToViewed={setNotificationsToViewed}
          setViewNotificationsModal={setViewNotificationsModal}
          notifications={filtered}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      </div>
    </>
  );
}

export default Notifications;
