import React from "react";
import PropTypes from "prop-types";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Card = styled.button`
  width: 378px;
  height: 146px;
  border: 1px solid #001415;
  border-radius: 4px;
  position: relative;
  margin-bottom: 20px;
  background-color: ${({ currentId, choosePack }) =>
    currentId === choosePack ? "rgba(255, 168, 0, 0.08)" : "white"};
`;
const Check = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: ${({ currentId, choosePack }) =>
    currentId === choosePack ? "#FFA800" : "#001415"};
`;
const TitlePackage = styled.h6`
  position: absolute;
  top: -20px;
  margin-left: 28px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  /* Secondary */

  color: ${({ currentId, choosePack }) =>
    currentId === choosePack ? "#FFA800" : "#001415"};
`;
const StudentsTitle = styled.p`
position: absolute;
  width: 60px;
  height: 12px;
  left: 229px;
  top: 25px;

  /* Overline */

  
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #000

  opacity: 0.7;
`;
const Features = styled.ul`
  position: absolute;
  width: 138px;
  height: 66px;
  left: 11px;
  top: 34px;
`;
const Admission = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration: ${({ admisionPortal }) =>
    admisionPortal ? "none" : "line-through"};
  /* or 157% */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #000;
  opacity: 0.7;
`;
const Files = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration: ${({ studentsFiles }) =>
    studentsFiles ? "none" : "line-through"};
  /* or 157% */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #000;
  opacity: 0.7;
`;
const Followup = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration: ${({ followUp }) => (followUp ? "none" : "line-through")};
  /* or 157% */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #000;
  opacity: 0.7;
`;
const Students = styled.h5`
  position: absolute;
  width: 89px;
  height: 24px;
  left: 229px;
  top: 15px;

  /* Body 1 */

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  /* Primary */
  color: ${({ currentId, choosePack }) =>
    currentId === choosePack ? "#FFA800" : "#001415"};
`;
const Price = styled.p`
  position: absolute;
  width: 32px;
  height: 12px;
  left: 229px;
  top: 70px;

  /* Overline */

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #000;
  opacity: 0.7;
`;
const Amount = styled.h5`
  position: absolute;
  height: 24px;
  left: 229px;
  top: 63px;

  /* Body 1 */

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* Secondary */

  color: ${({ currentId, choosePack }) =>
    currentId === choosePack ? "#FFA800" : "#001415"};
`;

function PackageCard({ pack, setChoosePack, choosePack }) {
  const { t } = useTranslation();
  // const { admisionPortal, studentsFiles, followUp } = pack;
  const handleChoose = (id) => {
    setChoosePack(id);
  };
  return (
    <Card
      currentId={pack.id}
      choosePack={choosePack}
      type="button"
      onClick={() => {
        handleChoose(pack.id);
      }}
    >
      <Check currentId={pack.id} choosePack={choosePack}>
        <CheckCircleOutlineIcon />
      </Check>
      <TitlePackage currentId={pack.id} choosePack={choosePack}>
        {pack.name.length > 17
          ? `${pack.name.substring(0, 17 - 3)}...`
          : pack.name}
      </TitlePackage>
      <StudentsTitle>{t("students")}</StudentsTitle>
      <Features>
        <li>
          <Admission admisionPortal={pack.waiting_list}>
            {t("admisionPortal")}
          </Admission>
        </li>
        <li>
          <Files studentsFiles={pack.student_files}>{t("studentsFiles")}</Files>
        </li>
        <li>
          <Followup followUp={pack.working_caregiver}>
            {t("familiyFollowup")}
          </Followup>
        </li>
      </Features>
      <Students currentId={pack.id} choosePack={choosePack}>
        {pack.students_amount}
      </Students>
      <Price>{t("price")}</Price>
      <Amount currentId={pack.id} choosePack={choosePack}>
        {pack.price}
      </Amount>
    </Card>
  );
}

export default PackageCard;
PackageCard.propTypes = {
  pack: PropTypes.shape({
    id: PropTypes.number.isRequired,
    waiting_list: PropTypes.number.isRequired,
    student_files: PropTypes.number.isRequired,
    working_caregiver: PropTypes.number.isRequired,
    students_amount: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setChoosePack: PropTypes.func.isRequired,
  choosePack: PropTypes.bool.isRequired,
};
