/* eslint-disable no-nested-ternary */
import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import useAuth from './useAuth';

function RequireAuth({ allowedRoles }) {
  const { auth, permissions } = useAuth();
  const token = localStorage.token ? localStorage.token : null;
  const location = useLocation();
  return (
    (auth.token) ? permissions.filter((x) => allowedRoles.includes(x)).length > 0 ? <Outlet />
      : <Navigate to="/unauthorized" state={{ from: location }} replace />
      : token ? jwtDecode(token).permissions.filter((x) => allowedRoles.includes(x)).length > 0
        ? <Outlet />
        : <Navigate to="/unauthorized" state={{ from: location }} replace />
        : (token === null) ? <Navigate to="/login" state={{ from: location }} replace />
          : <Navigate to="/login" state={{ from: location }} replace />

  );
}

export default RequireAuth;
RequireAuth.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
