/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import i18next from '../../i18next';
import styles from './ResetPassword.module.css';
import usePasswordForm from './useResetPassword';

const color = {
  color: 'black',
};

function ResetPassword() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  const email = new URLSearchParams(search).get('email');
  const [loader, setLoader] = useState(false);
  function validateResetPassword(values) {
    const errors1 = {};
    const errors2 = {};
    if (!values.password1) {
      errors1.password1 = 'Password is required';
    } else if (values.password1.length < 8) {
      errors1.password1 = 'Password needs to be 8 characters or more';
    } else if (!/(?=.*?[A-Z])/.test(values.password1)) {
      errors1.password1 = 'Password should contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character';
    } else if (!/(?=.*?[a-z])/.test(values.password1)) {
      errors1.password1 = 'Password should contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character';
    } else if (!/(?=.*?[0-9])/.test(values.password1)) {
      errors1.password1 = 'Password should contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character';
    } else if (!/(?=.*?[#?!@$%^&*-/_])/.test(values.password1)) {
      errors1.password1 = 'Password should contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character';
    }
    if (values.password1 !== values.password2) {
      errors2.password2 = -1;
    }
    return { errors1, errors2 };
  }
  const {
    handleChange, values, handleSubmit, errors1, errors2,
  } = usePasswordForm(validateResetPassword, token, email, setLoader);
  const [visibility1, setVisibility1] = useState({
    showPassword: false,
  });
  const [visibility2, setVisibility2] = useState({
    showPassword: false,
  });
  const handleClickShowPassword1 = () => {
    setVisibility1({ ...visibility1, showPassword: !visibility1.showPassword });
  };
  const handleClickShowPassword2 = () => {
    setVisibility2({ ...visibility2, showPassword: !visibility2.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div className={styles['forgot-password-container']}>
        <img src="/logo/Logo-Outer.svg" width={123} height={162} alt="login logo" />
        <p className={styles.title}>{t('forgotPasswordTitle')}</p>
        <h5 className={styles.title2}>{t('recoverPassword')}</h5>
        <p className={styles.body1}>
          {t('forgotPasswordP')}
        </p>
        <form onSubmit={handleSubmit} className={styles['form-conatiner']}>
          <div className={styles.fields}>
            <FormControl className={styles.fields} variant="outlined">
              <InputLabel color={errors1.password1 && 'secondary'} htmlFor="reset-password1">{t('newPassword')}</InputLabel>
              <OutlinedInput
                id="reset-password1"
                type={visibility1.showPassword ? 'text' : 'password'}
                value={values.password1}
                label={t('newPassword')}
                name="password1"
                onChange={handleChange}
                color={errors1.password1 && 'secondary'}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      { visibility1.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
            )}
                labelWidth={70}
              />
            </FormControl>
          </div>
          <div className={styles.fields}>
            <FormControl className={styles.fields} variant="outlined">
              <InputLabel color={errors2.password2 && 'secondary'} htmlFor="reset-password2">{t('confirmPassword')}</InputLabel>
              <OutlinedInput
                id="reset-password2"
                type={visibility2.showPassword ? 'text' : 'password'}
                value={values.password2}
                name="password2"
                label={t('confirmPassword')}
                onChange={handleChange}
                color={errors2.password2 && 'secondary'}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      { visibility2.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
            )}
                labelWidth={70}
              />
            </FormControl>
          </div>
          <p className={styles['password-guide']}>{t('passwordGuide')}</p>
          {(errors2.password2 === -1) && <p className={styles.error}>{t('missMatchPassword')}</p>}
          <div className={styles['confirm-button-container']}>
            <Button disabled={loader} type="submit" variant="contained" className={styles['confirm-button']}>
              {loader ? t('wait') : t('confirm')}
            </Button>
          </div>
          <div className={styles['return-container']}>
            <img src="/icons/arrow1.svg" alt="back arrow" />
            <a href="/login" className={styles.return}>{t('returnToLogin')}</a>
          </div>
        </form>
      </div>
      <div className="languages-container">
        <LanguageIcon className="language-icon" />
        <ButtonGroup sx={color} variant="text" aria-label="text primary button group">
          <Button sx={color} onClick={() => i18next.changeLanguage('en')} className="languages-buttons">English</Button>
          <Button sx={color} onClick={() => i18next.changeLanguage('ar')} className="languages-buttons">Arabic</Button>
        </ButtonGroup>
      </div>
    </>
  );
}

export default ResetPassword;
