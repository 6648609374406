import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";
import styles from "./ActivitesBank.module.css";
import ViewActivitiesBankModal from "../../ActivityBank/ViewActivitiesBank/ViewActivitiesBank";
import OtherActivitiesTable from "../../OtherActivities/OtherActivitiesTable";

import { getOtherActivities } from "../../../Services/adminServices";
import Excelexport from "../../ActivityBank/Excelexport";

function OtherActivities() {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [viewActivitiesBankModal, setViewActivitiesBankModal] = useState(false);
  const [activitiesBankToViewed, setActivitiesBankToViewed] = useState(-1);
  const [activities, setActivities] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const currentLanguageCode = Cookies.get("i18next");

  useEffect(() => {
    (async () => {
      const response = await getOtherActivities();
      if (response.status === 200) {
        setActivities(response.data.activities);
        setFiltered(response.data.activities);
      } else {
        localStorage.removeItem("token");
        window.location.reload(false);
      }
    })();
  }, [updateTable, currentLanguageCode]);
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setFiltered(
      activities.filter(
        (act) =>
          act.english_name.toLowerCase().includes(value) ||
          act.arabic_name.toLowerCase().includes(value) ||
          act.id === value ||
          act.description.toLowerCase().includes(value) ||
          act.arabic_description.toLowerCase().includes(value) ||
          act.category.toLowerCase().includes(value) ||
          act.skill.toLowerCase().includes(value)
      )
    );
    if (value <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(activities);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // search
    setFiltered(
      activities.filter(
        (act) =>
          act.name.toLowerCase().includes(searchValue) ||
          act.arabic_name.toLowerCase().includes(searchValue) ||
          act.id === searchValue ||
          act.description.toLowerCase().includes(searchValue) ||
          act.arabic_description.toLowerCase().includes(searchValue) ||
          act.category.toLowerCase().includes(searchValue) ||
          act.skill.toLowerCase().includes(searchValue)
      )
    );
    if (searchValue <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(activities);
    }
  };
  return (
    <>
      {viewActivitiesBankModal && (
        <ViewActivitiesBankModal
          setViewActivitiesBankModal={setViewActivitiesBankModal}
          viewActivitiesBankModal={viewActivitiesBankModal}
          activitiesBankToViewed={activitiesBankToViewed}
          activities={activities}
        />
      )}

      <div className={styles["sp-container"]}>
        <div className={styles.container}>
          <form onSubmit={handleSearch} className={styles.header}>
            <SearchIcon style={{ marginRight: "20px" }} />
            <input
              className={styles["search-bar"]}
              placeholder={t("searchForSomething")}
              onChange={handleChange}
            />
            <input type="submit" hidden />
          </form>
          <Excelexport excelData={activities} fileName="Other Activities" />
        </div>
        <OtherActivitiesTable
          setActivitiesBankToViewed={setActivitiesBankToViewed}
          setViewActivitiesBankModal={setViewActivitiesBankModal}
          activities={filtered}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      </div>
    </>
  );
}

export default OtherActivities;
