import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CloseIcon from "@mui/icons-material/Close";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { Button, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import Spinner from "../../Loader";
import {
  ActivateServiceProvider,
  VerifyUser,
  deleteSP,
} from "../../Services/adminServices";
import ConfirmationDialog from "../../Models/ConfirmationDialog";

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  [`&.${tableCellClasses.head}`]: {
    color: "#4F4F4F",
    backgroundColor: "#ffeecc",
    height: "17px",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.08em",
    textTransform: "uppercase",
  },
}));

const root = {
  width: "97%",
};

const icons = {
  display: "flex",
  justifyContent: "space-between",
  width: "200px",
};

export default function ServiceProvidersTable({
  setEditServiceModal,
  setSpToEdit,
  setSpToView,
  setViewServiceModal,
  users,
  setUpdateTable,
  updateTable,
}) {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const columns = [
    {
      id: "id",
      label: t("id"),
      minWidth: 16,
    },
    {
      id: "service_provider_type",
      label: t("SPType"),
      minWidth: 81,
    },
    {
      id: "name",
      label: t("SPName"),
      minWidth: 81,
    },
    {
      id: "phone",
      label: t("mobile"),
      minWidth: 60,
    },
    {
      id: "email",
      label: t("email"),
      minWidth: 60,
    },
    {
      id: "governorate",
      label: t("governorate"),
      minWidth: 120,
    },
    {
      id: "verify_at",
      label: t("verifyAt"),
    },
  ];

  useEffect(() => {
    setPage(0);
  }, [users]);

  const handleDelete = (id) => {
    // Show the confirmation dialog
    setShowConfirmation(true);

    // Set the ID of the item to be deleted
    setDeleteItemId(id);
  };

  const handleConfirmDelete = () => {
    setLoader(true);

    // Perform the deletion using deleteSP
    deleteSP(deleteItemId).then((response) => {
      if (response.status === 200) {
        setUpdateTable(!updateTable);
      }
      setLoader(false);
    });

    // Hide the confirmation dialog after deletion
    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    // Hide the confirmation dialog without performing the deletion
    setShowConfirmation(false);
    setDeleteItemId(null); // Reset the item ID
  };
  const handleEdit = (id) => {
    setSpToEdit(id);
    setEditServiceModal(true);
  };
  const handleView = (id) => {
    setSpToView(id);
    setViewServiceModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleLoad = () => {};
  const handleApprove = (id, activation) => {
    setLoader(true);
    ActivateServiceProvider(id, !activation).then((response) => {
      if (response.status === 200) {
        setUpdateTable(!updateTable);
      }
      setLoader(false);
    });
  };

  const handleVerify = (id) => {
    setLoader(true);
    VerifyUser(id).then((response) => {
      if (response.status === 200) {
        setUpdateTable(!updateTable);
      }
      setLoader(false);
    });
  };

  return (
    <Paper sx={root}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: column.backgroundColor,
                    border: column.border,
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">{t("operation")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number" ? (
                          column.format(value)
                        ) : column.id === "verify_at" ? (
                          row?.user?.verify_at ? (
                            row?.user?.verify_at
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleVerify(row.user?.user_id);
                              }}
                            >
                              {t("verify")}
                            </Button>
                          )
                        ) : (
                          value
                        )}
                      </StyledTableCell>
                    );
                  })}
                  {columns
                    .filter((column) => column.id === "id")
                    .map((column) => (
                      <StyledTableCell key={column.id}>
                        {loader ? (
                          <Spinner />
                        ) : (
                          <div style={icons}>
                            {row.is_active ? (
                              <CheckIcon
                                style={{ color: "green" }}
                                onClick={
                                  loader
                                    ? () => {
                                        handleLoad();
                                      }
                                    : () => {
                                        handleApprove(row.id, row.is_active);
                                      }
                                }
                              />
                            ) : (
                              <CloseIcon
                                style={{ color: "red" }}
                                onClick={
                                  loader
                                    ? () => {
                                        handleLoad();
                                      }
                                    : () => {
                                        handleApprove(row.id, row.is_active);
                                      }
                                }
                              />
                            )}
                            <VisibilityIcon
                              onClick={() => {
                                handleView(row.id);
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                handleEdit(row.id);
                              }}
                            />
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                handleDelete(row.id);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        )}
                      </StyledTableCell>
                    ))}
                </TableRow>
              ))}
            {showConfirmation && (
              <ConfirmationDialog
                confirmCallback={handleConfirmDelete}
                cancelCallback={handleCancelDelete}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
