import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import styles from "./StepOne.module.css";
import { createPackage, getCountries } from "../../Services/adminServices";

function StepOne({ setStepOne, setStepThree, handleUpdateTable }) {
  const { t } = useTranslation();
  function validateStepOne(values) {
    const errors = {};
    if (!values.arabic_name.trim()) {
      errors.nameAR = "name required";
      errors.frontend = "name required";
      // } else if (/^[A-Za-z]+/.test(values.arabic_name.trim())) {
      //   errors.nameAR = t('validName');
      //   errors.frontend = t('validName');
    }
    if (!values.name.trim()) {
      errors.nameEN = "name required";
      errors.frontend = "name required";
    } else if (!/^[A-Za-z]+/.test(values.name.trim())) {
      errors.nameEN = t("validName");
      errors.frontend = t("validName");
    }

    if (!values.arabic_description.trim()) {
      errors.textAR = "required";
      errors.frontend = "required";
      // } else if (/^[A-Za-z]+/.test(values.arabic_description.trim())) {
      //   errors.textAR = t('validText');
      //   errors.frontend = t('validText');
    }
    if (!values.description.trim()) {
      errors.textEN = "required";
      errors.frontend = "required";
    } else if (!/^[A-Za-z]+/.test(values.description.trim())) {
      errors.nameEN = t("validText");
      errors.frontend = t("validText");
    }
    if (!values.students_amount) {
      errors.students_amount = "number required";
      errors.frontend = "number required";
    } else if (values.students_amount < 0) {
      errors.students_amount = t("validNumber");
      errors.frontend = t("validNumber");
    }
    if (!values.duration) {
      errors.duration = "duration required";
      errors.frontend = "duration required";
    } else if (values.duration < 0) {
      errors.duration = "Enter a valid Duration";
      errors.frontend = "Enter a valid Duration";
    }
    if (!values.price) {
      errors.price = "price required";
      errors.frontend = "price required";
    } else if (values.price < 0) {
      errors.price = "Enter a valid Price";
      errors.frontend = "Enter a valid Price";
    }
    if (!values.country_id) {
      errors.country_id = "country required";
      errors.frontend = "country required";
    }

    return errors;
  }
  const currentLanguageCode = Cookies.get("i18next");
  const [values, setValues] = useState({
    name: "",
    arabic_name: "",
    description: "",
    arabic_description: "",
    students_amount: "",
    duration: "",
    price: "",
    country_id: "",
    waiting_list: false,
    student_files: false,
    working_caregiver: false,
    sort_order: 1,
  });
  const [countries, setCountries] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({});
  useEffect(() => {
    (async () => {
      const response = await getCountries();
      if (response.status === 200) {
        setCountries(response.data);
      } else {
        localStorage.removeItem("token");
        window.location.reload(false);
      }
    })();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleChangeAdmissionPortal = (e) => {
    setValues({
      ...values,
      waiting_list: e.target.checked,
    });
  };
  const handleChangeStudentFiles = (e) => {
    setValues({
      ...values,
      student_files: e.target.checked,
    });
  };
  const handleChangeFamilyFollowup = (e) => {
    setValues({
      ...values,
      working_caregiver: e.target.checked,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there is no errors
      setLoader(true);
      createPackage(values).then((response) => {
        if (response.status === 200) {
          handleUpdateTable();
          setStepOne(false);
          setStepThree(true);
        } else if (response.status === 422) {
          setError({
            ...error,
            frontend: response.data.message,
          });
        }
        setLoader(false);
      });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles["form-container"]}>
        <div className={styles["field-container"]}>
          <TextField
            id="name"
            label={t("nameEN")}
            name="name"
            required
            value={values.name}
            onChange={handleChange}
            type="text"
            variant="outlined"
            color={error.nameEN && "secondary"}
          />
        </div>

        <div className={styles["field-container"]}>
          <TextField
            id="arabic_name"
            label={t("nameAR")}
            name="arabic_name"
            required
            value={values.arabic_name}
            onChange={handleChange}
            type="text"
            variant="outlined"
            color={error.nameAR && "secondary"}
          />
        </div>
        <div className={styles["field-container"]}>
          <TextField
            id="noofStudents"
            label={t("numOfStudents")}
            required
            name="students_amount"
            value={values.students_amount}
            onChange={handleChange}
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            variant="outlined"
            color={error.students_amount && "secondary"}
          />
        </div>

        <div className={styles["field-container"]}>
          <TextField
            id="duration"
            label={t("durationInDays")}
            name="duration"
            required
            value={values.duration}
            onChange={handleChange}
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            variant="outlined"
            color={error.duration && "secondary"}
          />
        </div>
        <div className={styles["field-container"]}>
          <TextField
            id="price"
            label={t("price")}
            name="price"
            value={values.price}
            required
            onChange={handleChange}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            variant="outlined"
            color={error.price && "secondary"}
          />
        </div>
        <FormControl variant="outlined" className={styles["field-container"]}>
          <InputLabel
            id="country-label"
            color={error.specialistName && "secondary"}
          >
            {t("country")}
          </InputLabel>
          <Select
            labelId="country-label"
            native
            label={t("country")}
            value={values.country_id}
            required
            onChange={handleChange}
            color={error.country_id && "secondary"}
            inputProps={{
              name: "country_id",
              id: "country_id",
            }}
          >
            <option aria-label="None" value="" />
            {countries.map((country) => (
              <option key={country.id} value={country.id}>
                {currentLanguageCode === "en"
                  ? country.name
                  : country.arabic_name}
              </option>
            ))}
            <option value="999">{t("other")}</option>
          </Select>
        </FormControl>
        <div className={styles["field-container"]}>
          <TextField
            id="description"
            label={t("descriptionEN")}
            name="description"
            required
            value={values.description}
            onChange={handleChange}
            type="text"
            variant="outlined"
            color={error.textEN && "secondary"}
            multiline
            fullWidth
          />
        </div>
        <div className={styles["field-container"]}>
          <TextField
            id="arabic_description"
            label={t("descriptionAR")}
            name="arabic_description"
            required
            value={values.arabic_description}
            onChange={handleChange}
            type="text"
            variant="outlined"
            color={error.textAR && "secondary"}
            multiline
            fullWidth
            sx={{ direction: "rtl" }}
          />
        </div>

        <div className={styles["field-container"]}>
          <TextField
            id="sort_order"
            label={t("sort_order")}
            name="sort_order"
            required
            value={values.sort_order}
            onChange={handleChange}
            type="number"
            min="1"
            variant="outlined"
            color={error.sort_order && "secondary"}
            fullWidth
          />
        </div>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.waiting_list}
                color="primary"
                name="waiting_list"
              />
            }
            onChange={handleChangeAdmissionPortal}
            label={t("admisionPortal")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.student_files}
                color="primary"
                name="student_files"
              />
            }
            onChange={handleChangeStudentFiles}
            label={t("studentsFiles")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.worging_caregiver}
                color="primary"
                name="working_caregiver"
              />
            }
            onChange={handleChangeFamilyFollowup}
            label={t("familiyFollowup")}
          />
        </FormGroup>
      </div>
      {error.frontend && <p className={styles.error}>{error.frontend}</p>}
      <div className={styles["button-container"]}>
        <Button
          disabled={loader}
          type="submit"
          variant="contained"
          className={styles["next-button"]}
        >
          {loader ? t("wait") : t("confirm")}
        </Button>
      </div>
    </form>
  );
}

export default StepOne;
