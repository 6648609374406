/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";
import styles from "./Students.module.css";
import CaregiversTable from "../Caregivers/CaregiversTable";
import ViewCaregiverModal from "../Caregivers/ViewCaregiver/ViewCaregiver";
import { GetCaregivers } from "../../../Services/adminServices";
import Excelexport from "../../ActivityBank/Excelexport";
import EditAdminModal from "../Caregivers/EditCaregiver";

function Caregivers() {
  const { t } = useTranslation();
  const [viewCaregiversModal, setViewCaregiversModal] = useState(false);
  const [editCaregiversModal, setEditCaregiversModal] = useState(false);
  const [caregiversToViewed, setCaregiversToViewed] = useState(-1);
  const [caregivers, setCaregivers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const currentLanguageCode = Cookies.get("i18next");

  useEffect(() => {
    (async () => {
      const response = await GetCaregivers();
      if (response.status === 200) {
        setCaregivers(
          response.data.users.map((obj) => ({
            ...obj,
            name: `${obj.first_name} ${obj.middle_name} ${obj.last_name}`,
          }))
        );
        setFilteredUsers(
          response.data.users.map((obj) => ({
            ...obj,
            name: `${obj.first_name} ${obj.middle_name} ${obj.last_name}`,
          }))
        );
      } else {
        localStorage.removeItem("token");
        window.location.reload(false);
      }
    })();
  }, [updateTable, currentLanguageCode]);
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setFilteredUsers(
      caregivers.filter(
        (user) =>
          user.name?.toLowerCase().includes(value.toLowerCase()) ||
          user.email?.toLowerCase().includes(value.toLowerCase()) ||
          user.user_id == value ||
          user.mobile?.toLowerCase().includes(value.toLowerCase()) ||
          user.gender?.toLowerCase().includes(value.toLowerCase())
      )
    );
    if (value <= 0) {
      // setUpdateTable(!updateTable);
      setFilteredUsers(caregivers);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // search
    setFilteredUsers(
      caregivers.filter(
        (user) =>
          user.name.toLowerCase().includes(searchValue) ||
          user.email.toLowerCase().includes(searchValue) ||
          user.user_id == searchValue ||
          user.mobile.toLowerCase().includes(searchValue) ||
          user.gender.toLowerCase().includes(searchValue)
      )
    );
    if (searchValue.length === 0) {
      // setUpdateTable(!updateTable);
      setFilteredUsers(caregivers);
    }
  };
  return (
    <>
      {viewCaregiversModal && (
        <ViewCaregiverModal
          setViewCaregiversModal={setViewCaregiversModal}
          viewCaregiversModal={viewCaregiversModal}
          caregiversToViewed={caregiversToViewed}
          caregivers={caregivers}
        />
      )}
      {editCaregiversModal && (
        <EditAdminModal
          setEditAdminModal={setEditCaregiversModal}
          editAdminModal={editCaregiversModal}
          adminToEdit={caregiversToViewed}
          adminUsers={caregivers}
          updateTable={updateTable}
          setUpdateTable={setUpdateTable}
        />
      )}
      <div className={styles.container}>
        <div className={styles.container2}>
          <form onSubmit={handleSearch} className={styles.header}>
            <SearchIcon style={{ marginRight: "20px" }} />
            <input
              className={styles["search-bar"]}
              placeholder={t("searchForSomething")}
              onChange={handleChange}
            />
            <input type="submit" hidden />
          </form>
          <Excelexport excelData={caregivers} fileName="Caregivers" />
        </div>
        <CaregiversTable
          setCaregiversToViewed={setCaregiversToViewed}
          setViewCaregiversModal={setViewCaregiversModal}
          setEditCaregiversModal={setEditCaregiversModal}
          caregivers={filteredUsers}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      </div>
    </>
  );
}

export default Caregivers;
