import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TextField,
} from '@mui/material';
import styles from './StepOne.module.css';

function StepOne({ activities }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['form-container']}>
        <div className={styles.fields}>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="nameEN"
              label={t('nameEN')}
              name="nameEN"
              value={activities.english_name}
              disabled
              type="text"
              variant="outlined"
            />

          </div>

          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="nameAR"
              label={t('nameAR')}
              name="nameAR"
              disabled
              value={activities.arabic_name}
              type="text"
              variant="outlined"
            />

          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="category"
              label={t('category')}
              name="category"
              value={activities.category}
              type="text"
              multiline
              variant="outlined"
              disabled
            />

          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="speciality"
              label={t('speciality')}
              name="speciality"
              value={activities.skill}
              type="text"
              multiline
              variant="outlined"
              disabled
            />

          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="descriptionEN"
              label={t('descriptionEN')}
              name="descriptionEN"
              value={activities.english_description}
              type="text"
              multiline
              variant="outlined"
              disabled
            />

          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="descriptionAR"
              label={t('descriptionAR')}
              name="descriptionAR"
              value={activities.arabic_description}
              type="text"
              multiline
              variant="outlined"
              disabled
            />
          </div>

        </div>
      </div>

    </div>
  );
}

export default StepOne;
StepOne.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    english_name: PropTypes.string.isRequired,
    arabic_name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    english_description: PropTypes.string.isRequired,
    arabic_description: PropTypes.string.isRequired,
    skill: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    is_active: PropTypes.number.isRequired,
  })).isRequired,
};
