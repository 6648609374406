/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./Categories.module.css";
import CouponsTable from "../../Coupons/CouponsTable";

import { getCoupons } from "../../../Services/adminServices";
import Excelexport from "../../ActivityBank/Excelexport";
import CouponsModal from "../../Coupons/CouponsModal";

function Coupons() {
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [newCouponsModal, setNewCouponsModal] = useState(false);
  const [couponToEdit, setCouponToEdit] = useState(-1);

  useEffect(() => {
    getCoupons()
      .then((res) => {
        setCoupons(res);
        setFiltered(res);
      })
      .catch((err) => {});
  }, [updateTable, i18n.language]);

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setFiltered(
      coupons.filter(
        (act) =>
          act.id == value ||
          act.description.toLowerCase().includes(value) ||
          act.name.toLowerCase().includes(value) ||
          act.arabic_name.toLowerCase().includes(value) ||
          act.country.toLowerCase().includes(value) ||
          act.currency.toLowerCase().includes(value) ||
          act.price == value ||
          act.duration == value ||
          act.students_amount.toLowerCase().includes(value) ||
          act.arabic_description.toLowerCase().includes(value),
      ),
    );
    if (value <= 0) {
      setFiltered(coupons);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // search
    setFiltered(
      coupons.filter(
        (act) =>
          act.id == searchValue ||
          act.description.toLowerCase().includes(searchValue) ||
          act.name.toLowerCase().includes(searchValue) ||
          act.arabic_name.toLowerCase().includes(searchValue) ||
          act.country.toLowerCase().includes(searchValue) ||
          act.currency.toLowerCase().includes(searchValue) ||
          act.price == searchValue ||
          act.duration == searchValue ||
          act.students_amount.toLowerCase().includes(searchValue) ||
          act.arabic_description.toLowerCase().includes(searchValue),
      ),
    );
    if (searchValue <= 0) {
      setFiltered(coupons);
    }
  };
  return (
    <div className={styles["sp-container"]}>
      <div className={styles.container}>
        <form onSubmit={handleSearch} className={styles.header}>
          <SearchIcon style={{ marginRight: "20px" }} />
          <input
            className={styles["search-bar"]}
            placeholder={t("searchForSomething")}
            onChange={handleChange}
          />
          <input type="submit" hidden />
        </form>
        <div className={styles.export}>
          <div style={{ width: "50px" }}>
            <Excelexport excelData={coupons} fileName="Coupons" />
          </div>
          <Button
            onClick={() => {
              setNewCouponsModal(true);
            }}
            className={styles["add-button"]}
            variant="contained"
          >
            <AddIcon />
            <span className={styles["button-text"]}>{t("createCoupon")}</span>
          </Button>
        </div>
      </div>
      <CouponsTable
        coupons={filtered}
        setUpdateTable={setUpdateTable}
        updateTable={updateTable}
        setCouponToEdit={setCouponToEdit}
        setNewCouponsModal={setNewCouponsModal}
      />
      <CouponsModal
        newCouponsModal={newCouponsModal}
        setNewCouponsModal={setNewCouponsModal}
        couponToEdit={couponToEdit}
        setCouponToEdit={setCouponToEdit}
        setUpdateTable={setUpdateTable}
        updateTable={updateTable}
      />
    </div>
  );
}

export default Coupons;
