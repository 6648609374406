import React from 'react';
import {
  BarChart, Bar, XAxis, Tooltip, ResponsiveContainer,
} from 'recharts';
// import styles from './Charts.module.css';

const data = [
  {
    name: '6p',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: '7p',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: '8p',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: '9p',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: '10p',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: '11p',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: '12p',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

function WebTraffic() {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart width={250} height={200} data={data}>
        <Bar dataKey="amt" fill="#4285F4" />
        <XAxis dataKey="name" />
        <Tooltip />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default WebTraffic;
