import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import configData from '../../config/production.json';

const { SERVER_URL } = configData;
const StyledTableCell = styled(TableCell)(() => ({
  border: 'none',
  [`&.${tableCellClasses.head}`]: {
    color: '#4F4F4F',
    backgroundColor: '#ffeecc',
    height: '17px',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
  },
}));

const root = {
  width: '97%',
};

const icons = {
  display: 'flex',
  justifyContent: 'center',
};
const avatar = {
  borderRadius: '50%',
  width: '30px',
  height: '30px',
};

export default function StudentsTable({
  setStudentsToViewed, setViewStudentsModal,
  students,
}) {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const columns = [
    { id: 'id', label: t('id'), minWidth: 16 },
    {
      id: 'name',
      label: t('name'),
      minWidth: 46,
    },
    {
      id: 'gender',
      label: t('gender'),
      minWidth: 64,
    },
    {
      id: 'birthdate',
      label: t('birthdate'),
      minWidth: 130,
    },
    {
      id: 'age',
      label: t('age'),
      minWidth: 32,
      align: 'center',

    },
    {
      id: 'caregiver',
      label: t('caregiver'),
      minWidth: 91,
    },
    {
      id: 'relationship',
      label: t('relation'),
      minWidth: 91,
    },
    {
      id: 'photo',
      label: t('photo'),
      minWidth: 55,
      align: 'center',
    },
  ];
  const handleView = (id) => {
    setStudentsToViewed(id);
    setViewStudentsModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={root}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                align="center"
              >
                {t('operation')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <StyledTableCell key={column.id} align={column.align}>
                      {column.format && typeof value === 'number' ? column.format(value)
                        : column.id === 'photo' ? <img src={`${SERVER_URL}/storage/${row.photo}`} width={30} height={30} alt="avatar" style={avatar} />
                          : column.id === 'caregiver' ? `${row.caregiver}`
                            : value}
                    </StyledTableCell>
                  );
                })}
                {columns.filter((column) => column.id === 'id').map((column) => (
                  <StyledTableCell key={column.id}>
                    <div style={icons}>
                      <VisibilityIcon onClick={() => { handleView(row.id); }} />
                    </div>
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={students.length}
        rowsPerPageOptions={10}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
StudentsTable.propTypes = {
  setStudentsToViewed: PropTypes.func.isRequired,
  setViewStudentsModal: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    birthdate: PropTypes.string.isRequired,
    relationship: PropTypes.string.isRequired,
    caregiver: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
    is_active: PropTypes.number.isRequired,
  })).isRequired,

};
