import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SubMenu from "./Submenu";
import styles from "./Sidebar.module.css";
import UserCard from "./UserCard";

const SidebarNav = styled.nav`
  background: #f3f3f3;
  width: 20%;
  min-width: 250px;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  display: ${({ sidebar }) => (sidebar ? "" : "none")};
`;
// margin-left: ${({ sidebar }) => (sidebar ? '0' : '-20%')};
// transition: 350ms;

const SidebarWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
function Sidebar({ sidebar, user, notifications, permission }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  useEffect(() => {
    setSidebarOpen(sidebar);
  }, [sidebar]);
  const manageUsers = () => ({
    title: "users",
    id: 2,
    iconClosed: <KeyboardArrowDownIcon />,
    iconOpened: <KeyboardArrowUpIcon />,
    subNav: [
      {
        title: "admin",
        path: "/users/admin",
        id: 3,
      },
      {
        title: "serviceProvider",
        path: "/users/service-provider",
        id: 4,
      },
      {
        title: "caregivers",
        path: "/users/caregivers",
        id: 5,
      },
    ],
  });
  const manageUsersandStudents = () => ({
    title: "users",
    id: 2,
    iconClosed: <KeyboardArrowDownIcon />,
    iconOpened: <KeyboardArrowUpIcon />,
    subNav: [
      {
        title: "admin",
        path: "/users/admin",
        id: 3,
      },
      {
        title: "serviceProvider",
        path: "/users/service-provider",
        id: 4,
      },
      {
        title: "caregivers",
        path: "/users/caregivers",
        id: 5,
      },
      {
        title: "students",
        path: "/users/students",
        id: 6,
      },
    ],
  });
  const manageStudents = () => ({
    title: "users",
    id: 2,
    iconClosed: <KeyboardArrowDownIcon />,
    iconOpened: <KeyboardArrowUpIcon />,
    subNav: [
      {
        title: "students",
        path: "/users/students",
        id: 6,
      },
    ],
  });
  const manageSystem = () => ({
    title: "systemSetup",
    id: 7,
    iconClosed: <KeyboardArrowDownIcon />,
    iconOpened: <KeyboardArrowUpIcon />,
    subNav: [
      {
        title: "categories",
        path: "/system-setup/categories",
        id: 8,
      },
      {
        title: "specialities",
        path: "/system-setup/specialities",
        id: 9,
      },
      {
        title: "activitiesBank",
        path: "/system-setup/activities-bank",
        id: 10,
      },
      {
        title: "otherActivities",
        path: "/system-setup/other-activities",
        id: 11,
      },
      {
        title: "evaluation",
        path: "/system-setup/evaluation",
        id: 12,
      },
      {
        title: "packages",
        path: "/system-setup/packages",
        id: 13,
      },
      {
        title: "coupons",
        path: "/system-setup/coupons",
        id: 14,
      },
      {
        title: "roles",
        path: "/system-setup/roles",
        id: 15,
      },
    ],
  });
  const pushNotifications = () => ({
    title: "notifications",
    path: "/notifications",
    id: 14,
  });
  const SidebarData = [
    {
      title: "dashboard",
      path: "/dashboard",
      id: 1,
    },
    permission.includes("Manage Users") && permission.includes("Manage Student")
      ? manageUsersandStudents()
      : permission.includes("Manage Users") &&
        !permission.includes("Manage Student")
      ? manageUsers()
      : permission.includes("Manage Student") &&
        !permission.includes("Manage Users")
      ? manageStudents()
      : null,
    permission.includes("Manage System") ? manageSystem() : null,
    permission.includes("Push Notifications") ? pushNotifications() : null,
  ];
  return (
    <SidebarNav sidebar={sidebar}>
      <SidebarWrap>
        <div className={styles["logo-container"]}>
          <img src="/logo/Logo-Inner.svg" width={75} height={109} alt="logo" />
        </div>
        <div className={styles["sidebar-buttons"]}>
          {SidebarData.map(
            (item) =>
              item && (
                <SubMenu
                  item={item}
                  key={item.id}
                  sidebarOpen={sidebarOpen}
                  notifications={notifications}
                />
              )
          )}
        </div>

        <div className={styles["user-card"]}>
          <UserCard user={user} />
        </div>
      </SidebarWrap>
    </SidebarNav>
  );
}

export default Sidebar;
Sidebar.propTypes = {
  sidebar: PropTypes.bool.isRequired,
  notifications: PropTypes.number.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
    photo: PropTypes.string,
  }).isRequired,
  permission: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
