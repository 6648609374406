/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";
import styles from "./Categories.module.css";
import PackagesTable from "../../Packages/PackagesTable";
import EditPackageModal from "../../Packages/EditPackage/EditPackage";
import ViewPackageModal from "../../Packages/ViewPackage/ViewPackage";

import { getPackages } from "../../../Services/adminServices";
import PackagesModal from "../../Packages/PackagesModal";
import Excelexport from "../../ActivityBank/Excelexport";

function Packages() {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [newPackageModal, setNewPackageModal] = useState(false);
  const [editPackagesModal, setEditPackagesModal] = useState(false);
  const [viewPackagesModal, setViewPackagesModal] = useState(false);
  const [packagesToEdit, setPackagesToEdit] = useState(-1);
  const [packagesToViewed, setPackagesToViewed] = useState(-1);
  const [packages, setPackages] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const currentLanguageCode = Cookies.get("i18next");

  useEffect(() => {
    (async () => {
      const response = await getPackages();
      if (response.status === 200) {
        setPackages(response.data.packages);
        setFiltered(response.data.packages);
      } else {
        localStorage.removeItem("token");
        window.location.reload(false);
      }
    })();
  }, [updateTable, currentLanguageCode]);
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setFiltered(
      packages.filter(
        (act) =>
          act.id == value ||
          act.description?.toLowerCase().includes(value) ||
          act.name?.toLowerCase().includes(value) ||
          act.arabic_name?.toLowerCase().includes(value) ||
          act.country?.toLowerCase().includes(value) ||
          act.currency?.toLowerCase().includes(value) ||
          act.price == value ||
          act.duration == value ||
          act.students_amount?.toLowerCase().includes(value) ||
          act.arabic_description?.toLowerCase().includes(value)
      )
    );
    if (value <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(packages);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // search
    setFiltered(
      packages.filter(
        (act) =>
          act.id == searchValue ||
          act.description?.toLowerCase().includes(searchValue) ||
          act.name?.toLowerCase().includes(searchValue) ||
          act.arabic_name?.toLowerCase().includes(searchValue) ||
          act.country?.toLowerCase().includes(searchValue) ||
          act.currency?.toLowerCase().includes(searchValue) ||
          act.price == searchValue ||
          act.duration == searchValue ||
          act.students_amount?.toLowerCase().includes(searchValue) ||
          act.arabic_description?.toLowerCase().includes(searchValue)
      )
    );
    if (searchValue <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(packages);
    }
  };
  return (
    <>
      {newPackageModal && (
        <PackagesModal
          newPackageModal={newPackageModal}
          setNewPackageModal={setNewPackageModal}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      )}
      {editPackagesModal && (
        <EditPackageModal
          setEditPackageModal={setEditPackagesModal}
          editPackageModal={editPackagesModal}
          packageToEdit={packagesToEdit}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          packages={packages}
        />
      )}
      {viewPackagesModal && (
        <ViewPackageModal
          setViewPackageModal={setViewPackagesModal}
          viewPackageModal={viewPackagesModal}
          packageToViewed={packagesToViewed}
          packages={packages}
        />
      )}

      <div className={styles["sp-container"]}>
        <div className={styles.container}>
          <form onSubmit={handleSearch} className={styles.header}>
            <SearchIcon style={{ marginRight: "20px" }} />
            <input
              className={styles["search-bar"]}
              placeholder={t("searchForSomething")}
              onChange={handleChange}
            />
            <input type="submit" hidden />
          </form>
          <div className={styles.export}>
            <div style={{ width: "50px" }}>
              <Excelexport excelData={packages} fileName="Packages" />
            </div>
            <Button
              onClick={() => {
                setNewPackageModal(true);
              }}
              className={styles["add-button"]}
              variant="contained"
            >
              <AddIcon />
              <span className={styles["button-text"]}>
                {t("createPackage")}
              </span>
            </Button>
          </div>
        </div>
        <PackagesTable
          setEditPackagesModal={setEditPackagesModal}
          setPackagesToEdit={setPackagesToEdit}
          setPackagesToViewed={setPackagesToViewed}
          setViewPackagesModal={setViewPackagesModal}
          packages={filtered}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      </div>
    </>
  );
}

export default Packages;
