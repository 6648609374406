import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button, FormControl, InputLabel, Select, TextField,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Cookies from 'js-cookie';
import styles from './StepOne.module.css';

import { createNotification, getCountries } from '../../Services/adminServices';

function StepOne({ setStepOne, setStepThree, handleUpdateTable }) {
  const { t } = useTranslation();
  function validateStepOne(values) {
    const errors = {};

    if (!values.arabic_description.trim()) {
      errors.textAR = 'required';
      errors.frontend = 'required';
    }
    if (!values.description.trim()) {
      errors.textEN = 'required';
      errors.frontend = 'required';
    } else if (!/^[A-Za-z]+/.test(values.description.trim())) {
      errors.textEN = t('validText');
      errors.frontend = t('validText');
    }
    if (!values.user_type) {
      errors.userType = 'user type required';
    }
    if (!values.country_id) {
      errors.country_id = 'country required';
    }

    return errors;
  }
  const currentLanguageCode = Cookies.get('i18next');
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState({
    description: '',
    arabic_description: '',
    user_type: '',
    country_id: '',
  });
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState({});
  useEffect(() => {
    (async () => {
      const response = await getCountries();
      if (response.status === 200) {
        setCountries(response.data);
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there is no errors
      setLoader(true);
      createNotification(values).then((response) => {
        if (response.status === 200) {
          handleUpdateTable();
          setStepOne(false);
          setStepThree(true);
        } else if (response.status === 422) {
          setError({
            ...error,
            frontend: response.data.message,
          });
        }
        setLoader(false);
      });
    }
  };
  const users = [
    {
      id: 'all',
      user: t('all'),
    },
    {
      id: 'caregiver',
      user: t('caregiver'),
    },
    {
      id: 'admin',
      user: t('admin'),
    },
    {
      id: 'provider',
      user: t('serviceProvider'),
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles['form-container']}>
        <div className={styles.fields}>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="arabic_description"
              label={t('textAR')}
              name="arabic_description"
              required
              value={values.arabic_description}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.textAR && 'secondary'}
            />
            {values.arabic_description
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              arabic_description: '',
            });
          }}
        />
        )}
          </div>

          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="description"
              label={t('textEN')}
              name="description"
              value={values.description}
              required
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.textEN && 'secondary'}
            />
            {values.description
        && (
          <HighlightOffIcon
            className={styles.cancel}
            onClick={() => {
              setValues({
                ...values,
                description: '',
              });
            }}
          />
        )}
          </div>
          <FormControl variant="outlined" className={styles['field-container']}>
            <InputLabel style={{ top: 20, left: 25 }} id="user-label" color={error.userType && 'secondary'}>{t('userType')}</InputLabel>
            <Select
              className={styles['text-field']}
              labelId="user-label"
              native
              label={t('userType')}
              value={values.user_type}
              onChange={handleChange}
              required
              color={error.userType && 'secondary'}
              inputProps={{
                name: 'user_type',
                id: 'user_type',
              }}
            >
              <option aria-label="None" value="" />
              {users.map((user) => (
                <option key={user.id} value={user.id}>{user.user}</option>

              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={styles['field-container']}>
            <InputLabel style={{ top: 20, left: 25 }} id="country-label" color={error.specialistName && 'secondary'}>{t('country')}</InputLabel>
            <Select
              className={styles['text-field']}
              labelId="country-label"
              native
              label={t('country')}
              value={values.country_id}
              onChange={handleChange}
              required
              color={error.country_id && 'secondary'}
              inputProps={{
                name: 'country_id',
                id: 'country_id',
              }}
            >
              <option aria-label="None" value="" />
              {countries.map((country) => (
                <option
                  key={country.id}
                  value={country.id}
                >
                  {currentLanguageCode === 'en' ? country.name : country.arabic_name}

                </option>

              ))}
            </Select>
          </FormControl>

        </div>
      </div>
      {error.frontend && <p className={styles.error}>{error.frontend}</p>}

      <div className={styles['button-container']}>
        <Button disabled={loader} type="submit" variant="contained" className={styles['next-button']}>
          {loader ? t('wait') : t('confirm')}
        </Button>
      </div>
    </form>
  );
}

export default StepOne;

StepOne.propTypes = {
  setStepOne: propTypes.func.isRequired,
  setStepThree: propTypes.func.isRequired,
  handleUpdateTable: propTypes.func.isRequired,
};
