import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import styles from "./StepOne.module.css";

function StepOne({ userData }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles["form-container"]}>
        <div className={styles.fields}>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="name"
              label={t("name")}
              name="name"
              value={userData.name}
              disabled
              type="text"
              variant="outlined"
            />
          </div>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="mobile"
              label={t("mobile")}
              name="mobile"
              value={userData.mobile}
              type="text"
              multiline
              variant="outlined"
              disabled
            />
          </div>

          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="email"
              label={t("email")}
              name="email"
              value={userData.email}
              type="email"
              multiline
              variant="outlined"
              disabled
            />
          </div>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="gender"
              label={t("gender")}
              name="gender"
              disabled
              value={userData.gender}
              type="text"
              variant="outlined"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepOne;
