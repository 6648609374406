import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  TextField,
} from '@mui/material';
import styles from './StepOne.module.css';

function StepOne({ userData }) {
  const { t } = useTranslation();
  const genders = [{ name: t('male'), id: 'male' }, { name: t('female'), id: 'female' }];
  return (
    <div>
      <div className={styles['form-container']}>
        <div className={styles.fields}>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="admin-name"
              label={t('name')}
              name="name"
              value={userData.name}
              type="text"
              variant="outlined"
              disabled
            />
          </div>

          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="sp-email"
              label={t('email')}
              name="email"
              value={userData.email}
              type="text"
              variant="outlined"
              disabled
            />
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="admin-view-gender"
              label={t('gender')}
              name="gender"
              value={genders.filter((gender) => userData.gender === gender.id)[0].name}
              type="text"
              variant="outlined"
              disabled
            />
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="sp-mobile"
              label={t('email')}
              name="mobile"
              value={userData.mobile}
              type="text"
              variant="outlined"
              disabled
            />
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="role"
              label={t('role')}
              name="mobile"
              value={userData.role}
              type="text"
              variant="outlined"
              disabled
            />
          </div>

        </div>
      </div>

    </div>
  );
}

export default StepOne;
StepOne.propTypes = {
  userData: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
  })).isRequired,
};
