import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button, TextField,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from './StepOne.module.css';
import { createCategory } from '../../Services/adminServices';

function StepOne({ setStepOne, setStepThree, handleUpdateTable }) {
  const { t } = useTranslation();
  function validateStepOne(values) {
    const errors = {};
    if (!values.sort_order.trim()) {
      errors.frontend = 'order required';
    } else if (values.sort_order <= 0) {
      errors.frontend = 'order not valid';
    }
    if (!values.arabic_name.trim()) {
      errors.frontend = 'name required';
    } else if (/^[A-Za-z]+/.test(values.arabic_name.trim())) {
      errors.frontend = t('validName');
    }
    if (!values.name.trim()) {
      errors.frontend = 'name required';
    } else if (!/^[A-Za-z]+/.test(values.name.trim())) {
      errors.frontend = t('validName');
    }

    return errors;
  }
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState({
    name: '',
    arabic_name: '',
    sort_order: '',
  });
  const [error, setError] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there is no errors
      setLoader(true);
      createCategory(values).then((response) => {
        if (response.status === 200) {
          handleUpdateTable();
          setStepOne(false);
          setStepThree(true);
        } else if (response.status === 422) {
          setError({
            ...error,
            frontend: response.data.message,
          });
        }
        setLoader(false);
      });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles['form-container']}>
        <div className={styles.fields}>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="name"
              label={t('nameEN')}
              name="name"
              required
              value={values.name}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.nameEN && 'secondary'}
            />
            {values.name
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              name: '',
            });
          }}
        />
        )}
          </div>

          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="arabic_name"
              label={t('nameAR')}
              name="arabic_name"
              value={values.arabic_name}
              onChange={handleChange}
              required
              type="text"
              variant="outlined"
              color={error.nameAR && 'secondary'}
            />
            {values.arabic_name
        && (
          <HighlightOffIcon
            className={styles.cancel}
            onClick={() => {
              setValues({
                ...values,
                arabic_name: '',
              });
            }}
          />
        )}
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="sort_order"
              label={t('order')}
              name="sort_order"
              required
              value={values.sort_order}
              onChange={handleChange}
              InputProps={{ inputProps: { min: 1 } }}
              type="number"
              variant="outlined"
              color={error.order && 'secondary'}
            />
            {/* {values.order
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              order: '',
            });
          }}
        />
        )} */}
          </div>

        </div>
      </div>
      {(error.frontend) && <p className={styles.error}>{error.frontend}</p>}
      <div className={styles['button-container']}>
        <Button disabled={loader} type="submit" variant="contained" className={styles['next-button']}>
          {loader ? t('wait') : t('confirm')}
        </Button>
      </div>
    </form>
  );
}

export default StepOne;

StepOne.propTypes = {
  setStepOne: propTypes.func.isRequired,
  handleUpdateTable: propTypes.func.isRequired,
  setStepThree: propTypes.func.isRequired,
};
