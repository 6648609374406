import { useState } from 'react';
import { updateUser } from '../../Services/adminServices';
// import { changeName } from '../../services/userServices';

const useChangeProfile = (setLoader, validateProfile) => {
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    photo: '',
  });
  const [error, setError] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateProfile(values));
    if (Object.keys(validateProfile(values)).length === 0) {
      // there is no errors
      setLoader(true);
      updateUser(values).then((response) => {
        if (response.status === 200) {
          window.location.reload(false);
        } else if (response.status === 422) {
          setError({
            ...error,
            email: response.data.errors.email[0] ? response.data.errors.email[0] : null,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload(false);
        }
        setLoader(false);
      });
    }
  };
  return {
    handleChange, values, handleSubmit, error, setValues,
  };
};
export default useChangeProfile;
