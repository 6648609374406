import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Cookies from 'js-cookie';
import styles from './Admin.module.css';
import AdminsTable from '../../Admins/AdminsTable';
import AdminModal from '../../Admins/AdminsModal';
import EditAdminModal from '../../Admins/EditAdmin/EditAdmin';
import ViewAdminModal from '../../Admins/ViewAdmin/ViewAdmin';

import { GetAdminUsers } from '../../../Services/adminServices';

function Admin() {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [newAdminModal, setNewAdminModal] = useState(false);
  const [editAdminModal, setEditAdminModal] = useState(false);
  const [viewAdminModal, setViewAdminModal] = useState(false);
  const [adminToEdit, setAdminToEdit] = useState(-1);
  const [adminToView, setAdminToView] = useState(-1);
  const [adminUsers, setAdminUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const currentLanguageCode = Cookies.get('i18next');

  useEffect(() => {
    (async () => {
      const response = await GetAdminUsers();
      if (response.status === 200) {
        setAdminUsers(response.data.users.map((obj) => ({
          ...obj, name: `${obj.first_name} ${obj.middle_name} ${obj.last_name}`,
        })));
        setFilteredUsers(response.data.users.map((obj) => ({
          ...obj, name: `${obj.first_name} ${obj.middle_name} ${obj.last_name}`,
        })));
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
  }, [updateTable, currentLanguageCode]);
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setFilteredUsers(
      adminUsers.filter((user) => (user.name.toLowerCase().includes(value)
      || user.email.toLowerCase().includes(value)
      || user.user_id === value
      || user.mobile.toLowerCase().includes(value)
      || user.role.toLowerCase().includes(value))),
    );
    if (value <= 0) {
      // setUpdateTable(!updateTable);
      setFilteredUsers(adminUsers);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // search
    setFilteredUsers(
      adminUsers.filter((user) => (user.name.toLowerCase().includes(searchValue)
      || user.email.toLowerCase().includes(searchValue)
      || user.user_id.toLowerCase().includes(searchValue)
      || user.mobile.toLowerCase().includes(searchValue)
      || user.role.toLowerCase().includes(searchValue))),
    );
    if (searchValue.length === 0) {
      // setUpdateTable(!updateTable);
      setFilteredUsers(adminUsers);
    }
  };
  return (
    <>
      {newAdminModal && (
      <AdminModal
        newAdminModal={newAdminModal}
        setNewAdminModal={setNewAdminModal}
        setUpdateTable={setUpdateTable}
        updateTable={updateTable}
      />
      )}
      {editAdminModal && (
        <EditAdminModal
          setEditAdminModal={setEditAdminModal}
          editAdminModal={editAdminModal}
          adminToEdit={adminToEdit}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          adminUsers={adminUsers}
        />
      )}

      {viewAdminModal && (
        <ViewAdminModal
          setViewAdminModal={setViewAdminModal}
          viewAdminModal={viewAdminModal}
          adminToView={adminToView}
          adminUsers={adminUsers}
        />
      )}
      <div className={styles['sp-container']}>
        <div className={styles.container}>
          <form onSubmit={handleSearch} className={styles.header}>
            <SearchIcon style={{ marginRight: '20px' }} />
            <input
              className={styles['search-bar']}
              placeholder={t('searchForSomething')}
              onChange={handleChange}
            />
            <input type="submit" hidden />
          </form>
          <Button onClick={() => { setNewAdminModal(true); }} className={styles['add-button']} variant="contained">
            <AddIcon />
            <span className={styles['button-text']}>{t('createAdmin')}</span>
          </Button>
        </div>
        <AdminsTable
          setEditAdminModal={setEditAdminModal}
          setAdminToEdit={setAdminToEdit}
          setViewAdminModal={setViewAdminModal}
          setAdminToView={setAdminToView}
          adminUsers={filteredUsers}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      </div>
    </>
  );
}

export default Admin;
