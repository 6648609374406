import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import {
  Button, TextField,
} from '@mui/material';
// import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from './StepOne.module.css';
import { editRole, getRoles } from '../../../Services/adminServices';
import PermissionsTable from '../PermissionsTable';

function StepOne({
  setStepOne, setStepThree, handleUpdateTable, roleToEdit, data,
}) {
  const { t } = useTranslation();
  function validateStepOne(values) {
    const errors = {};
    // if (!values.arabic_name.trim()) {
    //   errors.nameAR = 'name required';
    // }
    if (!values.name.trim()) {
      errors.nameEN = 'name required';
    } else if (!/^[A-Za-z]+/.test(values.name.trim())) {
      errors.nameEN = t('validName');
      errors.frontend = t('validName');
    }
    if (values.permissions.length === 0) {
      errors.frontend = t('permissionsRequired');
    }

    // if (!values.arabic_description.trim()) {
    //   errors.textAR = 'required';
    // }
    // if (!values.description.trim()) {
    //   errors.textEN = 'required';
    // } else if (!/^[A-Za-z]+/.test(values.description.trim())) {
    //   errors.nameEN = 'Enter a valid text';
    // }
    // if (!values.students_amount.trim()) {
    //   errors.students_amount = 'number required';
    // } else if (values.students_amount.trim() < 0) {
    //   errors.students_amount = 'Enter a valid Number';
    // }
    // if (!values.duration.trim()) {
    //   errors.duration = 'duration required';
    // } else if (values.duration.trim() < 0) {
    //   errors.duration = 'Enter a valid Duration';
    // }
    // if (!values.price.trim()) {
    //   errors.price = 'price required';
    // } else if (values.price.trim() < 0) {
    //   errors.price = 'Enter a valid Price';
    // }
    // if (!values.country_id.trim()) {
    //   errors.country_id = 'country required';
    // }

    return errors;
  }
  // const currentLanguageCode = Cookies.get('i18next');
  const [values, setValues] = useState({
    name: data.name,
    permissions: data.permissions,
    id: roleToEdit,
  });
  const [permissions, setPermissions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({});
  useEffect(() => {
    (async () => {
      const response = await getRoles();
      if (response.status === 200) {
        setPermissions(response.data.permissions);
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there is no errors
      setLoader(true);
      editRole(values).then((response) => {
        if (response.status === 200 || response.status === 201) {
          handleUpdateTable();
          setStepOne(false);
          setStepThree(true);
        } else if (response.status === 422) {
          setError({
            ...error,
            backend: response.data.message,
          });
        }
        setLoader(false);
      });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles['form-container']}>
        <div className={styles.fields}>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="name"
              label={t('nameEN')}
              required
              name="name"
              value={values.name}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.nameEN && 'secondary'}
            />
            {values.name
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              name: '',
            });
          }}
        />
        )}
          </div>
          <div className={styles['field-container']}>
            <div className={styles['text-field']}>
              <PermissionsTable permissions={permissions} values={values} setValues={setValues} />
            </div>
          </div>

        </div>
      </div>
      {error.frontend && <p className={styles.error}>{error.frontend}</p>}

      <div className={styles['button-container']}>
        <Button disabled={loader} type="submit" variant="contained" className={styles['next-button']}>
          {loader ? t('wait') : t('confirm')}
        </Button>
      </div>
    </form>
  );
}

export default StepOne;

StepOne.propTypes = {
  setStepOne: propTypes.func.isRequired,
  setStepThree: propTypes.func.isRequired,
  handleUpdateTable: propTypes.func.isRequired,
  roleToEdit: propTypes.number.isRequired,
  data: propTypes.shape({
    name: propTypes.string.isRequired,
    arabic_name: propTypes.string.isRequired,
    id: propTypes.number.isRequired,
    permissions: propTypes.arrayOf(propTypes.shape({
      name: propTypes.string.isRequired,
      arabic_name: propTypes.string.isRequired,
      id: propTypes.number.isRequired,
    })),
  }).isRequired,
};
