import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../../Admins/EditAdmin/EditAdmin.module.css";
import StepOne from "./StepOne";
import StepThree from "./StepThree";

const modal = {
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
};
const paper = {
  position: "relative",
  border: "none",
  maxWidth: "350px",
  minWidth: "295px",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function EditAdminModal({
  setEditAdminModal,
  editAdminModal,
  adminToEdit,
  updateTable,
  setUpdateTable,
  adminUsers,
}) {
  const { t } = useTranslation();
  // const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [stepOne, setStepOne] = useState(true);
  const [stepThree, setStepThree] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    setOpen(editAdminModal);
    setUserData(adminUsers.find((user) => adminToEdit === user.user_id));
  }, [editAdminModal]);
  const handleUpdateTable = () => {
    setUpdateTable(!updateTable);
  };
  const handleClose = () => {
    setEditAdminModal(false);
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        sx={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={paper}>
            <p id="newService-modal-description" className={styles.subtitle}>
              {t("caregiver")}
            </p>
            <h2 id="newService-modal-title" className={styles.title}>
              {t("viewCaregiver")}
            </h2>
            {stepOne && (
              <CloseIcon onClick={handleClose} className={styles.close} />
            )}
            {stepOne ? (
              <StepOne
                setStepOne={setStepOne}
                setStepThree={setStepThree}
                adminToEdit={adminToEdit}
                handleUpdateTable={handleUpdateTable}
                userData={userData}
              />
            ) : (
              stepThree && <StepThree handleClose={handleClose} />
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
