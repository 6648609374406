import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import styles from './StepThree.module.css';

function StepThree({ handleClose }) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('roleAdded')}</h1>
      <div className={styles.circle}>
        <CheckIcon style={{ width: '70%', height: '70%' }} />
      </div>
      <button type="button" className={styles.close} onClick={handleClose}>
        <CloseIcon />
        <p>{t('close')}</p>
      </button>
    </div>
  );
}

export default StepThree;
StepThree.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
