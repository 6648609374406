import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ButtonGroup from '@mui/material/ButtonGroup';
import LanguageIcon from '@mui/icons-material/Language';
import styles from './ForgotPassword.module.css';
import useForgotPasswordForm from './useForgotPassword';
import ConfirmModal from './Components/ConfirmModal';
import i18next from '../../i18next';

const color = {
  color: 'black',
};

function ForgotPassword() {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState(false);
  const [loader, setLoader] = useState(false);
  function validateEmail(values) {
    const errors = {};

    if (!values.email) {
      errors.email = t('emailRequired');
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = t('emailNotValid');
    }

    return errors;
  }
  const {
    handleChange, values, setValues, handleSubmit, error,
  } = useForgotPasswordForm(setConfirm, validateEmail, setLoader);
  return (
    <>
      <div className={styles['forgot-password-container']}>
        <img src="./logo/Logo-Outer.svg" width={123} height={162} alt="login logo" />
        <p className={styles.title}>{t('forgotPasswordTitle')}</p>
        <h5 className={styles.title2}>{t('recoverPassword')}</h5>
        <p className={styles.body1}>
          {t('forgotPasswordP')}
        </p>
        <form onSubmit={handleSubmit} className={styles['form-conatiner']}>

          <div className={styles.fields}>
            <TextField
              className={styles.fields}
              id="forgot-password-email"
              label={t('email')}
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              variant="outlined"
              color={error.email && 'secondary'}
            />
            {values.email
                  && (
                  <HighlightOffIcon
                    className={styles.cancel}
                    onClick={() => {
                      setValues({
                        ...values,
                        email: '',
                      });
                    }}
                  />
                  )}
          </div>
          {(error.email) && <p className={styles.error}>{(error.email)}</p>}
          <div className={styles['confirm-button-container']}>
            <Button disabled={loader} type="submit" variant="contained" className={styles['confirm-button']}>
              {loader ? t('wait') : t('confirm')}
            </Button>
          </div>
          <div className={styles['return-container']}>
            <img src="./icons/arrow1.svg" alt="back arrow" />
            <a href="/login" className={styles.return}>{t('returnToLogin')}</a>
          </div>
        </form>
        <ConfirmModal confirm={confirm} setConfirm={setConfirm} />
      </div>
      <div className="languages-container">
        <LanguageIcon className="language-icon" />
        <ButtonGroup sx={color} variant="text" aria-label="text primary button group">
          <Button sx={color} onClick={() => i18next.changeLanguage('en')} className="languages-buttons">English</Button>
          <Button sx={color} onClick={() => i18next.changeLanguage('ar')} className="languages-buttons">Arabic</Button>
        </ButtonGroup>
      </div>
    </>
  );
}

export default ForgotPassword;
