import React, { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  TextField,
  FormControl,
  Select,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import stylesEn from "./StepOne.module.css";
import stylesAr from "./StepOneAr.module.css";
import { addCoupon } from "../../Services/adminServices";

function StepOne({
  setStepOne,
  setStepThree,
  couponToEdit,
  setUpdateTable,
  updateTable,
}) {
  const { t, i18n } = useTranslation();
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const styles = currentLanguageCode === "en" ? stylesEn : stylesAr;

  const types = [
    { name: t("value"), id: "value" },
    { name: t("percentage"), id: "percentage" },
    { name: t("duration"), id: "duration" },
  ];

  const [loader, setLoader] = useState(false);

  const [values, setValues] = useState({
    id: "",
    code: "",
    type: "",
    amount: "",
    expiresAt: "",
  });

  useEffect(() => {
    if (couponToEdit) {
      setValues({
        id: couponToEdit.id,
        code: couponToEdit.code,
        type: couponToEdit.type,
        amount: couponToEdit.amount,
        expiresAt: couponToEdit.expires_at,
      });
    }
  }, [couponToEdit]);

  function validateStepOne(value) {
    const errors = {};
    if (!value.code.trim()) {
      errors.code = "Code required";
    }
    if (!value.type) {
      errors.type = "Type required";
    }
    if (!value.amount) {
      errors.amount = "Amount Required";
    }
    if (!value.expiresAt) {
      errors.expiresAt = "Expires required";
    }

    return errors;
  }

  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleExpireDateChange = (newValue) => {
    setValues({
      ...values,
      expiresAt: dayjs(newValue?.$d).format("YYYY-MM-DD"),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there is no errors
      setLoader(true);

      addCoupon(values)
        .then((response) => {
          setStepOne(false);
          setStepThree(true);
          setUpdateTable(!updateTable);
        })
        .catch((err) => {
          setError({
            ...error,
            code: err.response.data.errors.code,
            type: err.response.data.errors.type,
            amount: err.response.data.errors.amount,
            expiresAt: err.response.data.errors.expires_at,
          });
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="code"
            label={t("CouponCode")}
            name="code"
            required
            value={values.code}
            onChange={handleChange}
            type="text"
            variant="outlined"
            color={error.code && "secondary"}
            fullWidth
          />
          {error.code && <p className={styles.error}>{error.code}</p>}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel
              required
              id="type-label"
              color={error.type && "secondary"}
            >
              {t("CouponType")}
            </InputLabel>
            <Select
              labelId="type-label"
              native
              label={t("CouponType")}
              value={values.type}
              required
              onChange={handleChange}
              color={error.type && "secondary"}
              inputProps={{
                name: "type",
                id: "type",
              }}
            >
              <option aria-label="None" value="" />
              {types.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <TextField
              id="amount"
              label={t("CouponAmount")}
              name="amount"
              required
              value={values.amount}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.amount && "secondary"}
              fullWidth
            />
            {error.amount && <p className={styles.error}>{error.amount}</p>}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("CouponExpire")}
                value={values.expiresAt}
                onChange={handleExpireDateChange}
                renderInput={(props) => <TextField fullWidth {...props} />}
              />
            </LocalizationProvider>
          </div>
        </Grid>
      </Grid>

      <div className={styles["button-container"]}>
        <Button
          disabled={loader}
          type="submit"
          variant="contained"
          className={styles["next-button"]}
        >
          {loader ? t("wait") : t("save")}
        </Button>
      </div>
    </form>
  );
}

export default StepOne;
