import React from 'react';
import GlobeMap from './GlobeMap/GlobeMap';

function OnlineUsers() {
  return (
    <GlobeMap />
  );
}

export default OnlineUsers;
