import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../Services/adminServices';

const useForgotPasswordForm = (setConfirm, validateEmail, setLoader) => {
  const [values, setValues] = useState({
    email: '',
  });
  // const navigate = useNavigate();
  const [error, setError] = useState({});
  //   const { login, setAuth } = useAuth();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateEmail(values));
    if (Object.keys(validateEmail(values)).length === 0) {
      // there is no errors
      setLoader(true);
      forgotPassword(values).then((response) => {
        if (response.status === 200) {
          setConfirm(true);
        } else if (response.status === 422) {
          setError({
            ...error,
            email: response.data.message,
          });
        }
        setLoader(false);
      });
    }
  };
  return {
    handleChange, values, setValues, handleSubmit, error,
  };
};
export default useForgotPasswordForm;
