import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";
import AdminLogin from "./Admin/Login/Login";
import ForgotPassword from "./Admin/ForgotPassword/ForgotPassword";
import ResetPassword from "./Admin/ForgotPassword/ResetPassword";
import Layout from "./Layout";
import Dashboard from "./Admin/Pages/Dashboard";
import Admin from "./Admin/Pages/Users/Admin";
import ServiceProviders from "./Admin/Pages/Users/ServiceProviders";
import CareGivers from "./Admin/Pages/Users/CareGivers";
import Students from "./Admin/Pages/Users/Students";
import Categories from "./Admin/Pages/SystemSetup/Categories";
import Specialities from "./Admin/Pages/SystemSetup/Specialities";
import ActivitiesBank from "./Admin/Pages/SystemSetup/ActivitiesBank";
import OtherActivities from "./Admin/Pages/SystemSetup/OtherActivities";
import Evaluation from "./Admin/Pages/SystemSetup/Evaluation";
import Packages from "./Admin/Pages/SystemSetup/Packages";
import Notifications from "./Admin/Pages/Notifications";
import Profile from "./Admin/Pages/Profile";
import { AuthProvider } from "./Auth/AuthProvider";
import LoggedOut from "./Auth/LoggedOut";
import RequireAuth from "./Auth/RequireAuth";
import Roles from "./Admin/Pages/SystemSetup/Roles";
import NotFound from "./Admin/Pages/404";
import Unauthorized from "./Admin/Pages/Unauthorized";
import MapLocation from "./map";
import Coupons from "./Admin/Pages/SystemSetup/Coupons";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffa800",
    },
    secondary: {
      main: "#b3261e",
    },
  },
  typography: {
    fontFamily: '"Readex Pro", "Roboto", sans-serif',
  },
});

function App() {
  const [needActivation, setNeedActivation] = useState(false);
  const ROLES = [
    "Manage Users",
    "Manage System",
    "Manage Data",
    "Manage Application",
    "Manage Student",
    "Push Notifications",
    "Manage Coupons", // 6
  ];
  return (
    <div className="app">
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route element={<LoggedOut />}>
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/login"
                  element={
                    <AdminLogin
                      needActivation={needActivation}
                      setNeedActivation={setNeedActivation}
                    />
                  }
                />
                <Route
                  path="/password-reset/link"
                  element={<ResetPassword />}
                />
              </Route>
              <Route
                element={
                  <RequireAuth
                    allowedRoles={[
                      ROLES[0],
                      ROLES[1],
                      ROLES[2],
                      ROLES[3],
                      ROLES[4],
                      ROLES[5],
                      ROLES[6],
                    ]}
                  />
                }
              >
                <Route
                  path="/"
                  element={<Layout setNeedActivation={setNeedActivation} />}
                >
                  <Route path="" element={<Dashboard />} />
                  <Route path="/profile" element={<Profile />} />
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES[0]]} />}>
                <Route
                  path="/"
                  element={<Layout setNeedActivation={setNeedActivation} />}
                >
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/users/admin" element={<Admin />} />
                  <Route
                    path="/users/service-provider"
                    element={<ServiceProviders />}
                  />
                  <Route path="/users/caregivers" element={<CareGivers />} />
                  <Route path="/users/students" element={<Students />} />
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES[1]]} />}>
                <Route
                  path="/"
                  element={<Layout setNeedActivation={setNeedActivation} />}
                >
                  <Route
                    path="/system-setup/categories"
                    element={<Categories />}
                  />
                  <Route
                    path="/system-setup/specialities"
                    element={<Specialities />}
                  />
                  <Route
                    path="/system-setup/activities-bank"
                    element={<ActivitiesBank />}
                  />
                  <Route
                    path="/system-setup/other-activities"
                    element={<OtherActivities />}
                  />
                  <Route
                    path="/system-setup/evaluation"
                    element={<Evaluation />}
                  />
                  <Route path="/system-setup/packages" element={<Packages />} />
                  <Route path="/system-setup/roles" element={<Roles />} />
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES[5]]} />}>
                <Route
                  path="/"
                  element={<Layout setNeedActivation={setNeedActivation} />}
                >
                  <Route path="/notifications" element={<Notifications />} />
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES[6]]} />}>
                <Route
                  path="/"
                  element={<Layout setNeedActivation={setNeedActivation} />}
                >
                  <Route path="/system-setup/coupons" element={<Coupons />} />
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/map" element={<MapLocation />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
