import axios from 'axios';
import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import configData from '../config/production.json';

const { SERVER_URL } = configData;
const axiosInstance = axios.create({
  baseURL: SERVER_URL, // Set your server URL
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
  },
});

const handleTokenExpiration = () => {
  localStorage.removeItem('token');
  // Add your custom navigation logic here.
  // For example, you can use window.location.href to redirect to the login page.
  window.location.href = '/login';
};

axiosInstance.interceptors.request.use(
  (config) => {
    const currentLanguageCode = cookies.get('i18next');
    const modifiedConfig = { ...config }; // Create a new instance of the config object
    modifiedConfig.headers['accept-language'] = currentLanguageCode;

    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds

      if (decodedToken.exp < currentTime) {
        // Token is expired, handle logout
        handleTokenExpiration();
      } else {
        // Token is valid, add it to the request headers
        modifiedConfig.headers.Authorization = `Bearer ${token}`;
      }
    }

    return modifiedConfig;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
