/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PlaceIcon from "@mui/icons-material/Place";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import styles from "./StepOne.module.css";
import configData from "../../../config/production.json";

const { SERVER_URL } = configData;
const StyledTable = styled(Table)(() => ({
  border: "none",
  width: "100%",
}));
const StyledTableCell = styled(TableCell)(() => ({
  // border: 'none',
  // [`&.${tableCellClasses.body}`]: {
  //   border: 'none',

  // },
  [`&.${tableCellClasses.head}`]: {
    height: "17px",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.08em",
    textTransform: "uppercase",
  },
}));

function ViewSP({ userData }) {
  const [documents, setDocuments] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    setDocuments(userData.files ?? []);
  }, []);
  const genders = [
    { name: t("male"), id: "male" },
    { name: t("female"), id: "female" },
  ];
  return (
    <div>
      <div className={styles["form-container"]}>
        <div className={styles.fields}>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-name"
              label={t("name")}
              disabled
              value={userData.name}
              type="text"
              variant="outlined"
            />
          </div>

          {/* </div>
        <div className={styles.fields}> */}
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-email"
              label={t("email")}
              disabled
              value={userData.user.email}
              type="text"
              variant="outlined"
            />
          </div>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-country"
              label={t("country")}
              disabled
              value={userData.country}
              type="text"
              variant="outlined"
            />
          </div>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-governorate"
              label={t("governorate")}
              disabled
              value={userData.governorate}
              type="text"
              variant="outlined"
            />
          </div>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-area"
              label={t("area")}
              disabled
              value={userData.area}
              type="text"
              variant="outlined"
            />
          </div>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-mobile"
              label={t("mobile")}
              disabled
              value={userData.mobile}
              type="text"
              variant="outlined"
            />
          </div>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-type"
              label={t("Service Provider Type")}
              disabled
              value={userData.service_provider_type}
              type="text"
              variant="outlined"
            />
          </div>
          {(userData.service_provider_type_id == 1 ||
            userData.service_provider_type_id == 2) && (
            <div className={styles["field-container"]}>
              <TextField
                className={styles["text-field"]}
                id="sp-comercial-register"
                label={t("Comercial Register")}
                disabled
                value={userData.commercial_register}
                type="text"
                variant="outlined"
              />
            </div>
          )}
          {(userData.service_provider_type_id == 1 ||
            userData.service_provider_type_id == 2) && (
            <div className={styles["field-container"]}>
              <TextField
                className={styles["text-field"]}
                id="sp-licensing-auth"
                label={t("Licensing Authority")}
                disabled
                value={userData.licensing_authority}
                type="text"
                variant="outlined"
              />
            </div>
          )}
          {(userData.service_provider_type_id == 1 ||
            userData.service_provider_type_id == 2) && (
            <div className={styles["field-container"]}>
              <TextField
                className={styles["text-field"]}
                id="sp-licencing-expired"
                label={t("Licensing Expired")}
                disabled
                value={userData.licensing_expired}
                type="text"
                variant="outlined"
              />
            </div>
          )}
          {(userData.service_provider_type_id == 1 ||
            userData.service_provider_type_id == 2) && (
            <div className={styles["field-container"]}>
              <TextField
                className={styles["text-field"]}
                id="sp-licensing-number"
                label={t("Licensing Number")}
                disabled
                value={userData.licensing_number}
                type="text"
                variant="outlined"
              />
            </div>
          )}
          {userData.service_provider_type_id == 3 && (
            <div className={styles["field-container"]}>
              <TextField
                className={styles["text-field"]}
                id="sp-id-number"
                label={t("Id Number")}
                disabled
                value={userData.id_number}
                type="text"
                variant="outlined"
              />
            </div>
          )}
          {userData.service_provider_type_id == 3 && (
            <div className={styles["field-container"]}>
              <TextField
                className={styles["text-field"]}
                id="sp-nationality"
                label={t("nationality")}
                disabled
                value={userData.nationality}
                type="text"
                variant="outlined"
              />
            </div>
          )}
          {userData.service_provider_type_id == 3 && (
            <div className={styles["field-container"]}>
              <TextField
                className={styles["text-field"]}
                id="sp-gender"
                label={t("gender")}
                disabled
                value={userData.gender ?? ""}
                type="text"
                variant="outlined"
              />
            </div>
          )}

          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-location"
              label={t("location")}
              disabled
              value={userData.location}
              type="text"
              variant="outlined"
            />
            <PlaceIcon className={styles.cancel} />
          </div>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-package"
              label={t("package")}
              value={userData.package}
              disabled
              type="text"
              variant="outlined"
            />
          </div>
          {/* </div>
        <div className={styles.fields}> */}
        </div>
      </div>
      <StyledTable size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{t("Service")}</StyledTableCell>
            <StyledTableCell>{t("Type")}</StyledTableCell>
            <StyledTableCell>{t("Payment")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData.services.map((serv) => (
            <TableRow>
              <TableCell
                className={styles["table-body"]}
                component="th"
                scope="row"
              >
                {serv.name}
              </TableCell>
              <TableCell
                className={styles["table-body"]}
                component="th"
                scope="row"
              >
                {serv.service_provider_service_type}
              </TableCell>
              <TableCell
                className={styles["table-body"]}
                component="th"
                scope="row"
              >
                {serv.service_provider_service_payment}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      <h6 className={styles["documents-title"]}>
        {t("documents&certificates")}
      </h6>
      <div className={styles.docs}>
        {userData?.logo.toLowerCase().endsWith(".pdf") ? (
          <a href={`${SERVER_URL}/storage/${userData?.logo}`} download>
            <img
              width={222}
              height={125}
              style={{ margin: 10 }}
              src="/pdf perview.png"
              alt="certificate"
            />
            <p style={{ margin: 0, textAlign: "center" }}>{t("logo")}</p>
          </a>
        ) : (
          <a href={`${SERVER_URL}/storage/${userData?.logo}`} download>
            {" "}
            <img
              width={222}
              height={125}
              style={{ margin: 10 }}
              src={`${SERVER_URL}/storage/${userData?.logo}`}
              alt="certificate"
            />
            <p style={{ margin: 0, textAlign: "center" }}>{t("logo")}</p>
          </a>
        )}
        {userData?.brochure.toLowerCase().endsWith(".pdf") ? (
          <a href={`${SERVER_URL}/storage/${userData?.brochure}`} download>
            <img
              width={222}
              height={125}
              style={{ margin: 10 }}
              src="/pdf perview.png"
              alt="certificate"
            />
            <p style={{ margin: 0, textAlign: "center" }}>{t("brochure")}</p>
          </a>
        ) : (
          <a href={`${SERVER_URL}/storage/${userData?.brochure}`} download>
            {" "}
            <img
              width={222}
              height={125}
              style={{ margin: 10 }}
              src={`${SERVER_URL}/storage/${userData?.brochure}`}
              alt="certificate"
            />
            <p style={{ margin: 0, textAlign: "center" }}>{t("brochure")}</p>
          </a>
        )}
        {documents.map((doc) =>
          doc.toLowerCase().endsWith(".pdf") ? (
            <a href={`${SERVER_URL}/storage/${doc}`} download>
              <img
                width={222}
                height={125}
                style={{ margin: 10 }}
                src="/pdf perview.png"
                alt="certificate"
              />
            </a>
          ) : (
            <a href={`${SERVER_URL}/storage/${doc}`} download>
              {" "}
              <img
                width={222}
                height={125}
                style={{ margin: 10 }}
                src={`${SERVER_URL}/storage/${doc}`}
                alt="certificate"
              />
            </a>
          )
        )}
      </div>
    </div>
  );
}

export default ViewSP;
