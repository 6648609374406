import React, { useState } from "react";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ReactPasswordChecklist from "react-password-checklist";
import styles from "./StepOne.module.css";
import { changePassword } from "../../../../Services/adminServices";

function PasswordField({ id, label, name, value, onChange, error }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      className={styles["text-field"]}
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      type={showPassword ? "text" : "password"}
      variant="outlined"
      color={error ? "secondary" : "primary"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

function StepOne({
  setStepOne,
  setStepThree,
  handleUpdateTable,
  adminToEdit,
  userData,
}) {
  const { t, i18n } = useTranslation();

  const [loader, setLoader] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [values, setValues] = useState({
    user_id: adminToEdit,
    email: userData.email,
    password: "",
    old_password: "$Master Password$",
    password_confirmation: "",
  });
  function validateStepOne(value) {
    const errors = {};
    if (!value.email) {
      errors.frontend = t("emailRequired");
      errors.email = t("emailRequired");
    } else if (!/\S+@\S+\.\S+/.test(value.email)) {
      errors.frontend = t("emailNotValid");
      errors.email = t("emailNotValid");
    }

    if (values.password || values.password_confirmation) {
      if (values.password.length < 8) {
        errors.frontend = t("passShort");
      } else if (!/(?=.*?[A-Z])/.test(value.password)) {
        errors.frontend = t("passwordRule");
      } else if (!/(?=.*?[a-z])/.test(value.password)) {
        errors.frontend = t("passwordRule");
      } else if (!/(?=.*?[0-9])/.test(value.password)) {
        errors.frontend = t("passwordRule");
      } else if (!/(?=.*?[#?!@$%^&*-/_])/.test(value.password)) {
        errors.frontend = t("passwordRule");
      }
      if (value.password !== value.password_confirmation) {
        errors.frontend = t("missMatchPassword");
      }
    }

    return errors;
  }

  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHasValue(true);
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there is no errors
      setLoader(true);
      changePassword(values).then((response) => {
        if (response.status === 200) {
          handleUpdateTable();
          setStepOne(false);
          setStepThree(true);
        } else if (response.status === 422) {
          setError({
            ...error,
            frontend: response.data.message,
          });
        } else {
          setError({
            ...error,
            password: "user not found",
          });
        }
        setLoader(false);
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles["form-container"]}>
        <div className={styles.fields}>
          <div className={styles["field-container"]}>
            <p>
              {t("name")}: {userData.first_name} {userData.middle_name}{" "}
              {userData.last_name}
            </p>
            <p>
              {t("email")}: {values.email}
            </p>
          </div>

          <div className={styles["field-container"]}>
            <PasswordField
              id="sp-password"
              label={t("password")}
              name="password"
              value={values.password}
              onChange={handleChange}
              error={error.password}
            />
          </div>
          <div className={styles["field-container"]}>
            <PasswordField
              id="sp-password_confirmation"
              label={t("confirmPassword")}
              name="password_confirmation"
              value={values.password_confirmation}
              onChange={handleChange}
              error={error.password_confirmation}
            />
          </div>
          {hasValue && (
            <ReactPasswordChecklist
              rules={["minLength", "specialChar", "number", "capital", "match"]}
              minLength={5}
              value={values.password}
              valueAgain={values.password_confirmation}
              messages={{
                minLength: t("minLength"),
                specialChar: t("specialChar"),
                number: t("number"),
                capital: t("capital"),
                match: t("match"),
              }}
              rtl={i18n.language === "ar"}
              iconSize={10}
              style={{
                direction: i18n.language === "ar" ? "rtl" : "ltr",
                fontSize: "small",
              }}
            />
          )}
        </div>
      </div>
      {error.frontend && <p className={styles.error}>{error.frontend}</p>}
      <div className={styles["button-container"]}>
        <Button
          disabled={loader}
          type="submit"
          variant="contained"
          className={styles["next-button"]}
        >
          {loader ? t("wait") : t("confirm")}
        </Button>
      </div>
    </form>
  );
}

export default StepOne;
