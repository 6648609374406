import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import propTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import styles from './CategoriesModal.module.css';
import StepOne from './StepOne';
import StepThree from './StepThree';

const modal = {
  display: 'flex',
  alignItems: 'right',
  justifyContent: 'right',
};
const paper = {
  position: 'relative',
  border: 'none',
  maxWidth: '350px',
  minWidth: '295px',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function SpecialityModal({
  setNewSpecialityModal, newSpecialityModal,
  setUpdateTable, updateTable,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [stepOne, setStepOne] = useState(true);
  const [stepThree, setStepThree] = useState(false);

  useEffect(() => {
    setOpen(newSpecialityModal);
  }, [newSpecialityModal]);

  const handleClose = () => {
    setNewSpecialityModal(false);
    setOpen(false);
  };
  const handleUpdateTable = () => {
    setUpdateTable(!updateTable);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        sx={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={paper}>
            <h2 id="newService-modal-title" className={styles.title}>{(stepOne) && t('createSpeciality')}</h2>
            {(stepOne) && <CloseIcon onClick={handleClose} className={styles.close} />}
            {stepOne
              ? (
                <StepOne
                  handleUpdateTable={handleUpdateTable}
                  setStepOne={setStepOne}
                  setStepThree={setStepThree}
                />
              )
              : stepThree && <StepThree handleClose={handleClose} />}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
SpecialityModal.propTypes = {
  setNewSpecialityModal: propTypes.func.isRequired,
  newSpecialityModal: propTypes.bool.isRequired,
  setUpdateTable: propTypes.func.isRequired,
  updateTable: propTypes.bool.isRequired,
};
