import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import propTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import styles from './ViewRole.module.css';
import StepOne from './StepOne';

const modal = {
  display: 'flex',
  alignItems: 'right',
  justifyContent: 'right',
};
const paper = {
  position: 'relative',
  border: 'none',
  maxWidth: '350px',
  minWidth: '295px',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ViewRolesModal({
  setViewRoleModal,
  viewRoleModal, roleToViewed, roles,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    setOpen(viewRoleModal);
    setUserData(roles.find((user) => roleToViewed === user.id));
  }, [viewRoleModal]);

  const handleClose = () => {
    setViewRoleModal(false);
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        sx={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={paper}>
            <p id="newService-modal-description" className={styles.subtitle}>{`${t('role')} ${roleToViewed}`}</p>
            <h2 id="newService-modal-title" className={styles.title}>
              {t('viewRole')}
            </h2>
            <CloseIcon onClick={handleClose} className={styles.close} />
            <StepOne roles={userData} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
ViewRolesModal.propTypes = {
  setViewRoleModal: propTypes.func.isRequired,
  viewRoleModal: propTypes.bool.isRequired,
  roleToViewed: propTypes.number.isRequired,
  roles: propTypes.arrayOf(propTypes.shape({
    name: propTypes.string.isRequired,
    arabic_name: propTypes.string.isRequired,
    id: propTypes.number.isRequired,
    permissions: propTypes.arrayOf(propTypes.shape({
      name: propTypes.string.isRequired,
      arabic_name: propTypes.string.isRequired,
      id: propTypes.number.isRequired,
    })),
  })).isRequired,
};
