import React from 'react';
import styles from './SuspenseLoading.module.css';

function SuspenseLoading() {
  return (
    <div className={styles.container}>
      <img src="/logo/Logo-Outer.svg" alt="suspense loader" />
      <div className={styles.load}>
        <div>-</div>
        <div>-</div>
        <div>-</div>
        <div>-</div>
        <div>-</div>
        <div>-</div>
        <div>-</div>
      </div>
    </div>

  );
}

export default SuspenseLoading;
