import React from 'react';
import { useTranslation } from 'react-i18next';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import styles from './Charts.module.css';

function MiniChart() {
  const { t } = useTranslation();
  return (
    <div className={styles['mini-chart']}>
      <div className={styles['mini-column']}>
        <p className={styles['mini-title']}>{t('centers')}</p>
        <h3 className={styles['mini-number']}>23</h3>
        <div className={styles['mini-stats-container']}>
          <TrendingUpIcon className={styles['trending-up']} />
          <p>+8%</p>
        </div>
      </div>
      <div className={styles['mini-column']}>
        <p className={styles['mini-title']}>{t('specialists')}</p>
        <h3 className={styles['mini-number']}>146</h3>
        <div className={styles['mini-stats-container']}>
          <TrendingDownIcon className={styles['trending-down']} />
          <p>-8%</p>
        </div>
      </div>
    </div>
  );
}

export default MiniChart;
