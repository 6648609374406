import React, { useState, createContext } from 'react';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext({});

// eslint-disable-next-line react/prop-types
export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({});
  const [permissions, setPermissions] = useState([]);
  const login = (user) => {
    localStorage.setItem('token', user.token);
    setPermissions(jwtDecode(user.token).permissions);
  };
  const logout = () => {
    localStorage.removeItem('token');
  };
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthContext.Provider value={{
      login, logout, auth, setAuth, permissions,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
