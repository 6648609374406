import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

import Navbar from './Admin/Navbar/Navbar';
import RightNav from './Admin/Sidebar/MobileBar';
import Sidebar from './Admin/Sidebar/Sidebar';
import styles from './Layout.module.css';
import { getUser } from './Services/adminServices';
import useAuth from './Auth/useAuth';

function Layout({ setNeedActivation }) {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);
  const [user, setUser] = useState({});
  const [notifications, setNotifications] = useState(0);
  const [permission, setPermission] = useState([]);

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion]);
  const { auth, permissions } = useAuth();
  useEffect(() => {
    (async () => {
      const response = await getUser();
      if (response.status === 200) {
        setUser(response.data.user);
        setNotifications(response.data.notifications.length);
        setNeedActivation(false);
      } else if (response.status === 419) {
        setNeedActivation(true);
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
    const token = localStorage.token ? localStorage.token : null;
    if (auth.token) {
      setPermission(permissions);
    } else if (token) {
      setPermission(jwtDecode(token).permissions);
    }
  }, []);
  return (
    <div className={styles['layout-container']}>
      {windowDimenion.winWidth >= 768 ? (
        <Sidebar
          sidebar={sidebar}
          user={user}
          notifications={notifications}
          permission={permission}
        />
      ) : (
        <RightNav
          open={sidebar}
          setSidebar={setSidebar}
          user={user}
          notifications={notifications}
          permission={permission}
        />
      )}
      <div className={styles['navbar-outlet']}>
        <Navbar setSidebar={setSidebar} sidebar={sidebar} />
        <main className={styles['pages-container']}>
          <Outlet />
        </main>
      </div>

    </div>
  );
}
export default Layout;
Layout.propTypes = {
  setNeedActivation: PropTypes.func.isRequired,
};
