/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import PlaceIcon from "@mui/icons-material/Place";
import {
  Button,
  FormControl,
  InputBase,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import styles from "./StepOne.module.css";

import TableDemo from "./ServicesTable";

import {
  getCities,
  getCountries,
  getGovernorates,
  getSpTypes,
  validateServiceProvider,
} from "../../../Services/adminServices";

function StepOne({ setStepOne, setStepTwo, setSpValues }) {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  function validateStepOne(values) {
    const errors = {};
    if (!values.name) {
      errors.name = "name required";
    }
    if (!values.area) {
      errors.area = "required";
    }
    if (!values.location.trim()) {
      errors.location = "required";
    } else if (!/^[A-Za-z]+/.test(values.location.trim())) {
      errors.location = "Enter a valid type";
    }
    if (!values.logoName) {
      errors.logoName = "required";
    }
    if (!values.email) {
      errors.email = "Email Required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = t("emailNotValid");
      errors.frontend = t("emailNotValid");
    }
    if (!values.mobile.trim()) {
      errors.mobile = "number required";
    }
    if (
      !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/.test(
        values.mobile.replaceAll(" ", "")
      )
    ) {
      if (
        !/(\d{4})[- ]?(\d{3})[- ]?(\d{4})$/.test(
          values.mobile.replaceAll(" ", "")
        )
      ) {
        errors.mobile = t("valideNumber");
        errors.frontend = t("valideNumber");
      }
    }
    if (values.mobile.length < 11) {
      errors.mobile = t("valideNumber");
      errors.frontend = t("valideNumber");
    }
    if (!values.password) {
      errors.password = t("passwordRequired");
      errors.frontend = t("passwordRequired");
    } else if (values.password.length < 8) {
      errors.frontend = t("passShort");
      errors.password = t("passShort");
    } else if (!/(?=.*?[A-Z])/.test(values.password)) {
      errors.password = t("passwordRule");
      errors.frontend = t("passwordRule");
    } else if (!/(?=.*?[a-z])/.test(values.password)) {
      errors.password = t("passwordRule");
      errors.frontend = t("passwordRule");
    } else if (!/(?=.*?[0-9])/.test(values.password)) {
      errors.password = t("passwordRule");
      errors.frontend = t("passwordRule");
    } else if (!/(?=.*?[#?!@$%^&*-/_])/.test(values.password)) {
      errors.password = t("passwordRule");
      errors.frontend = t("passwordRule");
    }
    if (values.password !== values.password_confirmation) {
      errors.password_confirmation = t("missMatchPassword");
      errors.frontend = t("missMatchPassword");
    }
    if (!values.country_id) {
      errors.country_id = "country required";
    }
    if (!values.governorate_id) {
      errors.governorate_id = "governorate required";
    }
    if (!values.service_provider_type_id) {
      errors.service_provider_type_id = "Type required";
    }
    if (values.files.length === 0) {
      errors.files = t("filesRequired");
      errors.frontend = t("filesRequired");
    }
    if (values.services.length === 0) {
      errors.services = t("servicesRequired");
      errors.frontend = t("servicesRequired");
    }
    if (
      values.service_provider_type_id == 1 ||
      values.service_provider_type_id == 2
    ) {
      if (!values.comercial_register) {
        errors.comercial_register = "commercial register required";
      }
      if (!values.licencing_authority) {
        errors.licencing_authority = "licensing authority required";
      } else if (/(?=.*?[0-9])/.test(values.licencing_authority.trim())) {
        errors.licencing_authority = t("licensingAuthorityInvalid");
        errors.frontend = t("licensingAuthorityInvalid");
      }
      if (!values.licensing_number) {
        errors.licensing_number = "licensing number required";
      }
      if (!values.licencing_expired) {
        errors.licencing_expired = "licensing expired required";
      }
    } else if (values.service_provider_type_id == 3) {
      if (!values.id_number) {
        errors.id_number = "id required";
      } else if (!/^[0-9]*$/.test(values.id_number)) {
        errors.id_number = t("idNumberError");
        errors.frontend = t("idNumberError");
      } else if (values.id_number.length < 8 || values.id_number.length > 15) {
        errors.id_number = t("idNumberError");
        errors.frontend = t("idNumberError");
      }
      if (!values.gender) {
        errors.gender = "gender required";
      }
      if (!values.nationality) {
        errors.nationality = "nationality required";
      }
    }

    return errors;
  }
  const [values, setValues] = useState({
    name: "",
    email: "",
    mobile: "",
    logo: null,
    logoName: "",
    location: "",
    governorate_id: "",
    area: "",
    files: [],
    country_id: "",
    comercial_register: "",
    licencing_authority: "",
    licensing_number: "",
    licencing_expired: "",
    id_number: "",
    gender: "",
    nationality: "",
    services: [],
    service_provider_type_id: "",
    password: "",
    password_confirmation: "",
  });
  const genders = [
    { name: t("male"), id: "male" },
    { name: t("female"), id: "female" },
  ];

  const [error, setError] = useState({});
  const [countries, setCountries] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [cities, setCities] = useState([]);
  const [documents, setDocuments] = useState([]);
  const documentFiles = [];
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const handleChangeDate = (e) => {
    setValues({
      ...values,
      licencing_expired: e,
    });
  };
  const handleChangeMobile = (e) => {
    matchIsValidTel(e);
    // console.log(tst);
    setValues({
      ...values,
      mobile: e,
    });
  };
  const handleRemoveDocument = (i) => {
    setValues({
      ...values,
      files: values.files.slice(0, i).concat(values.files.slice(i + 1)),
    });
    setDocuments(documents.slice(0, i).concat(documents.slice(i + 1)));
  };
  const handleAddDocuments = (e) => {
    const file = e.target.files;
    const doc = [];
    for (let i = 0; i < file.length; i += 1) {
      documentFiles.push(e.target.files[i]);
      const url = {
        url: URL.createObjectURL(e.target.files[i]),
        type: e.target.files[i].type,
      };
      doc.push(url);
    }
    setDocuments(documents.concat(doc));
    setValues({
      ...values,
      files: values.files.concat(documentFiles),
    });
  };
  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    const { value } = e.target;
    setValues({
      ...values,
      logo: file,
      logoName: value,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there is no errors
      setLoader(true);
      validateServiceProvider({
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation,
      }).then((response) => {
        if (response.status === 200) {
          setSpValues(values);
          setStepOne(false);
          setStepTwo(true);
        } else if (response.status === 422) {
          setError({ frontend: response.data.message });
        }
        setLoader(false);
      });
    }
  };
  const [types, setTypes] = useState([]);
  const currentLanguageCode = Cookies.get("i18next");
  useEffect(() => {
    (async () => {
      const response = await getCountries();
      if (response.status === 200) {
        setCountries(response.data);
      } else {
        localStorage.removeItem("token");
        window.location.reload(false);
      }
    })();
    (async () => {
      const response = await getSpTypes();
      if (response.status === 200) {
        setTypes(response.data.service_provider_types);
      } else {
        localStorage.removeItem("token");
        window.location.reload(false);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (values.country_id.length > 0) {
        const response = await getGovernorates(values.country_id);
        if (response.status === 200) {
          setGovernorates(response.data);
        } else {
          localStorage.removeItem("token");
          window.location.reload(false);
        }
      }
    })();
  }, [values.country_id]);
  useEffect(() => {
    if (values.governorate_id.length > 0) {
      (async () => {
        const response = await getCities(values.governorate_id);
        if (response.status === 200) {
          setCities(response.data);
        } else {
          localStorage.removeItem("token");
          window.location.reload(false);
        }
      })();
    }
  }, [values.governorate_id]);
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles["form-container"]}>
        <div className={styles.fields}>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-name"
              label={t("name")}
              name="name"
              required
              value={values.name}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.name && "secondary"}
            />
            {values.name && (
              <HighlightOffIcon
                className={styles.cancel}
                onClick={() => {
                  setValues({
                    ...values,
                    name: "",
                  });
                }}
              />
            )}
          </div>
          {/* </div>
        <div className={styles.fields}> */}
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-email"
              label={t("email")}
              name="email"
              required
              value={values.email}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.email && "secondary"}
            />
            {values.email && (
              <HighlightOffIcon
                className={styles.cancel}
                onClick={() => {
                  setValues({
                    ...values,
                    email: "",
                  });
                }}
              />
            )}
          </div>
          <FormControl variant="outlined" className={styles["field-container"]}>
            <InputLabel
              required
              style={{ top: 20, left: 25 }}
              id="country-label"
              color={error.specialistName && "secondary"}
            >
              {t("country")}
            </InputLabel>
            <Select
              className={styles["text-field"]}
              labelId="country-label"
              native
              required
              label={t("country")}
              value={values.country_id}
              onChange={(e) => {
                setValues({
                  ...values,
                  country_id: e.target.value,
                  mobile: `+${
                    countries.filter(
                      (country) => country.id == e.target.value
                    )[0].phone_code
                  }`,
                });
              }}
              color={error.country_id && "secondary"}
              inputProps={{
                name: "country_id",
                id: "country_id",
              }}
            >
              <option aria-label="None" value="" />
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {currentLanguageCode === "en"
                    ? country.name
                    : country.arabic_name}
                </option>
              ))}
            </Select>
          </FormControl>
          {values.country_id && (
            <FormControl
              variant="outlined"
              className={styles["field-container"]}
            >
              <InputLabel
                required
                style={{ top: 20, left: 25 }}
                id="governorate-label"
                color={error.specialistName && "secondary"}
              >
                {t("governorate")}
              </InputLabel>
              <Select
                className={styles["text-field"]}
                labelId="governorate-label"
                native
                required
                label={t("governorate")}
                value={values.governorate_id}
                onChange={handleChange}
                color={error.governorate_id && "secondary"}
                inputProps={{
                  name: "governorate_id",
                  id: "governorate_id",
                }}
              >
                <option aria-label="None" value="" />
                {governorates.map((country) => (
                  <option key={country.id} value={country.id}>
                    {currentLanguageCode === "en"
                      ? country.name
                      : country.arabic_name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {values.governorate_id &&
            governorates.filter(
              (governorate) => governorate.id == values.governorate_id
            ).length > 0 && (
              <FormControl
                required
                variant="outlined"
                className={styles["field-container"]}
              >
                <InputLabel
                  style={{ top: 20, left: 25 }}
                  id="governorate-label"
                  color={error.governorate_id && "secondary"}
                >
                  {t("area")}
                </InputLabel>
                <Select
                  className={styles["text-field"]}
                  labelId="governorate-label"
                  native
                  label={t("area")}
                  value={values.area}
                  required
                  onChange={handleChange}
                  color={error.area && "secondary"}
                  inputProps={{
                    name: "area",
                    id: "area",
                  }}
                >
                  <option aria-label="None" value="" />
                  {cities.map((country) => (
                    <option key={country.id} value={country.id}>
                      {currentLanguageCode === "en"
                        ? country.name
                        : country.arabic_name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          <div className={styles["field-container"]}>
            <MuiTelInput
              className={styles["text-field"]}
              onChange={handleChangeMobile}
              label={t("mobile")}
              name="mobile"
              color={error.mobile && "secondary"}
              value={values.mobile}
            />
          </div>
          <FormControl variant="outlined" className={styles["field-container"]}>
            <InputLabel
              style={{ top: 20, left: 25 }}
              id="country-label"
              color={error.specialistName && "secondary"}
            >
              {t("Service Provider Type")}
            </InputLabel>
            <Select
              className={styles["text-field"]}
              labelId="country-label"
              required
              native
              label={t("Service Provider Type")}
              value={values.service_provider_type_id}
              onChange={handleChange}
              color={error.service_provider_type_id && "secondary"}
              inputProps={{
                name: "service_provider_type_id",
                id: "service_provider_type_id",
              }}
            >
              <option aria-label="None" value="" />
              {types.map((type) => (
                <option key={type.id} value={type.id}>
                  {currentLanguageCode === "en" ? type.name : type.arabic_name}
                </option>
              ))}
            </Select>
          </FormControl>
          {values.service_provider_type_id == 3 && (
            <div className={styles["field-container"]}>
              <TextField
                className={styles["text-field"]}
                id="sp-id-number"
                label={t("Id Number")}
                required
                name="id_number"
                helperText={
                  currentLanguageCode === "en"
                    ? "Enter 8-15 number"
                    : "أدخل 8-15 رقم"
                }
                value={values.id_number}
                color={error.id_number && "secondary"}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />
              {values.id_number && (
                <HighlightOffIcon
                  className={styles.cancel}
                  onClick={() => {
                    setValues({
                      ...values,
                      id_number: "",
                    });
                  }}
                />
              )}
            </div>
          )}
          {values.service_provider_type_id == 3 && (
            <FormControl
              variant="outlined"
              className={styles["field-container"]}
            >
              <InputLabel
                required
                style={{ top: 20, left: 25 }}
                id="country-label"
                color={error.specialistName && "secondary"}
              >
                {t("nationality")}
              </InputLabel>
              <Select
                native
                className={styles["text-field"]}
                id="sp-nationality"
                label={t("nationality")}
                required
                name="nationality"
                value={values.nationality}
                onChange={handleChange}
                color={error.nationality && "secondary"}
                inputProps={{
                  name: "nationality",
                  id: "nationality",
                }}
              >
                <option aria-label="None" value="" />
                {countries.map((country) => (
                  <option
                    key={
                      currentLanguageCode === "en"
                        ? country.name
                        : country.arabic_name
                    }
                    value={country.id}
                  >
                    {currentLanguageCode === "en"
                      ? country.name
                      : country.arabic_name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {values.service_provider_type_id == 3 && (
            <FormControl
              variant="outlined"
              className={styles["field-container"]}
            >
              <InputLabel
                required
                style={{ top: 20, left: 25 }}
                id="gender-label"
                color={error.specialistName && "secondary"}
              >
                {t("gender")}
              </InputLabel>
              <Select
                className={styles["text-field"]}
                labelId="gender-label"
                native
                label={t("gender")}
                value={values.gender}
                required
                onChange={handleChange}
                color={error.gender && "secondary"}
                inputProps={{
                  name: "gender",
                  id: "gender",
                }}
              >
                <option aria-label="None" value="" />
                {genders.map((gender) => (
                  <option key={gender.id} value={gender.id}>
                    {gender.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {(values.service_provider_type_id == 1 ||
            values.service_provider_type_id == 2) && (
            <div className={styles["field-container"]}>
              <TextField
                className={styles["text-field"]}
                id="sp-comercial-register"
                label={t("Comercial Register")}
                name="comercial_register"
                required
                value={values.comercial_register}
                color={error.comercial_register && "secondary"}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />
              {values.comercial_register && (
                <HighlightOffIcon
                  className={styles.cancel}
                  onClick={() => {
                    setValues({
                      ...values,
                      comercial_register: "",
                    });
                  }}
                />
              )}
            </div>
          )}
          {(values.service_provider_type_id == 1 ||
            values.service_provider_type_id == 2) && (
            <div className={styles["field-container"]}>
              <TextField
                className={styles["text-field"]}
                id="sp-licensing-auth"
                label={t("Licensing Authority")}
                required
                name="licencing_authority"
                value={values.licencing_authority}
                onChange={handleChange}
                color={error.licencing_authority && "secondary"}
                type="text"
                variant="outlined"
              />
              {values.licencing_authority && (
                <HighlightOffIcon
                  className={styles.cancel}
                  onClick={() => {
                    setValues({
                      ...values,
                      licencing_authority: "",
                    });
                  }}
                />
              )}
            </div>
          )}
          {(values.service_provider_type_id == 1 ||
            values.service_provider_type_id == 2) && (
            <div className={styles["field-container"]}>
              <TextField
                className={styles["text-field"]}
                id="sp-licensing-number"
                label={t("Licensing Number")}
                name="licensing_number"
                required
                value={values.licensing_number}
                color={error.licensing_number && "secondary"}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />
              {values.licensing_number && (
                <HighlightOffIcon
                  className={styles.cancel}
                  onClick={() => {
                    setValues({
                      ...values,
                      licensing_number: "",
                    });
                  }}
                />
              )}
            </div>
          )}
          {(values.service_provider_type_id == 1 ||
            values.service_provider_type_id == 2) && (
            <div className={styles["field-container"]}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={styles["text-field"]}
                  label={t("Licensing Expired")}
                  name="licencing_expired"
                  value={values.licencing_expired}
                  disablePast
                  onChange={handleChangeDate}
                  renderInput={(params) => (
                    <TextField onKeyDown={onKeyDown} disabled {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
          )}
          {/* </div>
        <div className={styles.fields}> */}
          <FormControl className={styles["field-container"]} variant="outlined">
            <TextField
              id="logo-text"
              label={t("logo")}
              variant="outlined"
              name="logoName"
              required
              value={values.logoName}
              disabled
              className={styles.logo}
              color={error.logoName && "secondary"}
            />
            <InputBase
              className={styles["logo-field"]}
              id="sp-logo"
              name="logo"
              type="file"
              required
              onChange={handleLogoUpload}
              variant="outlined"
              color={error.name && "secondary"}
              style={{ opacity: 0 }}
            />
            <UploadIcon className={styles["upload-icon"]} />
          </FormControl>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-location"
              label={t("location")}
              name="location"
              required
              value={values.location}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.location && "secondary"}
            />
            <PlaceIcon className={styles.cancel} />
          </div>
          {/* </div>
        <div className={styles.fields}> */}
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-password"
              label={t("password")}
              name="password"
              required
              value={values.password}
              onChange={handleChange}
              type="password"
              variant="outlined"
              color={error.password && "secondary"}
            />
          </div>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sp-password_confirmation"
              label={t("confirmPassword")}
              required
              name="password_confirmation"
              value={values.password_confirmation}
              onChange={handleChange}
              type="password"
              variant="outlined"
              color={error.password_confirmation && "secondary"}
            />
          </div>
        </div>
      </div>
      <div className={styles["services-table"]}>
        <TableDemo
          values={values}
          setValues={setValues}
          serv={[]}
          filter={values.service_provider_type_id}
        />
      </div>
      <h6 className={styles["documents-title"]}>
        {t("documents&certificates")}
      </h6>
      <div className={styles["images-container"]}>
        {documents.map((doc, index) =>
          doc.type === "application/pdf" ? (
            <div className={styles["image-container"]}>
              <CloseIcon
                className={styles["image-delete-icon"]}
                onClick={() => {
                  handleRemoveDocument(index);
                }}
              />
              <a href={doc.url} download>
                <img
                  width={222}
                  height={125}
                  style={{ margin: 10 }}
                  src="/pdf perview.png"
                  alt="certificate"
                />
              </a>
            </div>
          ) : (
            <div className={styles["image-container"]}>
              <CloseIcon
                className={styles["image-delete-icon"]}
                onClick={() => {
                  handleRemoveDocument(index);
                }}
              />
              <a href={doc.url} download>
                <img
                  width={222}
                  height={125}
                  style={{ margin: 10 }}
                  src={doc.url}
                  alt="certificate"
                />
              </a>
            </div>
          )
        )}
        <div className={styles["upload-documents"]}>
          <div className={styles["plus-image"]}>
            <AddIcon style={{ width: 56, height: 56 }} />
          </div>
          <input
            type="file"
            onChange={handleAddDocuments}
            className={styles["input-document"]}
            multiple
          />
        </div>
      </div>
      {error.frontend && <p className={styles.error}>{error.frontend}</p>}
      <div className={styles["button-container"]}>
        <Button
          disabled={loader}
          type="submit"
          variant="contained"
          className={styles["next-button"]}
        >
          {loader ? t("wait") : t("next")}
        </Button>
      </div>
    </form>
  );
}

export default StepOne;

StepOne.propTypes = {
  setStepOne: propTypes.func.isRequired,
  setStepTwo: propTypes.func.isRequired,
  setSpValues: propTypes.func.isRequired,
};
