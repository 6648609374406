import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import styles from './EditActivitiesBank.module.css';
import StepOne from './StepOne';

const modal = {
  display: 'flex',
  alignItems: 'right',
  justifyContent: 'right',
};
const paper = {
  position: 'relative',
  border: 'none',
  maxWidth: '350px',
  minWidth: '295px',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ViewActivitiesBankModal({
  setViewActivitiesBankModal,
  viewActivitiesBankModal, activitiesBankToViewed, activities,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setOpen(viewActivitiesBankModal);
    setData(activities.find((act) => activitiesBankToViewed === act.id));
  }, [viewActivitiesBankModal]);

  const handleClose = () => {
    setViewActivitiesBankModal(false);
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        sx={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={paper}>
            <p id="newService-modal-description" className={styles.subtitle}>{`${t('activity')} ${activitiesBankToViewed}`}</p>
            <h2 id="newService-modal-title" className={styles.title}>{t('viewActivity')}</h2>
            <CloseIcon onClick={handleClose} className={styles.close} />
            <StepOne activities={data} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
ViewActivitiesBankModal.propTypes = {
  setViewActivitiesBankModal: PropTypes.func.isRequired,
  viewActivitiesBankModal: PropTypes.bool.isRequired,
  activitiesBankToViewed: PropTypes.number.isRequired,
  activities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    arabic_name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    arabic_description: PropTypes.string.isRequired,
    skill: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    is_active: PropTypes.number.isRequired,
  })).isRequired,
};
