/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Cookies from 'js-cookie';
import styles from './Roles.module.css';
import EditRoleModal from '../../Roles/EditRole/EditRole';
import ViewRolesModal from '../../Roles/ViewRole/ViewRole';

import { getRoles } from '../../../Services/adminServices';
import RolesModal from '../../Roles/RolesModal';
import RolesTable from '../../Roles/RolesTable';
import Excelexport from '../../ActivityBank/Excelexport';

function Roles() {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [newRoleModal, setNewRoleModal] = useState(false);
  const [editRoleModal, setEditRoleModal] = useState(false);
  const [viewRoleModal, setViewRoleModal] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(-1);
  const [roleToViewed, setRoleToViewed] = useState(-1);
  const [roles, setRoles] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const currentLanguageCode = Cookies.get('i18next');
  useEffect(() => {
    (async () => {
      const response = await getRoles();
      if (response.status === 200) {
        setRoles(response.data.roles);
        setFiltered(response.data.roles);
      } else {
        localStorage.removeItem('token');
        window.location.reload(false);
      }
    })();
  }, [updateTable, currentLanguageCode]);
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setFiltered(
      roles.filter((act) => (act.id == value
      || act.name.toLowerCase().includes(value)
      || (act.arabic_name && act.arabic_name.toLowerCase().includes(value)))),
    );
    if (value <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(roles);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // search
    setFiltered(
      roles.filter((act) => (act.id == searchValue
      || act.name.toLowerCase().includes(searchValue)
      || (act.arabic_name && act.arabic_name.toLowerCase().includes(searchValue)))),
    );
    if (searchValue <= 0) {
      // setUpdateTable(!updateTable);
      setFiltered(roles);
    }
  };
  return (
    <>
      {newRoleModal && (
      <RolesModal
        newRoleModal={newRoleModal}
        setNewRoleModal={setNewRoleModal}
        setUpdateTable={setUpdateTable}
        updateTable={updateTable}
      />
      )}
      {editRoleModal && (
        <EditRoleModal
          setEditRoleModal={setEditRoleModal}
          editRoleModal={editRoleModal}
          roleToEdit={roleToEdit}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          roles={roles}
        />
      )}
      {viewRoleModal && (
        <ViewRolesModal
          setViewRoleModal={setViewRoleModal}
          viewRoleModal={viewRoleModal}
          roleToViewed={roleToViewed}
          roles={roles}
        />
      )}

      <div className={styles['sp-container']}>
        <div className={styles.container}>
          <form onSubmit={handleSearch} className={styles.header}>
            <SearchIcon style={{ marginRight: '20px' }} />
            <input
              className={styles['search-bar']}
              placeholder={t('searchForSomething')}
              onChange={handleChange}
            />
            <input type="submit" hidden />
          </form>
          <div className={styles.export}>
            <div style={{ width: '50px' }}>
              <Excelexport excelData={roles} fileName="Roles" />
            </div>
            <Button onClick={() => { setNewRoleModal(true); }} className={styles['add-button']} variant="contained">
              <AddIcon />
              <span className={styles['button-text']}>{t('createRole')}</span>
            </Button>
          </div>
        </div>
        <RolesTable
          setEditPackagesModal={setEditRoleModal}
          setRoleToEdit={setRoleToEdit}
          setRoleToViewed={setRoleToViewed}
          setViewRoleModal={setViewRoleModal}
          packages={filtered}
        />
      </div>
    </>
  );
}

export default Roles;
