import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Cookies from "js-cookie";
import styles from "./StepOne.module.css";
import { editSkill, getCategories } from "../../../Services/adminServices";

function StepOne({
  setStepOne,
  setStepThree,
  handleUpdateTable,
  specialityToEdit,
  data,
}) {
  const { t } = useTranslation();
  function validateStepOne(values) {
    const errors = {};
    if (!values.sort_order) {
      errors.frontend = "order required";
    } else if (values.sort_order <= 0) {
      errors.frontend = "order not valid";
    }
    if (!values.arabic_name.trim()) {
      errors.frontend = "order required";
    } else if (/^[A-Za-z]+/.test(values.arabic_name.trim())) {
      errors.frontend = t("validName");
    }
    if (!values.name.trim()) {
      errors.frontend = "name required";
    } else if (!/^[A-Za-z]+/.test(values.name.trim())) {
      errors.frontend = t("validName");
    }
    if (!values.category_id) {
      errors.frontend = "Role Required";
    }

    return errors;
  }
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState({
    id: specialityToEdit,
    name: data.english_name,
    arabic_name: data.arabic_name,
    sort_order: data.sort_order,
    category_id: data.category_id,
  });
  const [error, setError] = useState({});
  const [categories, setCategories] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const currentLanguageCode = Cookies.get("i18next");
  useEffect(() => {
    (async () => {
      const response = await getCategories();
      if (response.status === 200) {
        setCategories(response.data.categories);
      } else {
        localStorage.removeItem("token");
        window.location.reload(false);
      }
    })();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there is no errors
      setLoader(true);
      editSkill(values).then((response) => {
        if (response.status === 200) {
          handleUpdateTable();
          setStepOne(false);
          setStepThree(true);
        } else if (response.status === 422) {
          setError({
            ...error,
            frontend: response.data.message,
          });
        }
        setLoader(false);
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles["form-container"]}>
        <div className={styles.fields}>
          <div className={styles["field-container"]}>
            <TextField
              multiline
              className={styles["text-field"]}
              id="name"
              label={t("nameEN")}
              name="name"
              required
              value={values.name}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.nameEN && "secondary"}
            />
          </div>

          <div className={styles["field-container"]}>
            <TextField
              multiline
              className={styles["text-field"]}
              id="arabic_name"
              label={t("nameAR")}
              name="arabic_name"
              required
              value={values.arabic_name}
              onChange={handleChange}
              type="text"
              variant="outlined"
              color={error.nameAR && "secondary"}
            />
          </div>
          <div className={styles["field-container"]}>
            <TextField
              className={styles["text-field"]}
              id="sort_order"
              label={t("order")}
              required
              name="sort_order"
              value={values.sort_order}
              onChange={handleChange}
              type="number"
              variant="outlined"
              color={error.order && "secondary"}
            />
            {/* {values.order
        && (
        <HighlightOffIcon
          className={styles.cancel}
          onClick={() => {
            setValues({
              ...values,
              order: '',
            });
          }}
        />
        )} */}
          </div>
          <FormControl variant="outlined" className={styles["field-container"]}>
            <InputLabel
              style={{ top: 20, left: 25 }}
              id="category-label"
              color={error.speciality && "secondary"}
            >
              {t("category")}
            </InputLabel>
            <Select
              className={styles["text-field"]}
              labelId="category-label"
              native
              label={t("category")}
              value={values.category_id}
              onChange={handleChange}
              required
              color={error.category && "secondary"}
              inputProps={{
                name: "category_id",
                id: "category_id",
              }}
            >
              <option aria-label="None" value="" />
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {currentLanguageCode === "ar"
                    ? category.arabic_name
                    : category.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {error.frontend && <p className={styles.error}>{error.frontend}</p>}
      <div className={styles["button-container"]}>
        <Button
          disabled={loader}
          type="submit"
          variant="contained"
          className={styles["next-button"]}
        >
          {loader ? t("wait") : t("confirm")}
        </Button>
      </div>
    </form>
  );
}

export default StepOne;

StepOne.propTypes = {
  setStepOne: propTypes.func.isRequired,
  setStepThree: propTypes.func.isRequired,
  handleUpdateTable: propTypes.func.isRequired,
  specialityToEdit: propTypes.number.isRequired,
  data: propTypes.shape({
    english_name: propTypes.string.isRequired,
    arabic_name: propTypes.string.isRequired,
    sort_order: propTypes.number.isRequired,
    category_id: propTypes.number.isRequired,
  }).isRequired,
};
