import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../Services/adminServices';
// import { changePassword } from '../../services/authServices';
// import validateResetPassword from './validateResetPassword';

const usePasswordForm = (validateResetPassword, token, email, setLoader) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    password1: '',
    password2: '',
  });
  const [errors1, setErrors1] = useState({});
  const [errors2, setErrors2] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors1(validateResetPassword(values).errors1);
    setErrors2(validateResetPassword(values).errors2);
    if (Object.keys(validateResetPassword(values).errors1).length === 0
    && Object.keys(validateResetPassword(values).errors2).length === 0) {
      // There is no errors
      setLoader(true);
      resetPassword({
        token,
        email,
        password: values.password1,
        password_confirmation: values.password2,
      }).then((response) => {
        if (response.status === 200) {
          sessionStorage.clear();
          navigate('/login');
        } else if (response.status === 422) {
          setLoader(false);
          setErrors2({
            ...errors2,
            password2: response.data.message,
          });
        } else {
          navigate('/');
        }
      });
    }
  };

  return {
    handleChange, values, handleSubmit, errors1, errors2,
  };
};

export default usePasswordForm;
