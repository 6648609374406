/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import Cookies from 'js-cookie';
import styles from './Students.module.css';
import StudentsTable from '../../Students/StudentsTable';
import ViewStudentsModal from '../../Students/ViewStudents/ViewStudents';
import { getStudents } from '../../../Services/adminServices';
import Excelexport from '../../ActivityBank/Excelexport';

function Students() {
  const { t } = useTranslation();
  const [viewStudentsModal, setViewStudentsModal] = useState(false);
  const [studentsToViewed, setStudentsToViewed] = useState(-1);
  const [students, setStudents] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const currentLanguageCode = Cookies.get('i18next');
  useEffect(() => {
    (async () => {
      const response = await getStudents();
      if (response.status === 200) {
        setStudents(response.data.students);
        setFilteredUsers(response.data.students);
      } else {
        // localStorage.removeItem('token');
        // window.location.reload(false);
      }
    })();
  }, [updateTable, currentLanguageCode]);
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setFilteredUsers(
      students.filter(
        (user) =>
          user.name.toLowerCase().includes(value) ||
          user.user_id == value ||
          user.birthdate.toLowerCase().includes(value) ||
          user.caregiver.toLowerCase().includes(value) ||
          user.relationship.toLowerCase().includes(value) ||
          user.age == value ||
          user.gender.toLowerCase().includes(value),
      ),
    );
    if (value <= 0) {
      // setUpdateTable(!updateTable);
      setFilteredUsers(students);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // search
    setFilteredUsers(
      students.filter(
        (user) =>
          user.name.toLowerCase().includes(searchValue) ||
          user.user_id == searchValue ||
          user.birthdate.toLowerCase().includes(searchValue) ||
          user.caregiver.toLowerCase().includes(searchValue) ||
          user.relationship.toLowerCase().includes(searchValue) ||
          user.age == searchValue ||
          user.gender.toLowerCase().includes(searchValue),
      ),
    );
    if (searchValue.length === 0) {
      // setUpdateTable(!updateTable);
      setFilteredUsers(students);
    }
  };

  return (
    <>
      {viewStudentsModal && (
        <ViewStudentsModal
          setViewStudentsModal={setViewStudentsModal}
          viewStudentsModal={viewStudentsModal}
          studentsToViewed={studentsToViewed}
          students={students}
        />
      )}
      <div className={styles.container}>
        <div className={styles.container2}>
          <form onSubmit={handleSearch} className={styles.header}>
            <SearchIcon style={{ marginRight: '20px' }} />
            <input
              className={styles['search-bar']}
              placeholder={t('searchForSomething')}
              onChange={handleChange}
            />
            <input type="submit" hidden />
          </form>
          <Excelexport excelData={students} fileName="Students" />
        </div>
        <StudentsTable
          setStudentsToViewed={setStudentsToViewed}
          setViewStudentsModal={setViewStudentsModal}
          students={filteredUsers}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      </div>
    </>
  );
}

export default Students;
