import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import { deleteSkill } from '../../Services/adminServices';
import Spinner from '../../Loader';

const StyledTableCell = styled(TableCell)(() => ({
  border: 'none',
  [`&.${tableCellClasses.head}`]: {
    color: '#4F4F4F',
    backgroundColor: '#ffeecc',
    height: '17px',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
  },
}));

const root = {
  width: '97%',
};

const icons = {
  display: 'flex',
  justifyContent: 'space-between',
};

export default function SpecialityTable({
  setEditSpecialityModal, setSpecialityToEdit, specialities, setUpdateTable,
  updateTable,
}) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const columns = [
    {
      id: 'id',
      label: t('id'),
      minWidth: 16,
    },
    {
      id: 'sort_order',
      label: t('order'),
      minWidth: 81,
    },
    {
      id: 'english_name',
      label: t('specialityEN'),
      minWidth: 180,
    },
    {
      id: 'arabic_name',
      label: t('specialityAR'),
      minWidth: 60,
    },
  ];
  const handleEdit = (id) => {
    setSpecialityToEdit(id);
    setEditSpecialityModal(true);
  };
  const handleDelete = (id) => {
    setLoader(true);
    deleteSkill(id).then((response) => {
      if (response.status === 200) {
        setUpdateTable(!updateTable);
      }
      setLoader(false);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={root}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: column.backgroundColor,
                    border: column.border,
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                align="center"
              >
                {t('operation')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {specialities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <StyledTableCell key={column.id} align={column.align}>
                      {column.format && typeof value === 'number' ? column.format(value) : value}
                    </StyledTableCell>
                  );
                })}
                {columns.filter((column) => column.id === 'id').map((column) => (
                  <StyledTableCell key={column.id}>
                    {loader ? <Spinner />
                      : (
                        <div style={icons}>
                          <EditIcon onClick={() => { handleEdit(row.id); }} />
                          <DeleteIcon onClick={() => { handleDelete(row.id); }} />
                        </div>
                      )}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={specialities.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
SpecialityTable.propTypes = {
  setEditSpecialityModal: PropTypes.func.isRequired,
  setSpecialityToEdit: PropTypes.func.isRequired,
  setUpdateTable: PropTypes.func.isRequired,
  updateTable: PropTypes.bool.isRequired,
  specialities: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    english_name: PropTypes.string.isRequired,
    arabic_name: PropTypes.string.isRequired,
    sort_order: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    is_active: PropTypes.number.isRequired,
  })).isRequired,

};
