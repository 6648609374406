/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
// import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import CreateIcon from '@mui/icons-material/Create';
import {
  Box, Button, Select, Table,
  TableBody, TableHead, TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// import { getServices } from '../../../Services/adminServices';

// Creating styles
const StyledTable = styled(Table)(() => ({
  minWidth: 295,
  borderBottom: 'unset',
}));
const StyledTableCell = styled(TableCell)(() => ({
  // border: 'none',
  [`&.${tableCellClasses.body}`]: {
    border: 'none',

  },
  [`&.${tableCellClasses.head}`]: {
    height: '17px',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
  },
}));
const select = {
  height: 30,
};
const color = {
  color: 'black',
};

function PermissionsTable({ values, setValues, permissions }) {
  const { t } = useTranslation();
  // Creating style object
  // const currentLanguageCode = Cookies.get('i18next');

  // Defining a state named rows
  // which we can update by calling on setRows function
  const [rows, setRows] = useState(values.permissions);
  // Initial states
  const [isEdit, setEdit] = React.useState(false);
  const [disable, setDisable] = React.useState(true);
  const [showConfirm, setShowConfirm] = React.useState(false);
  useEffect(() => {
    setValues({
      ...values,
      permissions: rows.map((perm) => (+perm.id)),
    });
  }, []);
  // Function For adding new row object
  const handleAdd = () => {
    setRows([
      ...rows,
      {
        id: '',
      },
    ]);
    setEdit(true);
  };

  // Function to handle edit
  const handleEdit = () => {
    // If edit mode is true setEdit will
    // set it to false and vice versa
    setEdit(!isEdit);
  };

  // Function to handle save
  const handleSave = () => {
    setEdit(!isEdit);
    setRows(rows);
    setValues({
      ...values,
      permissions: rows.map((perm) => (+perm.id)),
    });
    setDisable(true);
  };

  // The handleInputChange handler can be set up to handle
  // many different inputs in the form, listen for changes
  // to input elements and record their values in state
  const handleInputChange = (e, index) => {
    setDisable(false);
    const { name, value } = e.target;

    const list = [...rows];
    list[index][name] = value;
    let duplicate = false;
    for (let i = 0; i < rows.length; i += 1) {
      if (rows[i].id == list[index].id
          && index !== i) {
        duplicate = true;
      }
    }
    if (duplicate === true) {
      list.pop();
    }
    setRows(list);
  };

  // Showing delete confirmation to users
  const handleConfirm = () => {
    setShowConfirm(true);
  };

  // Handle the case of delete confirmation where
  // user click yes delete a specific row of id:i
  const handleRemoveClick = (i) => {
    const list = [...rows];
    list.splice(i, 1);
    setRows(list);
    setValues({
      ...values,
      permissions: rows.map((perm) => (+perm.id)),
    });
    setShowConfirm(false);
  };

  // Handle the case of delete confirmation
  // where user click no
  const handleNo = () => {
    setShowConfirm(false);
  };

  return (
    <TableBody>
      <Box margin={1}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {isEdit ? (
              <div>
                <Button sx={color} onClick={handleAdd}>
                  <AddBoxIcon onClick={handleAdd} />
                  {t('add')}
                </Button>
                {rows.length !== 0 && (
                  disable ? (
                    <Button sx={color} disabled align="right" onClick={handleSave}>
                      <DoneIcon />
                      {t('save')}
                    </Button>
                  ) : (
                    <Button sx={color} align="right" onClick={handleSave}>
                      <DoneIcon />
                      {t('save')}
                    </Button>
                  )
                )}
              </div>
            ) : (
              <div>
                <Button sx={color} onClick={handleAdd}>
                  <AddBoxIcon onClick={handleAdd} />
                  {t('add')}
                </Button>
                <Button sx={color} align="right" onClick={handleEdit}>
                  <CreateIcon />
                  {t('edit')}
                </Button>
              </div>
            )}
          </div>
        </div>
        <TableRow align="center"> </TableRow>

        <StyledTable
          size="larg"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>{t('permissions')}</StyledTableCell>
              <StyledTableCell align="center"> </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
            //   <div>
              <TableRow>
                {isEdit ? (
                  <StyledTableCell padding="none">
                    <Select
                      sx={select}
                      labelId="country-label"
                      native
                      variant="outlined"
                      value={row.id}
                      onChange={(e) => handleInputChange(e, i)}
                      inputProps={{
                        name: 'id',
                        id: 'id',
                      }}
                    >
                      <option aria-label="None" value="" />
                      {permissions.map((service) => (
                        <option
                          key={service.id}
                          value={service.id}
                        >
                          {service.name}

                        </option>

                      ))}

                    </Select>
                  </StyledTableCell>
                ) : (
                  <>
                    <TableCell component="th" scope="row">
                      {permissions.filter((ser) => row.id == ser.id)
                        .map((s) => (s.name))}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                    />
                  </>
                )}
                {isEdit ? (
                  <Button sx={color} className="mr10" onClick={handleConfirm}>
                    <ClearIcon />
                  </Button>
                ) : (
                  <Button sx={color} className="mr10" onClick={handleConfirm}>
                    <DeleteOutlineIcon />
                  </Button>
                )}
                {showConfirm && (
                  <div>
                    <Dialog
                      open={showConfirm}
                      onClose={handleNo}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {t('Confirm Delete')}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {t('Are you sure to delete')}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => handleRemoveClick(i)}
                          color="primary"
                          autoFocus
                        >
                          {t('yes')}
                        </Button>
                        <Button
                          onClick={handleNo}
                          color="primary"
                          autoFocus
                        >
                          {t('no')}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                )}
              </TableRow>
            //   </div>
            ))}
          </TableBody>
        </StyledTable>
      </Box>
    </TableBody>
  );
}

export default PermissionsTable;
PermissionsTable.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  }).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
  setValues: PropTypes.func.isRequired,
};
