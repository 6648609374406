import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import stylesEn from "./AdminsModal.module.css";
import stylesAr from "./AdminsModalAr.module.css";
import StepOne from "./StepOne";
import StepThree from "./StepThree";

export default function CouponsModal({
  setNewCouponsModal,
  newCouponsModal,
  couponToEdit,
  setCouponToEdit,
}) {
  const { t } = useTranslation();
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const styles = currentLanguageCode === "en" ? stylesEn : stylesAr;

  const [open, setOpen] = useState(false);
  const [stepOne, setStepOne] = useState(true);
  const [stepThree, setStepThree] = useState(false);

  useEffect(() => {
    setOpen(newCouponsModal);
  }, [newCouponsModal]);

  const handleClose = () => {
    setNewCouponsModal(false);
    setOpen(false);
    setStepThree(false);
    setStepOne(true);
    setCouponToEdit(-1);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        maxWidth={false}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          textAlign={currentLanguageCode === "en" ? "start" : "end"}
          id="scroll-dialog-title"
        >
          {stepOne ? t("createCoupon") : t("editCoupon")}
        </DialogTitle>
        <DialogContent dividers>
          {stepOne && (
            <CloseIcon onClick={handleClose} className={styles.close} />
          )}
          {stepOne ? (
            <StepOne
              setStepOne={setStepOne}
              setStepThree={setStepThree}
              couponToEdit={couponToEdit}
            />
          ) : (
            stepThree && <StepThree handleClose={handleClose} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
