import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button, TextField,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from './StepOne.module.css';
import { createEvaluation } from '../../Services/adminServices';

function StepOne({ setStepOne, setStepThree, handleUpdateTable }) {
  const { t } = useTranslation();
  function validateStepOne(values) {
    const errors = {};

    if (!values.arabic_description.trim()) {
      errors.questionAR = 'description required';
      errors.frontend = 'description required';
    } else if (/^[A-Za-z]+/.test(values.arabic_description.trim())) {
      errors.descriptionAR = t('validText');
      errors.frontend = t('validText');
    }
    if (!values.description.trim()) {
      errors.questionEN = 'description required';
      errors.frontend = 'description required';
    } else if (!/^[A-Za-z]+/.test(values.description.trim())) {
      errors.questionEN = 'Enter a valid text';
      errors.frontend = t('validText');
    }

    return errors;
  }
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState({
    is_active: 1,
    description: '',
    arabic_description: '',
  });
  const [error, setError] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validateStepOne(values));
    if (Object.keys(validateStepOne(values)).length === 0) {
      // there is no errors
      setLoader(true);
      createEvaluation(values).then((response) => {
        if (response.status === 200) {
          handleUpdateTable();
          setStepOne(false);
          setStepThree(true);
        } else if (response.status === 422) {
          setError({
            ...error,
            frontend: response.data.message,
          });
        }
        setLoader(false);
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles['form-container']}>
        <div className={styles.fields}>

          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="description"
              label={t('questionEN')}
              name="description"
              required
              value={values.description}
              onChange={handleChange}
              type="text"
              multiline
              variant="outlined"
              color={error.questionEN && 'secondary'}
            />
            {values.description && (
            <HighlightOffIcon
              className={styles.cancel}
              onClick={() => {
                setValues({
                  ...values,
                  description: '',
                });
              }}
            />
            )}
          </div>
          <div className={styles['field-container']}>
            <TextField
              className={styles['text-field']}
              id="arabic_description"
              label={t('questionAR')}
              name="arabic_description"
              required
              value={values.arabic_description}
              onChange={handleChange}
              type="text"
              multiline
              variant="outlined"
              color={error.questionAR && 'secondary'}
            />
            {values.arabic_description && (
            <HighlightOffIcon
              className={styles.cancel}
              onClick={() => {
                setValues({
                  ...values,
                  arabic_description: '',
                });
              }}
            />
            )}
          </div>

          {error.frontend && <p className={styles.error}>{error.frontend}</p>}
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button disabled={loader} type="submit" variant="contained" className={styles['next-button']}>
          {loader ? t('wait') : t('confirm')}
        </Button>
      </div>
    </form>
  );
}

export default StepOne;

StepOne.propTypes = {
  setStepOne: propTypes.func.isRequired,
  setStepThree: propTypes.func.isRequired,
  handleUpdateTable: propTypes.func.isRequired,
};
