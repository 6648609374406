/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import styles from "./ServiceProviders.module.css";
import ServiceProvidersTable from "../../ServiceProviders/SPTable";
import SPModal from "../../ServiceProviders/SPModal";
import EditSPModal from "../../ServiceProviders/EditServiceProvider";
import ViewSPModal from "../../ServiceProviders/ViewServiceProvider";

import { getServiceProviders } from "../../../Services/adminServices";
import Excelexport from "../../ActivityBank/Excelexport";

function ServiceProviders() {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [newServiceModal, setNewServiceModal] = useState(false);
  const [editServiceModal, setEditServiceModal] = useState(false);
  const [viewServiceModal, setViewServiceModal] = useState(false);
  const [spToEdit, setSpToEdit] = useState(-1);
  const [spToView, setSpToView] = useState(-1);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  // const currentLanguageCode = Cookies.get('i18next');
  useEffect(() => {
    (async () => {
      const response = await getServiceProviders();
      if (response.status === 200) {
        setUsers(response.data.service_providers);
        setFilteredUsers(response.data.service_providers);
      }
    })();
  }, [updateTable]);
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setFilteredUsers(
      users.filter(
        (user) =>
          user.name?.toLowerCase().includes(value) ||
          user.phone?.toLowerCase().includes(value) ||
          user.email?.toLowerCase().includes(value) ||
          user.service_provider_type?.toLowerCase().includes(value) ||
          user.governorate?.toLowerCase().includes(value) ||
          user.id == value
      )
    );
    if (value <= 0) {
      // setUpdateTable(!updateTable);
      setFilteredUsers(users);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // search
    setFilteredUsers(
      users.filter(
        (user) =>
          user.name.toLowerCase().includes(searchValue) ||
          user.phone?.toLowerCase().includes(searchValue) ||
          user.email?.toLowerCase().includes(searchValue) ||
          user.service_provider_type?.toLowerCase().includes(searchValue) ||
          user.governorate?.toLowerCase().includes(searchValue) ||
          user.id == searchValue
      )
    );
    if (searchValue <= 0) {
      // setUpdateTable(!updateTable);
      setFilteredUsers(users);
    }
  };
  return (
    <>
      {newServiceModal && (
        <SPModal
          newServiceModal={newServiceModal}
          setNewServiceModal={setNewServiceModal}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      )}
      {editServiceModal && (
        <EditSPModal
          setEditServiceModal={setEditServiceModal}
          editServiceModal={editServiceModal}
          spToEdit={spToEdit}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          users={users}
        />
      )}

      {viewServiceModal && (
        <ViewSPModal
          setViewServiceModal={setViewServiceModal}
          viewServiceModal={viewServiceModal}
          spToView={spToView}
          users={users}
        />
      )}
      <div className={styles["sp-container"]}>
        <div className={styles.container}>
          <form onSubmit={handleSearch} className={styles.header}>
            <SearchIcon style={{ marginRight: "20px" }} />
            <input
              className={styles["search-bar"]}
              placeholder={t("searchForSomething")}
              onChange={handleChange}
            />
            <input type="submit" hidden />
          </form>
          <div className={styles.export}>
            <div style={{ width: "50px" }}>
              <Excelexport excelData={users} fileName="Service Providers" />
            </div>
            <Button
              onClick={() => {
                setNewServiceModal(true);
              }}
              className={styles["add-button"]}
              variant="contained"
            >
              <AddIcon />
              <span className={styles["button-text"]}>{t("CreateSP")}</span>
            </Button>
          </div>
        </div>
        <ServiceProvidersTable
          setEditServiceModal={setEditServiceModal}
          setSpToEdit={setSpToEdit}
          setViewServiceModal={setViewServiceModal}
          setSpToView={setSpToView}
          users={filteredUsers}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
        />
      </div>
    </>
  );
}

export default ServiceProviders;
