/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import styles from "./Sidebar.module.css";

const SidebarLink = styled(Link)`
  display: flex;
  color: #001415;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 38px;
  text-decoration: none;
  font-size: 18px;
  &:hover {
    background: #f4efe7;
    color: #ffa800;
    cursor: pointer;
  }
  &:focus {
    background: #f4efe7;
    color: #ffa800;
  }
`;

const SidebarLabel = styled.span`
  display: flex;
  margin-left: 16px;
  width: fit-content;
  height: 24px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

const DropdownLink = styled(Link)`
  height: 30px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  text-decoration: none;
  color: #929a9a;
  font-size: 14px;
  &:hover {
    background: #f4efe7;
    color: #ffa800;
    cursor: pointer;
  }
  &:focus {
    background: #f4efe7;
    color: #ffa800;
  }
`;
const NotificationsNumber = styled.div`
  background: rgba(255, 168, 0, 0.27);
  border-radius: 50%;
  width: 14px;
  height: 14px;
  padding: 2px;
  margin-top: 5px;
  margin-left: 7px;

  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  /* or 145% */

  display: flex;
  justify-content: center;
  align-items: right;
  text-align: right;
  letter-spacing: 0.1px;

  /* Primary */

  color: #ffa800;
`;

function SubMenu({ item, notifications }) {
  const [subnav, setSubnav] = useState(false);
  const { t } = useTranslation();
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          <SidebarLabel>
            {t(item.title)}
            {item.title === "notifications" && (
              <NotificationsNumber>
                <p className={styles["notification-container"]}>
                  {notifications}
                </p>
              </NotificationsNumber>
            )}
          </SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((ite) => (
          <DropdownLink to={ite.path} key={ite.id}>
            <SidebarLabel>{t(ite.title)}</SidebarLabel>
          </DropdownLink>
        ))}
    </>
  );
}

export default SubMenu;
