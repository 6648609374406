import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import styles from './SPModal.module.css';
import StepOne from './EditServiceProvider/EditServiceProvider';
import StepTwo from './EditServiceProvider/EditPackage';
import StepThree from './EditServiceProvider/StepThree';

const modal = {
  display: 'flex',
  alignItems: 'right',
  justifyContent: 'right',
};
const paper = {
  position: 'relative',
  border: 'none',
  maxWidth: '1000px',
  minWidth: '295px',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function EditSPModal({
  setEditServiceModal, editServiceModal, spToEdit, updateTable, setUpdateTable, users,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [SpValues, setSpValues] = useState({});
  const [userData, setUserData] = useState({});

  useEffect(() => {
    setOpen(editServiceModal);
    setUserData(users.find((user) => spToEdit === user.id));
  }, [editServiceModal]);

  const handleClose = () => {
    setEditServiceModal(false);
    setOpen(false);
  };
  const handleUpdateTable = () => {
    setUpdateTable(!updateTable);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        sx={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={paper}>
            <p id="newService-modal-description" className={styles.subtitle}>{`${t('serviceProvider')} ${spToEdit}`}</p>
            <h2 id="newService-modal-title" className={styles.title}>{t('editServiceProvider')}</h2>
            {(stepOne || stepTwo) && <CloseIcon onClick={handleClose} className={styles.close} />}
            {stepOne
              ? (
                <StepOne
                  setStepOne={setStepOne}
                  setStepTwo={setStepTwo}
                  setSpValues={setSpValues}
                  SpValues={SpValues}
                  userData={userData}
                  spToEdit={spToEdit}
                />
              ) : stepTwo
                ? (
                  <StepTwo
                    setStepTwo={setStepTwo}
                    setStepThree={setStepThree}
                    setSpValues={setSpValues}
                    SpValues={SpValues}
                    spToEdit={spToEdit}
                    handleUpdateTable={handleUpdateTable}
                  />
                )
                : stepThree && <StepThree handleClose={handleClose} />}

          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
EditSPModal.propTypes = {
  setEditServiceModal: PropTypes.func.isRequired,
  editServiceModal: PropTypes.bool.isRequired,
  spToEdit: PropTypes.number.isRequired,
  setUpdateTable: PropTypes.func.isRequired,
  updateTable: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    service_provider_type: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    governorate: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    is_active: PropTypes.number.isRequired,
  })).isRequired,
};
