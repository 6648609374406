import React from "react";
import { useTranslation } from "react-i18next";
import Caregivers from "../Charts/Caregivers";
import MiniChart from "../Charts/MiniChart";
import OnlineUsers from "../Charts/OnlineUsers";
import TopActivities from "../Charts/TopActivities";
import UsersRegistered from "../Charts/UsersRegistered";
import WebTraffic from "../Charts/WebTraffic";
import styles from "./Dashboard.module.css";

function Dashboard() {
  const { t } = useTranslation();
  return (
    <div className={styles["all-container"]}>
      <div className={styles["left-container"]}>
        <div className={styles["container-up"]}>
          <div className={styles["chart-one"]}>
            <div>
              <h6 className={styles.title}>{t("webTraffic")}</h6>
              <p className={styles["sub-title"]}>{t("today")}</p>
            </div>
            <WebTraffic />
          </div>
          <div className={styles["chart-one"]}>
            <div className={styles.text2}>
              <h6 className={styles.title2}>{t("chartCaregivers")}</h6>
            </div>
            <Caregivers />
          </div>
        </div>
        <div className={styles["container-down"]}>
          <div className={styles["chart-one"]}>
            <div className={styles.text2}>
              <h6 className={styles.title2}>{t("usersRegistered")}</h6>
            </div>
            <UsersRegistered />
          </div>
          <div className={styles["chart-one"]}>
            <div className={styles.text2}>
              <h6 className={styles.title2}>{t("topActivities")}</h6>
            </div>
            <TopActivities />
          </div>
        </div>
      </div>
      <div className={styles["chart-two"]}>
        <div className={styles.text3}>
          <h6 className={styles.title2}>{t("onlineUsers")}</h6>
        </div>
        <MiniChart />
        {/* <OnlineUsers /> */}
        {/* <div className={styles.legend}>
          <div className={styles["level-1"]} />
          <div className={styles["level-2"]} />
          <div className={styles["level-3"]} />
          <div className={styles["level-4"]} />
          <div className={styles["level-5"]} />
          <div className={styles.scale}>3000</div>
          <div className={styles.scale}>6000</div>
          <div className={styles.scale}>9000</div>
          <div className={styles.scale}>12000 </div>
          <div className={styles.scale}>15000</div>
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard;
