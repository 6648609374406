import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
// import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import styles from './SPModal.module.css';
import ViewSP from './ViewServiceProvider/ViewSP';

const modal = {
  display: 'flex',
  alignItems: 'right',
  justifyContent: 'right',
};
const paper = {
  position: 'relative',
  border: 'none',
  maxWidth: '1000px',
  minWidth: '295px',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ViewSPModal({
  setViewServiceModal, viewServiceModal, spToView, users,
}) {
//   const { t } = useTranslation();
  const [userData, setUserData] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(viewServiceModal);
    setUserData(users.find((user) => spToView === user.id));
  }, [viewServiceModal]);

  const handleClose = () => {
    setViewServiceModal(false);
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        sx={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={paper}>
            <p id="newService-modal-description" className={styles.subtitle}>{`Service Provider ${spToView}`}</p>
            <h2 id="newService-modal-title" className={styles.title}>View Service Provider</h2>
            <CloseIcon onClick={handleClose} className={styles.close} />
            <ViewSP userData={userData} />
          </Box>
        </Fade>
      </Modal>

    </div>
  );
}
ViewSPModal.propTypes = {
  setViewServiceModal: PropTypes.func.isRequired,
  viewServiceModal: PropTypes.bool.isRequired,
  spToView: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    service_provider_type: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    governorate: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    is_active: PropTypes.number.isRequired,
  })).isRequired,
};
